import React, { Fragment, PureComponent } from "react";
import { ReactTabulator } from "react-tabulator";

class UserTable extends PureComponent {
  ref = React.createRef();

  state = {
    t : this.props.t,
    columns: this.props.columns,
    data: this.props.data,
    options: {
      pagination: "local",
      paginationSize: 10,
      langs: {
        all: {
          pagination: {
            first: this.props.t("common.table.pagination.first"),
            first_title: this.props.t("common.table.pagination.first_title"),
            last: this.props.t("common.table.pagination.last"),
            last_title: this.props.t("common.table.pagination.last_title"),
            prev: this.props.t("common.table.pagination.prev"),
            prev_title: this.props.t("common.table.pagination.prev_title"),
            next: this.props.t("common.table.pagination.next"),
            next_title: this.props.t("common.table.pagination.next_title")
          }
        }
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    let sortedData = nextProps.data.sort((a,b) => a.firstName.localeCompare(b.firstName))
    this.setState({ columns: nextProps.columns, data: sortedData, t: nextProps.t });
  }

  rowClick = (e, row) => {
    this.ref.table.deselectRow();
    this.ref.table.selectRow(row);
    this.props.rowClick(row.getData());
  };

  setRef(ref) {
    if (ref) {
      this.ref = ref;
      this.ref.table.setLocale("all");
    }
  }

  render() {
    const { columns, data, options } = this.state;
    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                {data.length > 0 && (
                  <ReactTabulator
                    ref={ref => (this.setRef(ref))}
                    columns={columns}
                    data={data}
                    rowClick={this.rowClick}
                    options={options}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserTable;
