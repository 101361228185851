class UserRole {
    constructor(
        id="",
        name = "",
        roleDescription = "",
    ) {
        this.id = id;
        this.name = name;
        this.roleDescription = roleDescription;
        this.caseActions = [];
        this.adminSections = [];
    }

    setCaseActions(actions) {
        this.caseActions = actions
    }

    setAdminSections(sections) {
        this.adminSections = sections
    }
}

export default UserRole;