import React, { Component, Fragment } from 'react'
import { withTranslation } from "react-i18next";
import _ from "lodash";
const $ = window.$;

export class UserRoleAddModal extends Component {

    openCustomizeCaseAccessWindow = () => {
        $(function () {
            $('#modal-customize-case-access').modal('show');
        });
    }

    render() {
        const { t } = this.props.params;
        const {isRoleEdit,roleStateData, errors, caseActions, workflows, selectWorkflow} = this.props.params;
        return (
            <Fragment>
                <div className="modal fade" id="modal-user-role">
                    <div className="modal-dialog user-role-dialog">
                        <div className="modal-content user-role-content">
                            <div className="modal-header user-role-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">{isRoleEdit && ( t("common.edit") ) || ( t("common.add") )} {t("portalSettings.userRoles.add.title")}</h4>
                            </div>
                            <div className="modal-body user-role-body">
                                <div className="row" id="titleRow">
                                    <div className="col-xs-12">
                                        <div className={ errors.name ? "form-group has-error" : "form-group" }>
                                            <label htmlFor="name">
                                                {t("portalSettings.userRoles.add.name")}
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                defaultValue={roleStateData && isRoleEdit && (roleStateData.name)}
                                                onChange={this.props.handleChange}
                                                placeholder={t("portalSettings.userRoles.add.placeholder.title")}
                                            />
                                            {errors.name && (
                                                <span className="help-block">{errors.name}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" id="descriptionRow">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="roleDescription">
                                                {t("portalSettings.userRoles.add.description")}
                                            </label>
                                            <input
                                                type="text"
                                                name="roleDescription"
                                                className="form-control"
                                                id="roleDescription"
                                                defaultValue={roleStateData && isRoleEdit && (roleStateData.roleDescription)}
                                                onChange={this.props.handleChange}
                                                placeholder={t("portalSettings.userRoles.add.placeholder.description")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" id="select-actions">
                                    <div className="col-xs-12 form-group">
                                        <label htmlFor="case-actions">
                                            {t("portalSettings.userRoles.add.selectActions.title")}
                                        </label>
                                        <select
                                            className="form-control case-actions"
                                            id="case-actions"
                                            name="actions[]"
                                            onChange={this.props.handleChange}
                                            multiple="multiple"
                                            style={{width: '72%'}}
                                        >
                                            {caseActions &&
                                                caseActions.map(option => {
                                                    return (
                                                        <option key={option.id} value={option.id}>
                                                            {this.props.generateTitle("portalSettings.caseStates.fields.actions.option", option.title, option.title)} : {option.selectedWorkflowName}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="row" id="select-admin-sections">
                                    <div className="col-xs-12 form-group">
                                        <label htmlFor="name">
                                            {t("portalSettings.userRoles.add.selectAdminSections.title")}
                                        </label>
                                        <select
                                            className="form-control admin-sections"
                                            id="admin-sections"
                                            name="sections[]"
                                            onChange={this.props.handleChange}
                                            multiple="multiple"
                                            style={{width: '72%'}}
                                        >
                                            <option key="admin-sec-op-01" value="U_VIEW_USER">{t("portalSettings.userRoles.adminSection.roleList.U_VIEW_USER")}</option>
                                            <option key="admin-sec-op-02" value="U_ADD_USER">{t("portalSettings.userRoles.adminSection.roleList.U_ADD_USER")}</option>
                                            <option key="admin-sec-op-03" value="PS_OVERVIEW">{t("portalSettings.userRoles.adminSection.roleList.PS_OVERVIEW")}</option>
                                            <option key="admin-sec-op-04" value="PS_WORKFLOW">{t("portalSettings.userRoles.adminSection.roleList.PS_WORKFLOW")}</option>
                                            <option key="admin-sec-op-05" value="PS_CASE_STATE">{t("portalSettings.userRoles.adminSection.roleList.PS_CASE_STATE")}</option>
                                            <option key="admin-sec-op-06" value="PS_CASE_ACTION">{t("portalSettings.userRoles.adminSection.roleList.PS_CASE_ACTION")}</option>
                                            <option key="admin-sec-op-07" value="PS_GROUP_ROLE">{t("portalSettings.userRoles.adminSection.roleList.PS_GROUP_ROLE")}</option>
                                            <option key="admin-sec-op-08" value="PS_SUPER_ADMIN">{t("portalSettings.userRoles.adminSection.roleList.PS_SUPER_ADMIN")}</option>
                                        </select>

                                        {this.props.populateRoleSelects()}

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default pull-left"
                                        data-dismiss="modal">{t("common.close")}
                                </button>
                                <button type="button"
                                        className="btn btn-default"
                                        onClick={(event) => {
                                            this.props.handleSave(event);
                                        }}
                                >{t("common.save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withTranslation("common")(UserRoleAddModal);