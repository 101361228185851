class PortalUser {
  constructor(
    uniqueId = "",
    firstName = "",
    lastName = "",
    email = "",
    status = "ACTIVE",
    contactNumber = ""
  ) {
    this.uniqueId = uniqueId;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.status = status;
    this.contactNumber = contactNumber;
    this.workflowList = [];
    this.caseExpressions = [];
  }

  setworkflows(workflows) {
    this.workflowList = workflows;
  }

  setCaseExpressions(expressions) {
    this.caseExpressions = expressions
  }
}

export default PortalUser;
