class CaseState {
    constructor(
        id= "",
        name = "",
        workflowStateName = "",
        description = "",
        buttonStyle = "",
        helpText = "",
        actions = [],
        columns = []
    ) {
        this.id = id;
        this.name = name;
        this.workflowStateName = workflowStateName;
        this.description = description;
        this.buttonStyle = buttonStyle;
        this.helpText = helpText;
        this.actions = actions;
        this.columns = columns;
    }
}

export default CaseState;