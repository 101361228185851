import React, { Component } from "react";
import HeaderSection from "../sections/HeaderSection";
import SidebarSection from "../sections/SidebarSection";
import ContentSection from "../sections/ContentSection";
import FooterSection from "../sections/FooterSection";

export default class ProtectedPage extends Component {
    constructor(props) {
        super(props)
        this.menuHandler = this.menuHandler.bind(this)
    }

    menuHandler(menuData) {
        this.setState({
            menuData: menuData
        })
    }

  render() {
    return (
      <div className="wrapper">
        {/* <div className="sidebar-mini"> */}
          <HeaderSection user={this.props.user} />
          <SidebarSection user={this.props.user} />
          <ContentSection user={this.props.user} menuData={this.menuHandler} />
          <FooterSection user={this.props.user} />
        {/* </div> */}
      </div>
    )
  }
}
