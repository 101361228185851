import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {NavLink as Link} from "react-router-dom";

class CaseLinkPanel extends Component {
    state = {
        caseLinks: this.props.caseLinks,
        caseData: this.props.caseData,
        refCase:this.props.refCase,
        t: this.props.t
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            caseLinks: nextProps.caseLinks,
            caseData: nextProps.caseData,
            refCase: nextProps.refCase
        });
    }

    componentDidMount() {

    }

    deleteRecord = (idx, caseId, relatedCaseId) => {
        const { caseLinks } = this.state;

        caseLinks.splice(idx, 1);
        this.props.actionClick(idx, caseId, relatedCaseId, caseLinks);
        this.setState({
            caseLinks: caseLinks
        });
    };

    getActionData = data => {
        let menuData = JSON.parse(sessionStorage.getItem("menuData"));
        let menu = [];
        let caseState = [];

        menu = menuData.filter(function(el) {
            return data.workflowId == el.workflowId;
        })

        if(menu[0]!=undefined) {
            caseState = (menu[0].states).filter(function(el) {
                return data.stateName == el.workflowStateName;
            })
        }

        if(caseState[0]!=undefined && caseState[0]!=null){
            return caseState[0].actions;
        } else {
            return [];
        }
    }

    getSortedCaseLinks = (caseLinks) => {
        return caseLinks.sort((o1, o2) => o1.stateType === 'UNLINKED' ? -1 : 1);
    }

    render() {
        const { caseLinks, caseData, refCase, t } = this.state;
        const sortedCaseLinks = this.getSortedCaseLinks(caseLinks);

        return (
            <div className="box">
                <div className="box-header">
                    <p className="lead" style={{marginBottom:0}}>{t("caseManagement.linkCasePanel.title")}</p>
                    <div className="box-tools pull-right">
                        <button type="button" className="btn btn-box-tool" data-widget="collapse">
                            <i className="fa fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div className="box-body">
                    <table id="case_links" className="table table-bordered table-hover">
                        <thead>

                        </thead>
                        <tbody>
                        {
                            sortedCaseLinks.map((record, idx) => (
                                <tr key={record.uniqueId}>
                                    {
                                        record.stateType === 'UNLINKED' ?
                                            <td>
                                                <a className="inactive">
                                                    {record.uniqueId}
                                                </a>
                                            </td> :
                                            <>
                                                <td>
                                                    {<Link
                                                        to={{
                                                            pathname: "/protected/cases/" + caseData.workflowId,
                                                            search: "?type=" + caseData.caseState,
                                                            state: {
                                                                workflowId: record.workflowId,
                                                                workflowIndex: caseData.workflowIndex,
                                                                workFlowName: record.workflowName,
                                                                exerciseId: record.exerciseId,
                                                                caseState: record.stateName,
                                                                actions: this.getActionData(record),
                                                                uniqueId: record.uniqueId,
                                                                securePublishing : caseData.securePublishing,
                                                                caseStateId: record.caseStateId,
                                                                caseStateTitle: record.caseState
                                                            }
                                                        }}
                                                    >
                                                        {record.uniqueId}
                                                    </Link>}
                                                </td>
                                                <td width={20}>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.deleteRecord(idx, refCase.uniqueId, record.uniqueId);
                                                        }}
                                                    >
                                                        <span>×</span>
                                                    </button>
                                                </td>
                                            </>
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
export default withTranslation("common")(CaseLinkPanel);