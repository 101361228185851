import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import DashboardPage from "../pages/DashboardPage";
import Casepage from "../pages/CasePage";
import UsersPage from "../pages/UsersPage";
import ProfilePage from "../components/users/Profile";
import SettingsOverviewPage from "../pages/SettingsOverviewPage";
import SettingsWorkflowPage from "../pages/SettingsWorkflowPage";
import SettingsCaseStatePage from "../pages/SettingsCaseStatePage";
import SettingsCaseStateActionPage from "../pages/SettingsCaseStateActionPage";
import SettingsUserRolePage from "../pages/SettingsUserRolePage";
import SettingsUserGroupAndRolePage from "../pages/SettingsUserGroupAndRolePage";
import WorkFlowMenuAPI from "../api/WorkFlowMenuAPI";
import SettingsSuperAdminPage from "../pages/SettingsSuperAdminPage";

export default class ContentSection extends Component {
  state = {
    menuHandler: this.props.menuHandler
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ menuHandler: nextProps.menuHandler });
  }

  componentDidMount() {
    WorkFlowMenuAPI.reinitialize().then(menuData => {

    }).catch(error => {});
  }

  render() {
    return (
      <Fragment>
          <Switch>
            <Route exact path="/protected" component={DashboardPage} />
            <Route path="/protected/profile" component={ProfilePage} />
            <Route path="/protected/cases" component={Casepage} />
            <Route path="/protected/users" component={UsersPage} />
            <Route path="/protected/settings/overview" component={SettingsOverviewPage} />
            <Route path="/protected/settings/workflows" component={SettingsWorkflowPage} menuHandler={this.state.menuHandler} />
            <Route path="/protected/settings/states" component={SettingsCaseStatePage} menuHandler={this.state.menuHandler}/>
            <Route path="/protected/settings/stateActions" component={SettingsCaseStateActionPage} menuHandler={this.state.menuHandler}/>
            <Route path="/protected/settings/roles" component={SettingsUserRolePage} />
            <Route path="/protected/settings/userGroups" component={SettingsUserGroupAndRolePage} />
            <Route path="/protected/settings/superAdminSettings"
                   component={SettingsSuperAdminPage}
                   menuHandler={this.state.menuHandler}/>
          </Switch>
      </Fragment>
    );
  }
}
