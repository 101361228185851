import axios from "axios";

const cmpBaseUrl = window.ENV.REACT_APP_CMP_BASE_URL;
const cmpLocaleBranch = window.ENV.REACT_APP_UI_LOCALES_BRANCH;

const ConfigAPI = {
  getConfig: function() {
    return axios
      .get(cmpBaseUrl + "view-configuration.json")
      .then(res => res.data)
      .catch(err => {
        console.log("Error Reading data " + err);
      });
  },
  getLocale: async function(lang) {
    return await axios
      .get(cmpBaseUrl + `locales/` + cmpLocaleBranch +`.${lang}.json`)
      .then(res => res.data)
      .catch(err => {
        console.log("Error Reading data " + err);
      });
  }
};

export default ConfigAPI;
