import React from "react";
import {Route, Redirect} from "react-router-dom"
import {AuthConsumer} from "../auth/AuthCtxProvider"


export const PublicRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated , loadLoggedInUserToStore}) => {
               //loadLoggedInUserToStore();
                if (!!Component && !isAuthenticated()) {                  
                    return <Component {...props} />;
                } else {                  
                    return <Redirect to="/protected" />
                }
            }}
        </AuthConsumer>
    );
  
    return <Route {...rest} render={renderFn(component)} />;
  };