import React, { Component, createContext } from 'react';
import DesignAPI from '../api/DesignAPI';

export const CommonContext = createContext();

class CommonContextProvider extends Component {
    state = {
        title: "",
        sideMenuUpdate: false,
        workflowId: null
    }

    loadCustomTitle = async() => {
        await DesignAPI.getCustomTitle()
        .then(title => this.setState({title:title.name}))
        .catch(error => {throw error;})
      }

    componentDidMount() {
        this.loadCustomTitle()
        sessionStorage.hasOwnProperty("customTitle") ? this.setState({ title: sessionStorage.getItem("customTitle") }) : this.setState({ title: this.title })
    }

    setTitle = (text) => {
        this.setState({ title: text })
    }

    handleSideMenuUpdate = (value) => {
        this.setState({ sideMenuUpdate: value })
    }

    setWorkflowId = (id) => {
        this.setState({workflowId : id})
    }

    render() {
        return (
            <CommonContext.Provider value={{ ...this.state, setTitle: this.setTitle, handleSideMenuUpdate: this.handleSideMenuUpdate, setWorkflowId:this.setWorkflowId }}>
                {this.props.children}
            </CommonContext.Provider>
        )
    }
}

export default CommonContextProvider;