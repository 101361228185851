class UserGroup {
  constructor(
    id="",
    groupTitle = "",
    groupDescription = "",
  ) {
    this.id = id;
    this.groupTitle = groupTitle;
    this.groupDescription = groupDescription;
    this.workflow = {};
    this.caseExpressions = [];
    this.portalUsers = [];
  }

  setworkflow(workflow) {
    this.workflow = workflow;
  }

  setCaseExpressions(expressions) {
    this.caseExpressions = expressions
  }

  setPortalUsers(portalUsers) {
    this.portalUsers = portalUsers
  }
}

export default UserGroup;
