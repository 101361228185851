import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Moment from 'moment';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import AuthUtil from "../../utils/AuthUtil";

const $ = window.$;

class CaseHistoryPanel extends Component {
    state = {
        caseHistoryData: this.props.caseHistoryData,
        t: this.props.t,
        userData: this.props.userData,
        caseDetail: this.props.caseData
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ caseHistoryData: nextProps.caseHistoryData });
    }

    componentDidMount() {

    }

    populateHistory = () => {
        if ( $.fn.DataTable.isDataTable('#case_history') ) {
            $('#case_history').DataTable().destroy();
        }

        $(function () {
            $('#case_history').DataTable({
                'paging'      : false,
                'lengthChange': false,
                'searching'   : false,
                'ordering'    : true,
                'info'        : false,
                'autoWidth'   : true,
                'retrieve'    : true,
                "order": [],
            });

        })
    }

    generateActionTitle = title => {
        const {t} = this.state;
        var translation = t("caseManagement.caseHistoryPanel.table.actions."+title);
        if(translation.includes("caseManagement.caseHistoryPanel.table.actions")){
            if(title.includes("Assign to")){
                title = title.replace("Assign to",t("caseManagement.caseHistoryPanel.table.actions.Assign"))
            }
            return title;
        } else {
            return translation;
        }
    }

    generateComment = comment => {
        const {caseDetail, userData} = this.state;
        let generatedValue =  ReactHtmlParser(comment);
        if(generatedValue!=undefined && generatedValue != null && generatedValue.length>0 && caseDetail && userData) {
            for(var i = 0; i < generatedValue.length; i++) {
                var item = generatedValue[i];
                let aTag;
                if(item.type=="a") {
                    if (this.isAccessibleAttachment(caseDetail, userData)) {
                        var path = item.props.href;
                        var fileName = item.props.children;
                        aTag = React.createElement(
                            /* type */ 'a',
                            /* props */ {
                                rel: 'noreferrer noopener',
                                onClick: () => {this.props.attachmentClick(path, fileName)}
                            },
                            /* children */ item.props.children
                        )
                    } else {
                        aTag = React.createElement(
                             'a',
                            {style: {cursor: "no-drop"}},
                             item.props.children
                        )
                    }

                    generatedValue[i] = aTag;
                }
            }
        }
        return generatedValue;
    }

    isAccessibleAttachment = (caseDetail, userData) => {
        const lockedUser = caseDetail.lockedBy;
        const accessUserList = caseDetail.lockAccessUserList;

        if (lockedUser) {
            return (userData.isPortalAdmin || (String(userData.user.id) === lockedUser) ||
                accessUserList.some(user => user.uniqueId === String(userData.user.id)));
        }
        return true;
    };

    render() {
        const { caseHistoryData, t } = this.state;
        return (
            <div className="box">
                <div className="box-header">
                    <p className="lead" style={{marginBottom:0}}>{t("caseManagement.caseHistoryPanel.title")}</p>
                    <div className="box-tools pull-right">
                        <button type="button" className="btn btn-box-tool" data-widget="collapse">
                            <i className="fa fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div className="box-body">
                    <table id="case_history" className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>{t("caseManagement.caseHistoryPanel.table.column.action")}</th>
                            <th>{t("caseManagement.caseHistoryPanel.table.column.comment")}</th>
                            <th>{t("caseManagement.caseHistoryPanel.table.column.caseWorker")}</th>
                            <th>{t("caseManagement.caseHistoryPanel.table.column.timeOfChange")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            caseHistoryData.map(item => {
                                Moment.locale('en');
                                return(
                                    <tr key={item.id}>
                                        {item.actionPerformed ==='null' ? <td></td> : <td>{this.generateActionTitle(item.actionPerformed)}</td>}
                                        {item.comment ==='null' ? <td></td> : <td>{this.generateComment(item.comment)}</td>}
                                        {item.userName ==='null' ? <td></td> : <td>{item.userName}</td>}
                                        {item.createdDate ==='null' ? <td></td> : <td>{item.createdDate}</td>}
                                    </tr>
                                )
                            })
                        }
                        {this.populateHistory()}
                        </tbody>
                        <tfoot>
                        </tfoot>
                    </table>
                </div>
            </div>
        );
    }
}
export default withTranslation("common")(CaseHistoryPanel);