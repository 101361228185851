import React, { Component, Fragment } from "react";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { withTranslation } from "react-i18next";
import NoCases from "../cases/NoCases";
import CaseAPI from "../../api/CaseAPI";
import WorkflowAPI from "../../api/WorkflowAPI";
import _ from "lodash";
import ReactHtmlParser from 'react-html-parser';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Filter from "../cases/Filter";
import {filterCaseDataByFilterMenu} from "../../utils/CaseDataFilter";
import StateActionAPI from "../../api/StateActionAPI";
import StateAPI from "../../api/StateAPI";
import feather from 'feather-icons';
const $ = window.$;


function FormatData(props) {
  const rowData = props.cell._cell.row.data;
  let classList = props.cell._cell.row.element.classList;
  const cellValue = props.cell._cell.value;

  if(rowData.highlightStr){
    if(classList){
      classList.add(rowData.highlightStyle);
    }
    return <div>
      <span>{cellValue}</span> &nbsp;
      {ReactHtmlParser(rowData.highlightStr)}
    </div>;
  } else {
    return <span>{cellValue}</span>;
  }
}
class CaseTable extends Component {
  ref = null;
  cState = null;
  selectedColumns = []
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      t: this.props.t,
      searchText:"",
      pageData:[],
      isSearch: false,
      allCaseBtn: true,
      myCaseBtn: false,
      columnsBtn: false,
      wfColumns: [],
      columnCount: 0,
      isEnableSearchButton: false,
      tempSelectedColumns: [],
      tempWfColumns: []
    };
    const { t } = this.state;

    this.options = {
      tooltips: true,
      movableColumns: true,
      pagination: "local",
      paginationSize: 10,
      download: true,
      langs: {
        all: {
          pagination: {
            first: t("common.table.pagination.first"),
            first_title: t("common.table.pagination.first_title"),
            last: t("common.table.pagination.last"),
            last_title: t("common.table.pagination.last_title"),
            prev: t("common.table.pagination.prev"),
            prev_title: t("common.table.pagination.prev_title"),
            next: t("common.table.pagination.next"),
            next_title: t("common.table.pagination.next_title")
          }
        }
      }
    };
  }
  
  rowClick = (e, row) => {
    this.ref.table.deselectRow();
    this.ref.table.selectRow(row);
    let caseData = row.getData();
    caseData.columns = this.state.columns;
    this.props.rowClick(caseData);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.state.data.length !==0){
      let allData=nextProps.state.data;
      let columns=nextProps.state.columns;
      allData.forEach((data, index) => {
       this.removeNullValue(data, columns);
       this.decorateWithHighlight(columns);
      });
    }
    this.cState = nextProps.state.caseState;
    if (this.props.state.caseState !== nextState.caseState) {
      this.setState({ allCaseBtn: true, myCaseBtn: false, columnsBtn: false });
      $("input[name=column]").prop('checked', false);
      this.getWorkflowColumns();
    }
    return (
      (this.props.state.caseState === nextProps.state.caseState && this.props.state.data.length !== nextProps.state.data.length ) ||
      this.props.state.caseState !== nextProps.state.caseState ||
      this.props.state.workflowId !== nextProps.state.workflowId ||
      this.props.state.data !== nextProps.state.data ||
      this.state.allCaseBtn !== nextState.allCaseBtn || this.state.isSearch || nextState.searchText !== this.state.searchText ||
      this.state.columnCount !== nextState.columnCount || this.state.wfColumns !== nextState.wfColumns || this.state.columnsBtn !== nextState.columnsBtn )
  }
  tempColumns = [
    {field: 'workflowName', width: '', title: 'Workflow Name', align: 'left'},
    {field: 'caseState', width: '', title: 'Case State', align: 'left'},
    {field: 'sessionRef', width: '', title: 'Session Ref', align: 'left'},
    {field: 'workflowRef', width: '', title: 'Workflow Ref', align: 'left'},
    {field: 'workflowVersion', width: '', title: 'W version', align: 'left'},
    {field: 'worker', width: '', title: 'Created by', align: 'left'}
  ]

  removeNullValue = (data, columns) => {
    columns.forEach((column) => {
      let field=column.field;
      if(data[field]==="null"){
        data[field]="";
      }
      if(field.includes("metadata.")){
        field = field.replace("metadata.","");
        field = field.replace(".value","");
          let metadata=data.metadata[field];
           if(metadata){
            if(metadata.value === "null"){
              metadata.value="";
            }
          }
      }
     });
  }

  decorateWithHighlight = (columns) => {
    columns.forEach((column) => {
      if (column.field === "uniqueId"){
        column.formatter = reactFormatter(
          <FormatData/>
        )
      }
    });
  }

  componentDidMount() {
    let loggedInUser = sessionStorage.getItem("loggedInUser");
    if (loggedInUser) {
      loggedInUser = JSON.parse(loggedInUser);
      this.setState({
        user: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
        workflowList: loggedInUser.workflowList,
        isEnableSearchButton: loggedInUser.enableSearchButton,
        loggedInUser: loggedInUser
      })
    }
    this.getWorkflowColumns();
    feather.replace();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { caseState, workflowId } = this.props.state;
    if (prevProps.state.caseState !== caseState || prevProps.state.workflowId !== workflowId) {
      this.setState({
        tempSelectedColumns: [],
        tempWfColumns: []
      });
    }
    feather.replace();
  }

  componentWillReceiveProps(nextProps) {
    let defaultColumns = nextProps.state.columns;
    if(nextProps.state.columns === undefined) {
      defaultColumns = this.tempColumns;
    }
    this.setState(prevState => ({
      columns: prevState.tempSelectedColumns.length !== 0 ? prevState.tempSelectedColumns : defaultColumns,
      wfColumns: prevState.tempWfColumns.length !== 0 ? prevState.tempWfColumns : nextProps.state.wfColumns,
      columnCount : defaultColumns.length,
      pageData: nextProps.state.data,
      caseState: nextProps.state.caseState,
      workflowId: nextProps.state.workflowId,
      caseStateTitle: nextProps.state.caseStateTitle,
      caseStateId: nextProps.state.caseStateId,
      toggle: true,
    }));
    if(this.cState !== nextProps.state.caseState){
      this.setState({
        searchText:""
      });
    }
  }
  
  getWorkflowColumns = () => {
    WorkflowAPI.getWorkflowColumnsWithType(this.state.workflowIndex, this.props.state.caseStateId).then(data => {
      this.setState({ wfColumns: data});
    });
  }

  saveCaseUpdateAttributeData() {
    let uRole = "";
    let userGroups = [];
    let uGroups = "";

    this.state.loggedInUser.workflowList.forEach(workflow => {
      if (workflow.workflow.workflowId === this.state.workflowId) {
        uRole = workflow.role.name;
        userGroups = workflow.userGroups;
      }
    });

    if (userGroups) {
      uGroups = userGroups.map(item => item.groupTitle).join(', ');
    }

    const caseUpdateAttributeData = {
      caseEntryId: "ShortcutEntry",
      action: null,
      assn: this.state.loggedInUser.uniqueId,
      comment: null,
      workerEmail: null,
      entryTitle: this.props.state.shortcutName,
      aEmail: this.state.loggedInUser.email,
      workerName: null,
      aName: this.state.loggedInUser.firstName + ' ' + this.state.loggedInUser.lastName,
      aRole: this.props.t("roles." + uRole),
      aGroups: (uGroups ? uGroups : '')
    };

    let response = CaseAPI.saveCaseUpdateAttribute(caseUpdateAttributeData);

    response.then( res =>{
      if (this.props.state.actionLink.includes('?')) {
        window.open(`${this.props.state.actionLink}&actionKey=${res.data}`)
      } else {
        window.open(`${this.props.state.actionLink}?actionKey=${res.data}`)
      }
      
    }).catch(error => {
      console.log("Request failed", error);
    });
  }

  launchShortcut() {
    this.saveCaseUpdateAttributeData();
  }

  setRef(ref) {
    const { columns } = this.state;
    if (ref) {
      this.ref = ref;
      this.ref.table.setColumns(columns);
      this.ref.table.setLocale("all");
      this.ref.table.redraw(true);
      if (this.cState === "All_Applications") {

        this.ref.table.redraw(true);
      }
    }
  }

  generateTitle = (type, key, title) => {
    const {t} = this.state;
    var translation = t(type+"."+key);
    if(translation.includes(type)){
      return title;
    } else {
      return translation;
    }
  }

  getWorkflowColumns = () => {
    WorkflowAPI.getWorkflowColumnsWithType(this.state.workflowIndex, this.props.state.caseStateId).then(data => {
      this.setState({ wfColumns: data});
    });
  }


  checkboxListener = (val) => {
    const { wfColumns, columns, columnCount } = this.state;
    let tempColumns = columns;
    let selectedColumn = wfColumns.find(col => col.id === val)

      if (columns.some(col => col.field === selectedColumn.field) ) {
        const filtered = columns.filter((col) => col.field !== selectedColumn.field);

        const filteredWfColumns = wfColumns.map(col => {
          if (col.id === val) {
            return {...col, type: 'extra'};
          }
          return col;
        })

        this.setState({
          columns: filtered,
          wfColumns:filteredWfColumns,
          columnCount : columnCount -1,
          tempSelectedColumns: filtered,
          tempWfColumns: filteredWfColumns
        });
      } else {
        let record = {
          field: selectedColumn.field,
          width: selectedColumn.width,
          title: selectedColumn.title,
          align: selectedColumn.align,
          order: selectedColumn.order
        }

        const filteredWfColumns = wfColumns.map(col => {
          if (col.id === val) {
            return {...col, type: 'default'};
          }
          return col;
        })

        tempColumns.push(record)
        tempColumns.sort((a, b) => a.order > b.order ? 1 : -1);
        this.setState({
          columns: tempColumns,
          wfColumns:filteredWfColumns,
          columnCount: columnCount + 1,
          tempSelectedColumns: tempColumns,
          tempWfColumns: filteredWfColumns
        });
      }
  }

  handleCaseButton = (button) => {
    const { data } = this.state;
    switch (button) {
      case 'allCaseBtn':
        this.setState({ allCaseBtn: true, myCaseBtn: false });
        break;
      case 'myCaseBtn':
        this.setState({ myCaseBtn: true, allCaseBtn: false });
        break;
      default:
        break;
    }
  }

handleSearchBox = (e) => {
  let { name, value } = e.target;
  this.setState({
    [name]: value,
    isSearch:true
  });

  if(!this.state.isEnableSearchButton) {
    this.getSearchCases(value);
  }

}

getSearchCases = (searchText) => {
  const { workflowId, caseState, exerciseId } = this.state;
  const { workflowIndex, caseStateId } = this.props.state;

  if(searchText.trim() !== ""){
    CaseAPI.searchCases(workflowId, exerciseId, caseState, searchText.toLowerCase()).then(AllCaseData => {
      this.setState({
        pageData: filterCaseDataByFilterMenu(AllCaseData.data, workflowIndex, caseStateId),
        isSearch:false
      });
      this.props.onChangeOriginalData(this.state.pageData);
    });
  } else {
    this.props.onResetPageData();
    this.setState({
      pageData: this.props.state.data
    });
  }
}

handleSubmitSearch = (event) => {
  const { searchText } = this.state;
  this.getSearchCases(searchText);
}

handleClearButton = () => {
  this.props.onResetPageData();
  this.setState({
    searchText: "",
    isSearch:false,
    pageData: this.props.state.data
  });
  }

  handleColumnsBtn = () => {
    this.setState({columnsBtn: !this.state.columnsBtn})
  }
  
  exportTable = () => {
    const currentWorkflow = this.state.workflowList.filter((item) => item.workflow.workflowId === this.state.workflowId);

    const table = this.ref.table;
    const colObj = {};
    const colArr = [];
    const cols = [];

    table.columnManager.columns.forEach((obj) => {
      colObj[obj.definition.field] = obj.definition.title.toUpperCase();
      colArr.push(obj.definition.field);
      cols.push({ wch: 20, alignment: { horizontal: 'left' }});
    });

    const data = [];

    table.rowManager.activeRows.forEach((row, x) => {
      const record = {};
      colArr.forEach((col, i) => {
        if (col.includes('metadata')) {
          record[col] = row.data.metadata[col.split(".")[1]].value;
        } else if (col.includes('auditInfo')) {
          record[col] = row.data.auditInfo[col.split(".")[1]];
        } else {
          record[col] = row.data[col];
        }
      })
      data.push(record);
    })

    const modifiedData = data.map((item) => {
      const modifiedItem = {};
      for (const key in item) {
        if (key in colObj) {
          modifiedItem[colObj[key]] = item[key];
        }
      }
      return modifiedItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    worksheet['!cols'] = cols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Export');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(fileData, currentWorkflow[0].workflow.name + ' ' + this.state.caseStateTitle + '.xlsx');
  }

  render() {
    const {workflowIndex, isPinned, shortcutName, shortcutIcon} = this.props.state;
    const {
      columns,
      caseState,
      caseStateTitle,
      toggle,
      allCaseBtn,
      myCaseBtn,
      columnsBtn,
      t,
      pageData,
      wfColumns,
      isEnableSearchButton,
      caseStateId
    } = this.state;

    const caseData = {
      allCaseBtn: allCaseBtn,
      myCaseBtn: myCaseBtn,
      workflowIndex: workflowIndex,
      caseStateId: caseStateId,
      t: t
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-xs-12">
            <div className="box" state={ caseState }>
              <div className="box-header">
                <div className="shortcut-button">
                  {isPinned && 
                  <button type="button" className="btn btn-primary" onClick={e => {
                    e.preventDefault();
                    this.launchShortcut();
                    }}>
                    <i data-feather={shortcutIcon} />
                    {shortcutName}

                  </button>}
                </div>
                <p className="lead" style={{marginBottom:0}}>
                  {this.generateTitle("caseManagement.viewDetails.stateType",caseStateTitle,caseStateTitle)}
                </p>
                {toggle && <div className="box-header">
                    <div className="row">
                      <div className="col-xs-5">
                        <Filter
                            caseData={caseData}
                            onHandleCaseBtn={this.handleCaseButton}
                            onHandleFilterData={(workflowIndex, caseStateId) =>
                                this.props.onHandleFilterData(workflowIndex, caseStateId)}
                        />

                      </div>
                      <div className="col-xs-5 right">
                        <div className="row">
                          <div className="col-xs-8">
                            <form className="form-inline ml-3" onSubmit={e => {
                              e.preventDefault();
                            }}>
                              <div className="input-group input-group-md">
                                <input type="text"
                                       className="form-control form-control-navbar"
                                       name="searchText"
                                       id="searchText"
                                       value={this.state.searchText}
                                       size={250}
                                       placeholder={t("userManagement.viewUsers.searchCases.placeholder")}
                                       onChange={this.handleSearchBox}/>
                                {(this.state.searchText !== '') &&
                                    <button type="button" className="clear-button" onClick={(e)=>this.handleClearButton()}><i data-feather='x' /></button>}
                              </div>

                            </form>
                          </div>
                          <div className="col-xs-4">
                            {
                                isEnableSearchButton && <button type="button"
                                                                onClick={this.handleSubmitSearch}
                                                                className="btn btn-primary btn-sm">
                                  search 
                                  <i data-feather='search' />
                                </button>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-2 ">
                      <div className="dropdown">
                        <button className="dropbtn" onClick={this.handleColumnsBtn}>{t("portalSettings.caseStates.buttonLabel")} <i data-feather='columns' /></button>
                        {columnsBtn && <div className={"dropdown-content"}>
                          <p className="dropdown-title">{t("portalSettings.caseStates.dropdownTitle")}</p>
                          {wfColumns.length > 0 && wfColumns.map((column, idx) => (
                              <a key={idx} onClick={() => this.checkboxListener(column.id)} ><span style={{ marginLeft: 8, paddingRight: 8 }}><input className="medium" name="column" type="checkbox" checked={column.type === "default" ? true : false} value={column.id} />&nbsp;{column.title}</span></a>
                            ))}
                        </div>}
                      </div>
                    </div>
                    </div>
                </div>}
              </div>
              <div className="box-body">
                {pageData.length === 0 ? (
                  <NoCases />
                ) : (
                  <ReactTabulator
                    ref={ref => this.setRef(ref)}
                    columns={columns}
                    data={ allCaseBtn === true ? pageData : pageData.filter(dataElement => dataElement.worker === this.state.user)}
                    rowClick={this.rowClick}
                    options={this.options}
                  />
                )}
                <button id="export-table" type="button" disabled={pageData.length === 0} className="btn btn-primary" onClick={this.exportTable}>
                  {t("caseManagement.caseTable.exportButton.value")} <i data-feather='download' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(CaseTable);