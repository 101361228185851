import React, { Component } from 'react'

export class CommonActionButton extends Component {
    render() {
        const t = this.props.params;
        return (
            <>
                <a
                    href="#/"
                    className="btn btn-app"
                    onClick={e => {
                        e.preventDefault();
                        this.props.reverseOnRowClick()
                        this.props.openModal();
                    }}
                >
                    <i
                        className="fa fa-plus"
                        data-toggle="tooltip"
                        title="Add"
                    />
                    {t("common.add")}
                </a>
                <a
                    href="#/"
                    className="btn btn-app"
                    onClick={e => {
                        e.preventDefault();
                        this.props.openModal();
                    }}
                >
                    <i
                        className="fa fa-edit"
                        data-toggle="tooltip"
                        title="Edit"
                    />
                    {t("common.edit")}
                </a>
                <a
                    href="#/"
                    className="btn btn-app"
                    data-toggle="modal"
                    data-target="#caseStateDeleteAlert"
                >
                    <span
                        className="fa fa-remove"
                        data-toggle="tooltip"
                        title={t("common.delete")}
                    />
                    {t("common.delete")}
                </a>
            </>
        )
    }
}

export default CommonActionButton