import React, { Component, Fragment } from 'react'
import { withTranslation } from "react-i18next";

export class UserGroupDetails extends Component {
    
    render() {
        const { t } = this.props;
        const userGroup = this.props.userGroup;
        return (
            <Fragment>
                <div className="box">
                    <div className="box-header">
                        <p className="lead">
                            {t("portalSettings.userGroups.details.title")}
                        </p>
                    </div>
                    <div className="box-body">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                <tr key="name">
                                    <th> {t("portalSettings.userGroups.details.name")}:</th>
                                    <td>{userGroup.groupTitle}</td>
                                </tr>
                                <tr key="description">
                                    <th> {t("portalSettings.userGroups.details.description")}:</th>
                                    <td>{userGroup.groupDescription}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default withTranslation("common")(UserGroupDetails);