import React from "react";

const Loading = () => {
  return (
    <div className="overlay">
      <div className="fa fa-spin loading-icon" />
    </div>
  );
};

export default Loading;
