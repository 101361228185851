import AuthUtil from "../utils/AuthUtil";
import ApiService from "../auth/ApiService";
import _ from "lodash";
import { handleResponse, handleError } from "../services/serviceUtil";
import ConfigAPI from "../api/ConfigAPI";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/case";

const CaseAPI = {
  caseData: {
    columns: [],
    data: []
  },
  all: async function(workFlowId, exerciseId, caseState, t) {
    var initialCaseState = caseState
    if (caseState === "All_Applications") {
      caseState = "ALL";
    }
    if (caseState === "My_Cases") {
      caseState = "MY_CASES";
    }
    let userId = await AuthUtil.getUserId().then(id => id);
    let authUser = await AuthUtil.getLoggedInUser();
    let currentUser = `${authUser.firstName} ${authUser.lastName}`
    let isAdmin = true;
    let isSubmittedOrReturnedState = (caseState === "INNSENDT" || caseState === "TILBAKESENDT");
    //let dateRangeOfCases = isAdmin || !isSubmittedOrReturnedState ? "unlimited" : "30";
    let data = {
      workFlowId: workFlowId,
      userId: userId,
      caseState: caseState,
      currentUser:currentUser,
      exerciseId: exerciseId,
      isAdmin: isAdmin
    };

    let response = await new ApiService().callApi(resourceUrl, "post", data);
    this.caseData.data = response.data;

    let Config = await ConfigAPI.getConfig();

    let columns = await new ApiService().callApi(resourceUrl+"/columns/"+workFlowId+"/"+initialCaseState, "get");
    let menuData = {
      config: {
        colorFormatter:true
      },
      columns: columns.data.columns
    };

    function getFunction(column) {
      /*Commented after the dynamic workflow setting implementation - TODO */
      /*var translation = t("caseManagement.caseTable.columns."+column.field);
      if(!translation.includes("caseManagement.caseTable.columns")){
        column.title = translation;
      }*/

      var sampleFunctionBody = "var value = cell.getValue(); switch (value) { case \"INNSENDT\": cell.getElement().style.color = \"#3c8dbc\";return value;  case \"TILBAKESENDT\": cell.getElement().style.color = \"#f39c12\"; return value; case \"BEHANDLET\": cell.getElement().style.color = \"#00a65a\"; return value; case \"AVVIST\": cell.getElement().style.color = \"#dd4b39\"; return value; default:cell.getElement().style.color = \"default\"; return value;}";

      if(column.headerFilter){
        var placeholder = t("caseManagement.caseTable.columns.headerFilter.placeholder");
        column.headerFilterPlaceholder = placeholder;
      }

      /*Commented after the CCM refactoring tasks - TODO */
      if (
        menuData.config.colorFormatter &&
        column.title === "Status"
      ) {
        var f = new Function("cell", sampleFunctionBody);
        return (column.formatter = f);
      }
    }


    _.forEach(menuData.columns, getFunction);
    this.caseData.columns = menuData.columns;
    return this.caseData;
  },

  allCases: async function(workFlowId, exerciseId, caseState, t) {
    if (caseState === "All_Applications") {
      caseState = "ALL";
    }
    let userId = await AuthUtil.getUserId().then(id => id);
    let authUser = await AuthUtil.getLoggedInUser();
    let isAdmin = true;
    let isSubmittedOrReturnedState = (caseState === "INNSENDT" || caseState === "TILBAKESENDT");
    //let dateRangeOfCases = isAdmin || !isSubmittedOrReturnedState ? "unlimited" : "30";
    let data = {
      workFlowId: workFlowId,
      userId: userId,
      caseState: caseState,
      exerciseId: exerciseId,
      isAdmin: isAdmin
    };

    return await new ApiService().callApi(resourceUrl, "post", data);
  },

  deleteCase: async function(uniqueId) {
    let remoteUrl = `${resourceUrl}/${uniqueId}`;
    let response = await new ApiService()
      .callApi(remoteUrl, "delete")
      .then(handleResponse)
      .catch(handleError);
    return response;
  },
  getDashboardData: async function(uniqueId) {
    let authUser = await AuthUtil.getLoggedInUser();
    //let isAdmin = await AuthUtil.hasRole(authUser, ["PORTAL_ADMIN"]);
    //let remoteUrl =
        //isAdmin ? `${apiEndpoint}/secured/ui/statistics/${uniqueId}`
            //: `${apiEndpoint}/secured/ui/statistics/${uniqueId}/30`;
    let remoteUrl = `${apiEndpoint}/secured/ui/statistics`;
    return new ApiService().callApi(remoteUrl, "get").then(response => {
      return response.data;
    });
  },

  getMyCaseData: async function(uniqueId) {
    let authUser = await AuthUtil.getLoggedInUser();
    let currentUser = `${authUser.firstName} ${authUser.lastName}`
    let remoteUrl = `${apiEndpoint}/secured/ui/statistics/case/${currentUser}`;

    return new ApiService().callApi(remoteUrl, "get").then(response => {
      return response.data;
    });
  },

  getAllCases: async function(uniqueId) {
    let userId = await AuthUtil.getUserId().then(id => id);
    let authUser = await AuthUtil.getLoggedInUser();
    let isAdmin = await AuthUtil.hasRole(authUser, ["PORTAL_ADMIN"]);

    let data = {
      userId: userId,
      caseState: "ALL",
      isAdmin: isAdmin
    };

    let response = await new ApiService().callApi(resourceUrl, "post", data);
    this.caseData.data = response.data;
  },

  getAllLinkedCases: async function(uniqueId) {
    let remoteUrl =`${apiEndpoint}/secured/case/links/${uniqueId}`;
    let response = await new ApiService().callApi(remoteUrl,"get");
    return response.data;
  },

  deleteCaseLink: async function(caseId, relatedCaseId) {
    let remoteUrl = `${apiEndpoint}/secured/case/links/delete/${caseId}/${relatedCaseId}`;;
    let data = {
      caseId: caseId,
      relatedCaseId: relatedCaseId
    };

    let response = await new ApiService()
        .callApi(remoteUrl, "delete", data)
        .then(handleResponse)
        .catch(handleError);
    return response;
  },

  saveCaseWorker: async function(caseWorkerDto) {
    let data = await new ApiService().callApi(resourceUrl+`/save/worker`, "post", caseWorkerDto);
    return data.data;
  },

  searchCases: async function(workFlowId,exerciseId,caseState,searchTerm) {
    if (caseState === "All_Applications") {
      caseState = "ALL";
    }
    if (caseState === "My_Cases") {
      caseState = "MY_CASES";
    }
    let userId = await AuthUtil.getUserId().then(id => id);
    let authUser = await AuthUtil.getLoggedInUser();
    let currentUser = `${authUser.firstName} ${authUser.lastName}`
    let isAdmin = true;
    let searchKey = workFlowId+"@"+userId+"@"+caseState+"@"+currentUser+"@"+isAdmin;

    return await new ApiService().callApi(resourceUrl+"/search?searchKey="+searchKey+"&searchTerm="+searchTerm, "get");
  },
  saveCaseUpdateAttribute: async function(data){
    return await new ApiService().callApi(resourceUrl+"/save/case-update-attribute", "post", data);
  },
  updateCaseLinks: async function(caseLinkDto) {
    let data = await new ApiService().callApi(resourceUrl+`/save/links`, "post", caseLinkDto);
    return data.data;
  }

};

export default CaseAPI;
