import React, { Component } from 'react'

export class DeleteModal extends Component {
    render() {
        const { t } = this.props.params;
        const id = this.props.id;
        const type = this.props.type;
        const message = this.props.message;
        const title =this.props.title;
        return (
            <div className="modal fade" id={id}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{t(title)}</h4>
                        </div>
                        <div className="modal-body">
                            <p>{t(message)}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                data-dismiss="modal"
                                onClick={e => {
                                    e.preventDefault();
                                    if (type === "workflow") {
                                         this.props.deleteFunction(this.props.workflowDataId) 
                                        }
                                    if (type === "state" || type === "action") { 
                                        this.props.deleteFunction(this.props.stateDataId, this.props.stateDataName)
                                     }
                                    if (type === "user") { 
                                        this.props.deleteFunction(this.props.uniqueId)
                                     }
                                }}
                                style={{ marginLeft: 10 }}
                            >
                                {t("portalSettings.workflows.delete.ok")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-right"
                                data-dismiss="modal"
                            >
                                {t("portalSettings.workflows.delete.cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeleteModal