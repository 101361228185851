import UserAPI from "../api/UserAPI";
import ConfigAPI from "../api/ConfigAPI";
import WorkflowAPI from "../api/WorkflowAPI";
import _ from "lodash";

const WorkFlowMenuAPI = {
  menuData: [],
  all: async function() {
    let menuData = sessionStorage.getItem("menuData");

    if (menuData) {
      return JSON.parse(menuData);
    } else {
      const userId = sessionStorage.getItem("userId");
      let user = await UserAPI.getProfile(userId);

      let menuData = this.menuData;
      await ConfigAPI.getConfig().then(data => {
        menuData = data.menuData;

        menuData = menuData.filter(function(el) {
          return user.workflowList.some(
            item => item.workflow.workflowId === el.workFlowId
          );
        });
      });

      menuData.forEach((menu, index) => {
        let workflow = _.find(user.workflowList, {
          workflow: {
            workflowId: menu.workFlowId
          }
        });
        let exercises = workflow.exercises
          .map(exercise => exercise.code)
          .join(",");
        menu.exerciseId = exercises;
      });

      sessionStorage.setItem("menuData", JSON.stringify(menuData));
      return menuData;
    }
  },

  updatedList: async function() {
    let menuData = sessionStorage.getItem("menuData");

    if (menuData) {
      return JSON.parse(menuData);
    } else {
      const userId = sessionStorage.getItem("userId");
      let user = await UserAPI.getProfile(userId);

      let menuData = this.menuData;

      await WorkflowAPI.all().then(data => {
        menuData = data.data;
        menuData = menuData.filter(function(el) {
          return user.workflowList.some(
              item => item.workflow.workflowId === el.workflowId
          );
        });
      })

      menuData.forEach((menu, index) => {
        let workflow = _.find(user.workflowList, {
          workflow: {
            workflowId: menu.workflowId
          }
        });

        let exercises = workflow.exercises
            .map(exercise => exercise.code)
            .join(",");
        menu.exerciseId = exercises;
      });
      sessionStorage.setItem("menuData", JSON.stringify(menuData));
      return menuData;
    }
  },

  reinitialize: async function() {
    sessionStorage.removeItem("menuData");
    return await this.updatedList();
  }

};

export default WorkFlowMenuAPI;
