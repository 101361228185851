import ApiService from "../auth/ApiService";
import { handleResponse, handleError } from "../services/serviceUtil";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/states";

const StateAPI = {
    stateData: {
        columns: [
            { title: "Id", field: "id", visible:false},
            { title: "Name", field: "name" },
            { title: "Description", field: "description" },
            { title: "Button Style", field: "buttonStyle" }
        ],
        data: []
    },

    getAllStates: async function() {
        return await new ApiService()
            .callApi(resourceUrl, "get")
            .then(handleResponse)
            .catch(handleError);
    },

    all: async function() {
        let data = await new ApiService().callApi(`${resourceUrl}`, "get");
        this.stateData.data = data.data;
        return this.stateData;
    },

    save: async function(caseState) {
        let data = await new ApiService().callApi(`${resourceUrl}`, "post", caseState);
        return data.data;
    },

    delete: function(id) {
        return new ApiService()
            .callApi(`${resourceUrl}/${id}`, "delete")
            .then(handleResponse)
            .catch(handleError);
    },

    update: function(caseState) {
        return new ApiService()
            .callApi(`${resourceUrl}`, "put", caseState)
            .then(handleResponse)
            .catch(handleError);
    },

    updateColumns: function(caseState) {
        return new ApiService()
            .callApi(`${resourceUrl+"/columns"}`, "put", caseState)
            .then(handleResponse)
            .catch(handleError);
    },

    getCaseStateColumns: async function(workflowId, caseStateId) {
        return new ApiService()
            .callApi(`${resourceUrl+"/columns/"+workflowId+"/"+caseStateId}`, "get")
            .then(handleResponse)
            .catch(handleError);
    },

    saveAction: async function(caseAction) {
        let data = await new ApiService().callApi(`${resourceUrl+"/action/save"}`, "post", caseAction);
        return data.data;
    },

    deleteAction: function(id) {
        return new ApiService()
            .callApi(`${resourceUrl+"/action/delete"}/${id}`, "delete")
            .then(handleResponse)
            .catch(handleError);
    },

    updateAction: function(caseAction) {
        return new ApiService()
            .callApi(`${resourceUrl+"/action/update"}`, "put", caseAction)
            .then(handleResponse)
            .catch(handleError);
    },

    getCaseActionInfoConfig: async function(caseStateId,caseUniqueId) {
        return new ApiService()
            .callApi(`${resourceUrl+"/action/info/"+caseStateId+"/"+caseUniqueId}`, "get")
            .then(handleResponse)
            .catch(handleError);
    },
    getStateCaseActionInfo: async function(caseStateId) {
        return new ApiService()
            .callApi(`${resourceUrl+"/action/info/"+caseStateId}`, "get")
            .then(handleResponse)
            .catch(handleError);
    },

    getCaseStateHelpText: async function (caseStateId) {
        return new ApiService()
            .callApi(`${resourceUrl+"/"+caseStateId}`, "get")
            .then(handleResponse)
            .catch(handleError);
    }
};

export default StateAPI;