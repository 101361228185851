import React, { Component, Fragment } from "react";
import UserActionPanel from "../shared/UserActionPanel";
import UserTable from "../shared/UserTable";
import UserDetails from "../shared/UserDetails";
import UserAPI from "../../api/UserAPI";
import { withTranslation } from "react-i18next";

class AllUsers extends Component {
  state = {
    userData: null,
    columns: [],
    data: [],
    t: this.props.t
  };

  onRowClick = userData => {
    this.setState({
      userData: userData
    });
  };

  updateUserTable() {
    let { columns, t } = this.state;
    UserAPI.all(t).then(data => {
      columns = [...data.columns];
      this.setState({ columns, data: data.data });
    });
  }
  componentDidMount() {
    this.updateUserTable();
  }

  deleteUser = uniqueId => {
    UserAPI.deleteUser(uniqueId).then(() => {
      this.updateUserTable();
      this.setState({
        userData: null
      });
    });
  };

  render() {
    const { userData, columns, data, t } = this.state;
    return (
      <Fragment>
        <section className="content">
          <UserTable rowClick={this.onRowClick} columns={columns} data={data} t={t} />
          {userData && (
            <div className="row">
              <div className="col-xs-8">
                <div className="box">
                  <div className="box-header">
                    <p className="lead">
                      {t("userManagement.viewDetails.title")}
                    </p>
                  </div>
                  <div className="box-body">
                    <UserDetails userData={userData} />
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <UserActionPanel
                  uniqueId={userData.uniqueId}
                  roleList={userData.roleList}
                  deleteUser={this.deleteUser}
                />
              </div>
            </div>
          )}
        </section>
      </Fragment>
    );
  }
}

export default withTranslation("common")(AllUsers);