import SockJs from "sockjs-client";
import Stomp from "stomp-websocket";
const webSocketEndpoint = "/cng-websocket";
const caseUpdateUrl = "/app/case";

let stompClient = null;

class WebSocket {
  register(registrations) {
    let socket = SockJs(window.ENV.REACT_APP_CMP_API_URL + webSocketEndpoint);
    stompClient = Stomp.over(socket);
    stompClient.debug = () => {};
    stompClient.connect({}, frame => {
      registrations.forEach(registration => {
        stompClient.subscribe(registration.route, registration.callback);
      });
    });
  }

  sendCaseUpdateRequest(caseLockDto) {
    stompClient.send(caseUpdateUrl, {}, JSON.stringify(caseLockDto));
  }

  sendCaseWorkerUpdateRequest(caseWorkerDto) {
    stompClient.send("/app/caseWorker", {}, JSON.stringify(caseWorkerDto));
  }

  sendCaseLinkUpdateRequest(caseLinkDto) {
    stompClient.send("/app/caseLink", {}, JSON.stringify(caseLinkDto));
  }
}

export default new WebSocket();
