import React from "react";
import {Route, Redirect} from "react-router-dom"
import {AuthConsumer} from "../auth/AuthCtxProvider"


export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = (Component) => (props) => (
      <AuthConsumer>
          {({ isAuthenticated, loadLoggedInUserToStore}) => {
              //loadLoggedInUserToStore();
              if (!!Component && isAuthenticated()) {
                  return <Component {...props} />;
              } else {
                  return <Redirect to="/" />
              }
          }}
      </AuthConsumer>
  );
  return <Route {...rest} render={renderFn(component)} />;
}