import axios from "axios";
import AuthUtil from "../utils/AuthUtil";

const CaseAttachmentsAPI = {

    getAttachmentByUrl: async function(attachmentUrl, fileName) {
        let auth = AuthUtil.getService();
        auth.loadUser()
            .then(user => {
                if (user && user.access_token) {
                    return this.invokeRestCall(attachmentUrl, user.access_token)
                        .catch(error => {
                            if (error.response.status > 401 && error.response.message === "Expired JWT") {
                                return this.auth.silentRenew()
                                    .then(renewedUser => {
                                        return this.invokeRestCall(attachmentUrl, renewedUser.access_token);
                                    });
                            } else {
                                throw error;
                            }
                        });
                } else if (user) {
                    return this.auth.silentRenew()
                        .then(renewedUser => {
                            return this.invokeRestCall(attachmentUrl, renewedUser.access_token);
                        });
                } else {
                    throw new Error("user is not logged in");
                }
            })
            .then(response => {
                let blob = new Blob([response.data], {type: response.headers['content-type']});
                var blobUrl = window.URL.createObjectURL(blob);
                window.open(blobUrl);

                /*  Add the following code segment with an appropriate file name to download the retrieved file upon clicking
                 *
                 *  let link = document.createElement('a');
                 *  link.href = blobUrl;
                 *  link.download = fileName;
                 *  link.dispatchEvent(new MouseEvent('click'));
                 */
            })
            .catch(error => console.log(error));
    },

    invokeRestCall(url, token) {
        return axios.get(url,
            {
                headers: {
                    Accept: "*/*",
                    Authorization: "Bearer " + token
                },
                responseType: "arraybuffer"
            });
    }
};
export default CaseAttachmentsAPI;