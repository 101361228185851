import React, { Component } from "react";
import {withTranslation} from "react-i18next";

class LoginPageFooterSection extends Component {
  state = {
    locale: this.props.locale,
    t: this.props.t
  };

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    let { t } = this.state;

    return (
      <div>
        <footer className="main-footer login-main-footer">
          {/* Default to the left */}
          <div className="copyright text-center my-auto login-my-auto">
            <div>
              {t('loginPage.copyright.segment01')}{" "}
              <a
                href="https://www.usecompose.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Compose
              </a>{" "}
              | © {t('loginPage.copyright.segment02')}
            </div>
          </div>
        </footer>
      </div>
    );
  }
}


export default withTranslation("common")(LoginPageFooterSection);