import ApiService from "../auth/ApiService";
import { handleResponse, handleError } from "../services/serviceUtil";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/case-state-filter";

const CaseStateFilterAPI = {

    save: async function(stateFilters) {
        let data = await new ApiService()
            .callApi(`${resourceUrl}`, "post", stateFilters);
        return data.data;
    },

    delete: function(ids) {
        return new ApiService()
            .callApi(`${resourceUrl}`, "delete", ids)
            .then(handleResponse)
            .catch(handleError);
    },

    getCaseStateFilters: async function(workflowId, caseStateId) {
        return new ApiService()
            .callApi(`${resourceUrl+"/"+workflowId+"/"+caseStateId}`, "get")
            .then(handleResponse)
            .catch(handleError);
    },

    getFilterSettings: async function(workflowId, caseStateId) {
        return new ApiService()
            .callApi(`${resourceUrl+"/filter-menu/"+workflowId+"/"+caseStateId}`, "get")
            .then(handleResponse)
            .catch(handleError);
    }
};

export default CaseStateFilterAPI;