import React, { Component } from 'react';
import Select from "react-select";
export class APIConfigModal extends Component {

    render() {
        const { t, workflowAttributes, filteredMetadata } = this.props.params;

        return (
            <div className="modal fade" id="api-config-modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">
                                {t("portalSettings.superAdmin.properties.api.modal.title")}
                            </h4>
                        </div>
                        <div className="modal-body api-conf-mdl-body">
                            <div className="row">
                                <div className="col-xs-12">
                                    <p className="info-text">
                                        {t("portalSettings.superAdmin.properties.api.modal.infoText.message")}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-10">
                                    <form>
                                        <div className="row select-metadata" id="select-users">
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <label htmlFor="caseInfoField">
                                                            {t("portalSettings.superAdmin.properties.api.modal.fields.metadata")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <div className="form-group">
                                                            <Select
                                                                classNamePrefix="sa-metadata-select"
                                                                isMulti
                                                                value={filteredMetadata.metadata}
                                                                getOptionLabel={option => option.attributeName}
                                                                getOptionValue={option => option.attribute}
                                                                onChange={this.props.handleMetadataChange}
                                                                options={workflowAttributes}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                data-dismiss="modal"
                                style={{ marginLeft: 10 }}
                                onClick={this.props.handleMetadataSave}
                            >
                                {t("common.save")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-left"
                                data-dismiss="modal"
                            >
                                {t("common.cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default APIConfigModal;