import ApiService from "../auth/ApiService";
import { handleResponse, handleError } from "../services/serviceUtil";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/workflow";

const WorkflowAPI = {
  workflowData: {
    columns: [
      { title: "Id", field: "id", visible:false},
      { title: "Workflow ID", field: "workflowId" },
      { title: "Name", field: "name", formatter:function(cell, formatterParams) {
            return cell.getValue();
        }
      },
      { title: "Description", field: "description" },
      { title: "Icon", field: "icon" }
    ],
    data: []
  },

  getWorkflows: async function() {
    return await new ApiService()
      .callApi(resourceUrl, "get")
      .then(handleResponse)
      .catch(handleError);
  },

  all: async function() {
    let data = await new ApiService().callApi(`${resourceUrl}`, "get");
    this.workflowData.data = data.data;
    return this.workflowData;
  },

  save: async function(workflow) {
    let data = await new ApiService().callApi(`${resourceUrl}`, "post", workflow);
    return data.data;
  },

  delete: function(id) {
    return new ApiService()
        .callApi(`${resourceUrl}/${id}`, "delete")
        .then(handleResponse)
        .catch(handleError);
  },

  update: function(workflow) {
    return new ApiService()
        .callApi(`${resourceUrl}`, "put", workflow)
        .then(handleResponse)
        .catch(handleError);
  },

  updateColumns: function(workflow) {
    return new ApiService()
        .callApi(`${resourceUrl+"/columns"}`, "put", workflow)
        .then(handleResponse)
        .catch(handleError);
  },

  getWorkflowColumns: async function(id) {
    return new ApiService()
        .callApi(`${resourceUrl+"/columns/"+id}`, "get")
        .then(handleResponse)
        .catch(handleError);
  },

  getWorkflowColumnsWithType: async function(workflowId, caseStateId) {
    return new ApiService()
        .callApi(`${resourceUrl+"/columns/"+workflowId+"/"+caseStateId}`, "get")
        .then(handleResponse)
        .catch(handleError);
  },

  getWorkflowAttributes: async function(workflowId) {
    return new ApiService()
        .callApi(`${resourceUrl+"/attributes/"+workflowId}`, "get")
        .then(handleResponse)
        .catch(handleError);
  },

  updateCaseInfoConfig: function(workflow) {
    return new ApiService()
        .callApi(`${resourceUrl+"/case/info"}`, "put", workflow)
        .then(handleResponse)
        .catch(handleError);
  },

  getCaseInfoConfig: async function(id) {
    return new ApiService()
        .callApi(`${resourceUrl+"/case/info/"+id}`, "get")
        .then(handleResponse)
        .catch(handleError);
  },

  getCaseInfoConfigByWorkflowId: async function(workflowId, caseUniqueId) {
    return new ApiService()
        .callApi(`${resourceUrl+"/case/infoBy/"+workflowId+"/"+caseUniqueId}`, "get")
        .then(handleResponse)
        .catch(handleError);
  },

  getCaseDeletionRules: async function(workflowId) {
    return new ApiService()
        .callApi(`${resourceUrl+"/case/deletion-rule/"+workflowId}`, "get")
        .then(handleResponse)
        .catch(handleError);
  },

  updateCaseDeletionRules: async function(workflow) {
    return new ApiService()
        .callApi(`${resourceUrl+"/case/deletion-rule"}`, "put", workflow)
        .then(handleResponse)
        .catch(handleError);
  },

  getCaseHighlightingConfig: async function(workflowId) {
    return new ApiService()
        .callApi(`${resourceUrl+"/case/highlight-config/"+workflowId}`, "get")
        .then(handleResponse)
        .catch(handleError);
  },

  updateCaseHighlightConfig: async function(workflow) {
    return new ApiService()
        .callApi(`${resourceUrl+"/case/highlight-config"}`, "put", workflow)
        .then(handleResponse)
        .catch(handleError);
  },
};

export default WorkflowAPI;