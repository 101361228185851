import React, { Component } from 'react'
import PropTypes from 'prop-types'
const $ = window.$;

export class ColumnConfigModal extends Component {
    static propTypes = {}
    render() {
        const { t, errors, workflowAttributes, attributesSorted, columnRecords } = this.props.params;
        return (
            <div className="modal fade" id="modal-columns">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{t("portalSettings.workflows.actions.columns.title")}</h4>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className={
                                    errors.title ? "form-group has-error" : "form-group"
                                }>
                                    <label htmlFor="title">
                                        {t("portalSettings.workflows.actions.columns.fields.title")}
                                    </label>
                                    <input
                                        type="text"
                                        name="title"
                                        className="form-control"
                                        id="title"
                                        placeholder={t("portalSettings.workflows.actions.columns.placeholder.title")}
                                    />
                                    {errors.title && (
                                        <span className="help-block">{errors.title}</span>
                                    )}
                                </div>

                                <div className={
                                    errors.field ? "form-group has-error" : "form-group"
                                }>
                                    <label htmlFor="field">
                                        {t("portalSettings.workflows.actions.columns.fields.field")}
                                    </label>
                                    <select
                                        type="text"
                                        name="field"
                                        className="form-control"
                                        id="field"
                                    >
                                        {workflowAttributes && attributesSorted &&
                                            attributesSorted.map(option => {
                                                return (
                                                    <option key={"C" + option.id} value={option.attribute}>
                                                        {option.attributeName}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    {errors.field && (
                                        <span className="help-block">{errors.field}</span>
                                    )}
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className={
                                            errors.align ? "form-group has-error" : "form-group"
                                        }>
                                            <label htmlFor="align">
                                                {t("portalSettings.workflows.actions.columns.fields.align")}
                                            </label>
                                            <select
                                                type="text"
                                                name="align"
                                                className="form-control"
                                                id="align"
                                            >
                                                <option key="a1" value="left">{t("portalSettings.workflows.actions.columns.fields.alignOptions.left")}</option>
                                                <option key="a2" value="right">{t("portalSettings.workflows.actions.columns.fields.alignOptions.right")}</option>
                                                <option key="a3" value="center">{t("portalSettings.workflows.actions.columns.fields.alignOptions.center")}</option>
                                            </select>
                                            {errors.align && (
                                                <span className="help-block">{errors.align}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className={
                                            errors.width ? "form-group has-error" : "form-group"
                                        }>
                                            <label htmlFor="width">
                                                {t("portalSettings.workflows.actions.columns.fields.width")}
                                            </label>
                                            <input
                                                type="text"
                                                name="width"
                                                className="form-control"
                                                id="width"
                                                placeholder={t("portalSettings.workflows.actions.columns.placeholder.width")}
                                            />
                                            {errors.width && (
                                                <span className="help-block">{errors.width}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <span
                                            className="input-group-btn"
                                            style={{ paddingTop: 25, textAlign: "right" }}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-md btn-default"
                                                id="columnSave"
                                                onClick={this.props.addColumnRecord}
                                            >
                                                {t("common.add")}
                                            </button>

                                            <button
                                                type="reset"
                                                className="btn btn-md btn-default"
                                                style={{ marginLeft: 10 }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.resetColumnForm();
                                                }}

                                            >
                                                {t("common.clear")}
                                            </button>
                                        </span>
                                    </div>
                                </div>

                                {columnRecords.length > 0 && (
                                    <div className="form-group" style={{ marginTop: 30 }}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>{t("portalSettings.workflows.actions.columns.fields.title")}</th>
                                                    <th>{t("portalSettings.workflows.actions.columns.fields.field")}</th>
                                                    <th style={{ width: 150 }}>
                                                        {t("portalSettings.workflows.actions.columns.fields.action")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {columnRecords.length > 0 && columnRecords.sort((a, b) => a.order > b.order ? 1 : -1).map((record, idx) => (
                                                    <tr key={idx}>
                                                        <td>{record.title}</td>
                                                        <td>{this.props.generateAttributeName(record.field)}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                title="Move Up"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.moveUpCaseInfoRecord("columns", record);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-arrow-up"></i></span>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                title="Move Down"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.moveDownCaseInfoRecord("columns", record);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-arrow-down"></i></span>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.editColumnRecord(idx, record);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-edit"></i></span>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.deleteColumnRecord(idx);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-close"></i></span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                data-dismiss="modal"
                                style={{ marginLeft: 10 }}
                                onClick={(event) => {
                                    this.props.handleColumnSave(event)
                                    $('#modal-state').modal('show');
                                }}
                            >
                               {t("portalSettings.workflows.actions.columns.directiveButton")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                data-dismiss="modal"
                                style={{ marginLeft: 10 }}
                                onClick={this.props.handleColumnSave}
                            >
                                {t("common.save")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-right"
                                data-dismiss="modal"
                            >
                                {t("common.cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ColumnConfigModal