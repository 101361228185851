import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

class NoCases extends Component {
  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <section className="content-header">
          <h4>
            <i>{t("caseManagement.noCasesMessage")}</i>
          </h4>
        </section>
      </Fragment>
    );
  }
}
export default withTranslation("common")(NoCases);