import {generateFilterKey} from "./GenerateKey";

export const  filterCaseDataByFilterMenu = (originalData, workflowIndex, caseStateId) => {
    // Check if originalData is not empty
    if (!originalData || originalData.length === 0) {
        return originalData;
    }

    const key = generateFilterKey(workflowIndex, caseStateId);
    const filterArray = JSON.parse(localStorage.getItem(key));

    // Check if filterArray is not null and not empty
    if (!filterArray || filterArray.length === 0) {
        return originalData;
    }

    const filterMap = filterArray.reduce((acc, filter) => {
        if (filter.selectedOptions && filter.selectedOptions.length > 0) {
            let fieldKey = filter.field;
            if (fieldKey.startsWith('metadata.') && fieldKey.endsWith('.value')) {
                fieldKey = fieldKey.split('.')[1]; // Extract 'category'
            } else if (fieldKey.startsWith('auditInfo.')) {
                fieldKey = fieldKey.split('.')[1]; // Extract 'area'
            }
            acc[fieldKey] = filter.selectedOptions.map(option => option.value);
        }
        return acc;
    }, {});

    // Check if all filter values are empty
    const allFiltersEmpty = Object
        .values(filterMap)
        .every(filterValues => filterValues.length === 0);

    // If all filters are empty, set state with originalData and return it
    if (allFiltersEmpty) {
        this.setState({ data: originalData });
        return originalData;
    }

    return  originalData.filter(item => {
        return Object.keys(filterMap).every(key => {
            const itemValue = item.metadata[key]?.value;
            return itemValue && filterMap[key]
                .some(option => itemValue
                    .includes(option));
        });
    });
}