import React, { Component, Fragment } from 'react'
import { withTranslation } from "react-i18next";
import _ from "lodash";
const $ = window.$;

export class UserGroupAddModal extends Component {

    openCustomizeCaseAccessWindow = () => {
        $(function () {
          $('#modal-customize-case-access').modal('show');
        });
    }
    
    render() {
        const { t } = this.props.params;
        const {isEdit,stateData, errors, userData, workflows, selectWorkflow} = this.props.params;
        return (
            <Fragment>
                <div className="modal fade" id="modal-user-group">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">{isEdit && ( t("common.edit") ) || ( t("common.add") )} {t("portalSettings.userGroups.add.title")}</h4>
                            </div>
                            <div className="modal-body">
                                <div className="row" id="user-group-info">
                                    <div className="col-xs-12">
                                        <p className="info-text">{t("portalSettings.userGroups.add.helpText")}</p>
                                    </div>
                                </div>
                                <div className="row" id="titleRow">
                                    <div className="col-xs-6">
                                        <div className={ errors.groupTitle ? "form-group has-error" : "form-group" }>
                                            <label htmlFor="name">
                                                {t("portalSettings.userGroups.add.name")}
                                            </label>
                                            <input
                                                type="text"
                                                name="groupTitle"
                                                className="form-control"
                                                id="groupTitle"
                                                defaultValue={stateData && isEdit && (stateData.groupTitle)}
                                                onChange={this.props.handleChange}
                                                placeholder={t("portalSettings.userGroups.add.placeholder.title")}
                                            />
                                            {errors.groupTitle && (
                                                <span className="help-block">{errors.groupTitle}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xs-6">
                                        <div className={ errors.workflow ? "form-group has-error" : "form-group"}>
                                            <label htmlFor="name">
                                                {t("portalSettings.userGroups.add.workflow")}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="workflow"
                                                name="workflow"
                                                onChange={this.props.handleWorkflowChange}
                                                value={
                                                    workflows && selectWorkflow ? 
                                                    _.find(workflows, {
                                                        workflowId: selectWorkflow
                                                        }).name
                                                    : ""
                                                }
                                            >
                                                <option key={'no-select'} value="no-select">{t("portalSettings.userGroups.add.placeholder.workflow")}</option>
                                                {workflows &&
                                                    workflows.map(option => {
                                                    return (
                                                        <option
                                                            key={option.workflowId}
                                                            value={option.name}
                                                        >
                                                            {option.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            {errors.workflow && (
                                                <span className="help-block">{errors.workflow}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row" id="descriptionRow">
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="description">
                                                {t("portalSettings.userGroups.add.description")}
                                            </label>
                                            <input
                                                type="text"
                                                name="groupDescription"
                                                className="form-control"
                                                id="groupDescription"
                                                defaultValue={stateData && isEdit && (stateData.groupDescription)}
                                                onChange={this.props.handleChange}
                                                placeholder={t("portalSettings.userGroups.add.placeholder.description")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" id="user-group-access">
                                    <div className="col-xs-12">
                                        <button
                                            type="button"
                                            id="caseAccess"
                                            className="btn btn-primary"
                                            onClick={this.openCustomizeCaseAccessWindow}
                                        >
                                            {t("portalSettings.userGroups.add.caseAccess")}
                                        </button>
                                    </div>
                                </div>
                                <div className="row" id="section-header">
                                    <div className="col-xs-12">
                                        <h4 className="section-header">{t("portalSettings.userGroups.add.users")}</h4>
                                    </div>
                                </div>
                                <div className="row" id="users-info">
                                    <div className="col-xs-12">
                                        <p className="info-text">{t("portalSettings.userGroups.add.helpText2")}</p>
                                    </div>
                                </div>
                                <div className="row" id="select-users">
                                    <div className="col-xs-6">
                                        <div className="form-group">
                                            <label htmlFor="name">
                                                {t("portalSettings.userGroups.add.selectUser")}
                                            </label>
                                            <select
                                                className="form-control portal-user"
                                                id="portal-user"
                                                name="users[]"
                                                onChange={this.props.handleUserChange}
                                                multiple="multiple"
                                                style={{width: '72%'}}
                                            >
                                                {userData &&
                                                    userData.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(option => {
                                                    return (
                                                        <option key={option.id} value={option.id}>
                                                            {option.firstName + ' ' + option.lastName}
                                                        </option>
                                                    )
                                                    })
                                                }
                                            </select>

                                            {this.props.populateUserSelect()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default pull-left"
                                        data-dismiss="modal">{t("common.close")}
                                </button>
                                <button type="button" 
                                    className="btn btn-default"
                                    onClick={(event) => {
                                        this.props.handleSave(event);
                                    }}
                                >{t("common.save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default withTranslation("common")(UserGroupAddModal);