import React, { Component } from 'react';

class ToggleSwitch extends Component {
  render() {
    const { isToggled, onToggle } = this.props;

    return (
      <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isToggled}
        onChange={onToggle}
      />
      <span className="slider"></span>
    </label>
    );
  }
}

export default ToggleSwitch;
