import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import WorkflowAPI from "../api/WorkflowAPI";
import CommonTable from "../components/shared/CommonTable";
import Workflow from "../dto/Workflow";
import StateAPI from "../api/StateAPI";
import ExerciseAPI from "../api/ExerciseAPI";
import APIConfigModal from "../components/superadmin/APIConfigModal";
import MetadataFilterAPI from "../api/MetadataFilterAPI";

const $ = window.$;

class SettingsSuperAdminPage extends Component {
    ref = React.createRef();
    workflowId;

    state = {
        workflow: new Workflow(),
        workflowData : null,
        t : this.props.t,
        columns: [],
        data: [],
        records: [],
        caseStates: [],
        selectState: null,
        isEdit: false,
        exercises: [],
        exerciseRecords: [],
        selectExercise: null,
        errors: {},
        initialRecords: [],
        initialExercises: [],

        columnRecords: [],
        initialColumns: [],

        workflowAttributes: [],

        caseInfoRecords: [],
        initialCaseInfo: [],

        filteredMetadata: {
            workflowId: '',
            metadata: []
        }
    };

    defaultAttributes = [
        {
            "attribute": "worker",
            "attributeName": "Assign Worker (default)"
        },
        {
            "attribute": "attachments",
            "attributeName": "Attachments (default)"
        }
    ]

    onRowClick = workflowData => {
        let states = workflowData.states;
        let newData = [];
        this.workflowId = workflowData.workflowId;

        this.updateWorkflowCaseInfoConfig(workflowData.id);
        this.updateWorkflowAttributes(workflowData.workflowId);
        this.updateWorkflowColumns(workflowData.id);

        workflowData.name = workflowData.actualName;

        states.forEach(item =>{
            let record = {
                id: item.id,
                name: item.name,
                description: item.description,
                buttonStyle: item.buttonStyle,
                actions: item.actions,
                order: item.order
            };
            newData.push(record);
        })

        let exercise = workflowData.exercise;
        let newExData = [];

        exercise.forEach(item =>{
            let record = {
                id: item.id,
                code: item.code,
                description: item.description
            };
            newExData.push(record);
        })

        this.setState({
            workflowData: workflowData,
            workflow: workflowData,
            isEdit: true,
            records: newData,
            exerciseRecords: newExData,
            initialRecords: newData,
            initialExercises: newExData
        });
    };

    componentDidMount() {
        this.updateWorkflowTable();
    }

    updateWorkflowTable() {
        let { columns, t} = this.state;

        function getColumns (column,t) {
            var translation = t("portalSettings.workflows.fields."+column.field);
            if(!translation.includes("portalSettings.workflows.fields")){
                column.title = translation;
            }
        }

        function updateRow(data, t){
            if(data.name === "portalSettings.workflows.new_workflow.name") {
                data.isNewWorkflow = true;
                data.actualName = t(data.name);
                data.name = "<span class='time-label'><span class='bg-green'>" + t(data.name) + "</span></span>";
                data.description = t(data.description);
            } else {
                data.isNewWorkflow = false;
                data.actualName = data.name;
            }
        }

        WorkflowAPI.all().then(data => {
            _.forEach(data.columns, function (column) {
                getColumns(column, t)
            });

            columns = [...data.columns];

            _.forEach(data.data, function (data) {
                updateRow(data, t)
            });

            this.setState({
                columns: columns,
                data: data.data,
                workflow: new Workflow(),
                workflowData : null,
                records: [],
                selectState: null,
                isEdit: false,
                exerciseRecords: [],
                selectExercise: null,
                errors: {},
                initialRecords: [],
                initialExercises: []
            });
        });
    }

    updateCaseStates(){
        StateAPI.all().then(data => {
            this.setState({caseStates: data.data})
        })
    }

    updateExercises(){
        ExerciseAPI.all().then(data => {
            this.setState({exercises: data.data})
        })
    }

    updateWorkflowAttributes(workflowId){
        WorkflowAPI.getWorkflowAttributes(workflowId).then(data => {
            let dataAttributes = data.concat(this.defaultAttributes);
            this.setState({
                workflowAttributes:  this.generateSortedAttributeList(dataAttributes)
            })
        })
    }

    updateWorkflowColumns(id){
        WorkflowAPI.getWorkflowColumns(id).then(data => {
            this.setState({
                initialColumns: data,
                columnRecords: data
            })
        })
    }

    updateWorkflowCaseInfoConfig(id){
        WorkflowAPI.getCaseInfoConfig(id).then(data => {
            this.setState({
                initialCaseInfo: data,
                caseInfoRecords: data
            })
        })
    }

    errorHandling = error => {
        const { t } = this.state;

        if (error.response) {
            $.notification("error", t("common.notification.error"),
                t("portalSettings.workflows.validationError."+error.response.data));
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
    }

    closeModal = () => {
        $(function () {
            $('#modal-workflow').modal('hide');
        });
    }

    resetColumnOrder = list => {
        let order = 1;
        list.forEach(item =>{
            item.order = order;
            order++;
        })

        return list;
    }

    openApiConfigModal = () => {
        const {workflowAttributes} = this.state;

        MetadataFilterAPI.getFilteredMetadata(this.workflowId).then(data => {
            if(data) {
                this.setState({
                    filteredMetadata: {
                        workflowId: data.workflowId,
                        metadata: this.getFilteredMetadata(workflowAttributes, data.metadata)
                    }
                });
            } else {
                this.setState({
                    filteredMetadata: {
                        workflowId: this.workflowId,
                        metadata: []
                    }
                });
            }

            $(function () {
                $('#api-config-modal').modal('show');
            });
        });
    }

    handleMetadataSave = () => {
        const { t, filteredMetadata } = this.state;
        let metadataFilterDto = {
            workflowId: filteredMetadata.workflowId,
            metadata:  filteredMetadata.metadata.map(metadata => metadata.attribute)
        }

        MetadataFilterAPI.save(metadataFilterDto).then(() => {
            $("#columnSave").html(t("common.add"));
            $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
        }).catch(error => {
            this.errorHandling(error);
        });
    }

    generateAttributeName = (attribute) => {
        if(attribute.includes("metadata") || attribute.includes("auditInfo")){
            var valueArray = attribute.split(".");
            if(valueArray[1] !== undefined && valueArray[1] !== null){
                return valueArray[1];
            } else {
                return attribute;
            }
        } else {
            return attribute;
        }
    }

    handleMetadataChange = selectedMetadata => {
        this.setState({
            filteredMetadata: {
                workflowId: this.workflowId,
                metadata: selectedMetadata
            }
        });
    }



    generateSortedAttributeList = (workflowAttributesTemp) => {
        let workflowAttributesSorted = workflowAttributesTemp.map(option => {
            let attribName = this.generateAttributeName(option.attribute);
            if (
                attribName.includes("baseURL") ||
                attribName.includes("continuationURL") ||
                attribName.includes("daysOfInactivity") ||
                attribName.includes("daysTillDeletion") ||
                attribName.includes("sessionRef") ||
                attribName.includes("stateName") ||
                attribName.includes("stateType") ||
                attribName.includes("worker") ||
                attribName.includes("workflowId") ||
                attribName.includes("workflowName") ||
                attribName.includes("workflowRef")) {

                delete [option]
                return;
            } else if (
                attribName.includes("attachments") ||
                attribName.includes("Assign Worker") ||
                attribName.includes("workflowVersion") ||
                attribName.includes("caseState")) {

                return {
                    id: option.id,
                    attribute: option.attribute,
                    attributeName: `${attribName} (default)`
                }
            }
            else {
                return {
                    id: option.id,
                    attribute: option.attribute,
                    attributeName: attribName
                }
            }
        });

        workflowAttributesSorted.sort((a,b) => a.attributeName.localeCompare(b.attributeName))

        return workflowAttributesSorted.filter(data => {return data !== undefined;});
    }

    getFilteredMetadata = (sortedAttributes, filteredMetadata) => {
        return sortedAttributes
            .filter(data => filteredMetadata.includes(data.attribute));
    }

    render() {
        const { t } = this.props;
        const { workflowData, columns, data, workflowAttributes, filteredMetadata} = this.state;

        const caseInfoParams = { t, workflowAttributes, filteredMetadata };

        return (
            <Fragment>
                <div className="content-wrapper portal-admin admin-developer-settings">
                    <section className="content-header">
                        <h1>
                            <small>{t("portalSettings.superAdmin.title")} </small>
                        </h1>
                    </section>

                    <p className="super-admin-title">{t("portalSettings.superAdmin.title")}</p>

                    <section className="content">
                        <CommonTable rowClick={this.onRowClick} columns={columns} data={data} t={t} />

                        <div className="row">

                            <div className="col-xs-8">

                            </div>

                            <div className="col-xs-4 right">
                                {workflowData && (
                                    <div className="box">
                                        <div className="box-header">
                                            <div className="box-title">
                                                <p
                                                    className="lead"
                                                   style={{marginBottom:0}}>{t("portalSettings.superAdmin.properties.title")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            {workflowData && (
                                                <a
                                                    href="#/"
                                                    className="btn btn-app"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.openApiConfigModal();
                                                    }}
                                                >
                                              <span
                                                  className="fa fa-share-alt"
                                                  data-toggle="tooltip"
                                                  title="Case Info Panel"
                                              />
                                                    {t("portalSettings.superAdmin.properties.api.button")}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <APIConfigModal
                            params={caseInfoParams}
                            handleMetadataChange={this.handleMetadataChange}
                            handleMetadataSave={this.handleMetadataSave}
                        />
                    </section>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation("common")(SettingsSuperAdminPage);