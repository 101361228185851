import React, { Component } from "react";
import ConfigAPI from "../api/ConfigAPI";
import {withTranslation} from "react-i18next";

class FooterSection extends Component {
    state = {
        locale: this.props.locale,
        t: this.props.t
    };

    componentWillMount() {
        ConfigAPI.getLocale(window.ENV.REACT_APP_UI_LOCALES).then(data => {
            this.setState({
                locale: data.loginPage,
            });
        });
    }

  getYear() {
    return new Date().getFullYear();
  }

  render() {
      let { t } = this.state;

    return (
      <div>
        <footer className="main-footer">
          {/* Default to the left */}
          <div className="copyright text-center my-auto">
            <div>
              <img
                src="img/Footer_Compose_logo.svg"
                alt="compose_logo_icon"
                style={{
                  width: 20,
                  marginLeft: -5,
                  marginRight: 5,
                  marginTop: 0,
                  marginBottom: 0
                }}
              />
                {t('loginPage.copyright.segment01')}{" "}
              <a
                href="https://www.usecompose.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Compose
              </a>{" "}
              | © {t('loginPage.copyright.segment02')}
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default withTranslation("common")(FooterSection);