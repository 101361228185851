import React, { Component, Fragment } from "react";
import AuthUtil from "../../utils/AuthUtil";
import { withTranslation } from "react-i18next";
import Loading from "./Loading";
import UserAPI from "../../api/UserAPI";
import CaseAPI from "../../api/CaseAPI";
import { CommonContext } from "../../context/CommonContext";
import _ from "lodash";
import Select from "react-select";
import feather from 'feather-icons';
const $ = window.$;

class CaseActionPanel extends Component {
  state = {
    caseDetail: this.props.caseData,
    showComment: false,
    comment: "",
    actionType: null,
    sendParams: null,
    showApprove: false,
    showLock: false,
    showDel: false,
    showUnlock: false,
    showSentback: false,
    showReject: false,
    showCaseAssign: false,
    showCaseLink: false,
    showAddCommentAction: false,
    loggedInUserId: null,
    textRemaining: 4000,
    maxTextLength: 4000,
    t: this.props.t,
    isPortalAdmin: false,
    showAssignComment: false,
    showLinkCaseComment: false,
    userData: [],
    assignWorkerId: null,
    allCaseData: [],
    records: [],
    showCompleteAction: false,
    showRestoreAction: false,

    title: "",
    description: "",
    confirmActionButtonLabel: "",
    cancelActionButtonLabel: "",

    isCommentSection: false,
    isMandatoryComment: false,
    isCaseHistoryEntry: false,
    isCaseHistoryLogPdf: false,

    caseHistoryEntryTitle: "",
    commentHintText: "",
    isDisplayActionBasedOnDate: false,
    successMessage: "",

    caseLockAccessUsrList: []
  };

  static contextType = CommonContext

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    this.updateUserTable();
    this.getAllCaseData();
    feather.replace();
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
    feather.replace();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ caseDetail: nextProps.caseData });
    this.isDisplayActionBasedOnDate();
  }

  componentWillMount() {
    this.getLogedInUser();
  }

  actionClick = (type, sendParams, actionObject) => {
    const {caseDetail} = this.state;
    sendParams.type=actionObject.type;
    sendParams.workflowLink = actionObject.workflowLink;
    if (actionObject.enable!=undefined && actionObject.enable!=null && actionObject.enable === false) {
      const { t } = this.state;
      _.forEach(actionObject.caseExpressions, function (obj) {
        if (obj.expressionType === "enable" && obj.information !== "") {
          $.notification("info", t("common.notification.info"), obj.information);
          return false;
        }
      });
    } else if(caseDetail.lockedBy) {
       if(this.isEnabledAction()) {
         switch (type) {
           case "UNLOCK":
             this.setState({ actionType: type, sendParams });
             this.showCommentBox(actionObject);
             break;
           case "VIEW_PDF":
             this.props.actionClick(type, this.state.caseDetail, sendParams);
             break;
           default:
         }
       }
    } else {
      if(actionObject.type=='1') {
        switch (type) {
          case "SEND_BACK":
            sendParams.email = this.state.user.email;
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "REJECT":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "APPROVED":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "ASSIGN":
            this.setState({ actionType: type, sendParams });
            this.showAssignCommentBox(actionObject);
            break;
          case "LINK":
            this.setState({ actionType: type, sendParams });
            this.showLinkCaseBox(actionObject);
            break;
          case "ADD_INFO":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "SET_GRADATION":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "ADD_COMMENT":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "COMPLETE":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "RESTORE":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "DELETE":
            this.props.actionClick(type, this.state.caseDetail, sendParams);
            this.context.handleSideMenuUpdate(true);
            break;
          case "LOCK":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          case "UNLOCK":
            this.setState({ actionType: type, sendParams });
            this.showCommentBox(actionObject);
            break;
          default:
            sendParams.isCaseHistoryEntry = actionObject.isCaseHistoryEntry;
            sendParams.isCaseHistoryLogPdf = actionObject.isCaseHistoryLogPdf;
            sendParams.caseHistoryEntryTitle = actionObject.caseHistoryEntryTitle;
            sendParams.successMessage = actionObject.successMessage;
            this.setState({ showComment: false });
            this.props.actionClick(type, this.state.caseDetail, sendParams);
        }
      } else {
        sendParams.isCaseHistoryEntry = actionObject.isCaseHistoryEntry;
        sendParams.isCaseHistoryLogPdf = actionObject.isCaseHistoryLogPdf;
        sendParams.caseHistoryEntryTitle = actionObject.caseHistoryEntryTitle;
        sendParams.successMessage = actionObject.successMessage;
        sendParams.type = actionObject.type;
        this.setState({ actionType: type, sendParams });
        this.showCommentBox(actionObject);
      }
    }    
  };

  showCommentBox = (actionObject) => {
    this.setState({
      showComment: true,
      showAssignComment: false,
      showLinkCaseComment: false,

      title: actionObject.title,
      description: actionObject.description,
      confirmActionButtonLabel: actionObject.confirmActionButtonLabel,
      cancelActionButtonLabel: actionObject.cancelActionButtonLabel,
      isCommentSection: actionObject.isCommentSection === "true" ? true : false,
      isMandatoryComment: actionObject.isMandatoryComment === "true" ? true : false,
      isCaseHistoryEntry: actionObject.isCaseHistoryEntry === "true" ? true : false,
      isCaseHistoryLogPdf: actionObject.isCaseHistoryLogPdf === "true" ? true : false,
      caseHistoryEntryTitle: actionObject.caseHistoryEntryTitle,
      commentHintText: actionObject.commentHintText,
      successMessage: actionObject.successMessage
    });
  };

  showDeletePopup = (actionObject) => {
    let {sendParams}  = this.state;
    sendParams = {stateTransition : actionObject.caseTransitionState != null && actionObject.caseTransitionState != "" ? actionObject.caseTransitionState : "delete"};
    
    this.setState({
      showComment: false,
      showAssignComment: false,
      showLinkCaseComment: false,

      title: actionObject.title,
      description: actionObject.description,
      confirmActionButtonLabel: actionObject.confirmActionButtonLabel,
      cancelActionButtonLabel: actionObject.cancelActionButtonLabel,
      successMessage: actionObject.successMessage,
      actionObject: actionObject,
      sendParams
    });

    $(function () {
      $('#caseDeleteAlert').modal('show');
    });
  };

  showAssignCommentBox = (actionObject) => {
    this.setState({
      showAssignComment: true,
      showLinkCaseComment: false,
      showComment: false,

      title: actionObject.title,
      description: actionObject.description,
      confirmActionButtonLabel: actionObject.confirmActionButtonLabel,
      cancelActionButtonLabel: actionObject.cancelActionButtonLabel,
      isCommentSection: actionObject.isCommentSection === "true" ? true : false,
      isMandatoryComment: actionObject.isMandatoryComment === "true" ? true : false,
      isCaseHistoryEntry: actionObject.isCaseHistoryEntry === "true" ? true : false,
      isCaseHistoryLogPdf: actionObject.isCaseHistoryLogPdf === "true" ? true : false,
      caseHistoryEntryTitle: actionObject.caseHistoryEntryTitle,
      commentHintText: actionObject.commentHintText,
      successMessage: actionObject.successMessage
    });
  };

  showLinkCaseBox = (actionObject) => {
    this.setState({
      showLinkCaseComment: true,
      showAssignComment: false,
      showComment: false,

      title: actionObject.title,
      description: actionObject.description,
      confirmActionButtonLabel: actionObject.confirmActionButtonLabel,
      cancelActionButtonLabel: actionObject.cancelActionButtonLabel,
      isCommentSection: actionObject.isCommentSection === "true" ? true : false,
      isMandatoryComment: actionObject.isMandatoryComment === "true" ? true : false,
      isCaseHistoryEntry: actionObject.isCaseHistoryEntry === "true" ? true : false,
      isCaseHistoryLogPdf: actionObject.isCaseHistoryLogPdf === "true" ? true : false,
      caseHistoryEntryTitle: actionObject.caseHistoryEntryTitle,
      commentHintText: actionObject.commentHintText,
      successMessage: actionObject.successMessage
    });
  };

  handleCommentChange = event => {
    const { name, value } = event.target;
    var textLength = value.length;
    var textRemaining = 4000 - textLength;
    this.setState({ [name]: value, textRemaining: textRemaining });
  };

  handleCommentSend = () => {
    let {
      caseDetail,
      comment,
      actionType,
      sendParams,
      textRemaining,
      maxTextLength,
      records,
      t,
      isCommentSection,
      isMandatoryComment,

      isCaseHistoryEntry,
      isCaseHistoryLogPdf,
      caseHistoryEntryTitle,
      successMessage,
      caseLockAccessUsrList
    } = this.state;

    sendParams.isCaseHistoryEntry = isCaseHistoryEntry;
    sendParams.isCaseHistoryLogPdf = isCaseHistoryLogPdf;
    sendParams.caseHistoryEntryTitle = caseHistoryEntryTitle;
    sendParams.successMessage = successMessage;
    sendParams.accessUserList = caseLockAccessUsrList;

    if (isCommentSection && isMandatoryComment) {
      if (textRemaining < maxTextLength) {
        if (actionType === "ASSIGN") {
          sendParams.assignWorkerId = $("#select2").find(':selected').val();
          sendParams.email = $("#select2").find(':selected').attr('email');
        } else if (actionType === "LINK") {
          var selectedValue = $(".select3").val();
          var newData = [];
          $.each(selectedValue, function (index, value) {
            newData.push(JSON.parse(value));
          })
          sendParams.records = newData;
        }
        sendParams.comment = comment;
        this.props.actionClick(actionType, caseDetail, sendParams);
        this.setState({ showComment: false });
      } else {
        $.notification("error", t("common.notification.error"), t("caseManagement.actionAlert." + actionType));
      }
    } else {
      if (actionType === "ASSIGN") {
        sendParams.assignWorkerId = $("#select2").find(':selected').val();
        sendParams.email = $("#select2").find(':selected').attr('email');
      } else if (actionType === "LINK") {
        var selectedValue = $(".select3").val();
        var newData = [];
        $.each(selectedValue, function (index, value) {
          newData.push(JSON.parse(value));
        })
        sendParams.records = newData;
      }
      sendParams.comment = comment;
      this.props.actionClick(actionType, caseDetail, sendParams);
      this.setState({ showComment: false });
    }
  };

  handleCommentCancel = () => {
    this.setState({
      showComment: false,
      showAssignComment: false,
      showLinkCaseComment: false,
      textRemaining: 4000,

      title: "",
      description: "",
      confirmActionButtonLabel: "",
      cancelActionButtonLabel: "",

      isCommentSection: false,
      isMandatoryComment: false,
      isCaseHistoryEntry: false,
      isCaseHistoryLogPdf: false,

      caseHistoryEntryTitle: "",
      commentHintText: ""
    });
  };

  handleCaseActionAlert = () => {
    $("#caseActionAlert")
      .appendTo("body")
      .modal("show");
  };

  getLogedInUser = () => {
    return AuthUtil.getLoggedInUser().then(user => {
      this.setState({ user: user, loggedInUserId: user.id });
      this.isShowCngActionButtons();
    });
  };

  isPortalAdminUser = () => {
    return this.state.isPortalAdmin;
  }

  orderActionList(actions) {
    actions.sort((a, b) => a.order - b.order);
    return actions;
  }

  isShowCngActionButtons = () => {
    let { caseDetail, user } = this.state;
    var actions = [];
    let orderedList = this.orderActionList(caseDetail.actions);

    caseDetail.actions.forEach(item => {
      actions.push(item.name);
    })

    caseDetail.actions = orderedList;

    this.setState({
      showApprove: actions.includes("APPROVED"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showLock: actions.includes("LOCK"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showDel: actions.includes("DELETE"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN"], caseDetail.workflowId),
      showUnlock: actions.includes("UNLOCK"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showSentback: actions.includes("SEND_BACK"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showReject: actions.includes("REJECT"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showCaseAssign: actions.includes("ASSIGN"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "INSPECTOR", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showCaseLink: actions.includes("LINK"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showAddInfo: actions.includes("ADD_INFO"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showSetGradation: actions.includes("SET_GRADATION"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showAddCommentAction: actions.includes("ADD_COMMENT"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "INSPECTOR", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showCompleteAction: actions.includes("COMPLETE"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      showRestoreAction: actions.includes("RESTORE"),// && AuthUtil.hasRoles(user, ["PORTAL_ADMIN", "CASE_MANAGER", "CASE_SUPERVISOR"], caseDetail.workflowId),
      isPortalAdmin: AuthUtil.hasRoles(user, ["PORTAL_ADMIN"], caseDetail.workflowId),
      caseDetail: caseDetail,
      isDisplayActionBasedOnDate: this.isDisplayActionByDetails(caseDetail)
    });
  };

  populateUserSelect = () => {
    $(function () {
      $("#select2").select2();
    })
  };

  populateCaseSelect = () => {
    $(function () {
      $("#select3").select2();
    })
  };

  updateUserTable() {
    let { caseDetail } = this.state;
    UserAPI.getUsersByWorkflowId(caseDetail.workflowId).then(data => {
      this.setState({ userData: data.data });
    });
  }

  handleUserChange = event => {
    let { name, value } = event.target;
    this.setState({
      selectedUserId: value
    });
  }

  getAllCaseData = () => {
    let { caseDetail } = this.state;
  
    // Check if actions array contains an object with name equal to "LINK"
    const hasLinkAction = caseDetail.actions?.some(action => action.name === "LINK");
  
    // If there is a "LINK" action, proceed with the API call
    if (hasLinkAction) {
      CaseAPI.allCases(caseDetail.workflowId, caseDetail.exerciseId, "All_Applications", this.props.t)
        .then(AllCaseData => {
          this.setState({
            allCaseData: AllCaseData.data
          });
        });
    }
  };

  isDisplayActionBasedOnDate = () => {
    let { caseDetail } = this.state;

    var conditionalDate = new Date(2022, 2, 10, 0, 0, 0, 0);
    var submittedDate = new Date(caseDetail.auditInfo.createdDate);
    this.setState({
      isDisplayActionBasedOnDate: submittedDate > conditionalDate
    });
  }

  isDisplayActionByDetails = (caseDetail) => {
    var conditionalDate = new Date(2022, 2, 10, 0, 0, 0, 0);
    var submittedDate = new Date(caseDetail.auditInfo.createdDate);

    return submittedDate > conditionalDate;
  }

  addRecord = () => {
    var selectedValue = $(".select3").val();
    var newData = [];

    $.each(selectedValue, function (index, value) {
      newData.push(JSON.parse(value));
    })

    this.setState({
      textRemaining: 0,
      records: newData
    });
  }

  deleteRecord = idx => {
    const { records } = this.state;

    records.splice(idx, 1);
    this.setState({
      records: records
    });
  };

  deleteClick = () => {
    let {sendParams, actionObject}  = this.state;
    this.actionClick("DELETE", sendParams, actionObject);
  }

  getFormattedUserList = userList => {
    const {loggedInUserId} = this.state;
    return userList
        .filter(user => user.id !== loggedInUserId)
        .map(user => this.getUserData(user))
        .sort((a, b) => a.fullName > b.fullName ? 1 : -1);
  }

  getUserData = (user) => {
    return  {
      uniqueId: user.id,
      fullName: user.firstName + ' ' + user.lastName
    }
  }

  handleMultiUserChange = (users) => {
    this.setState({
      caseLockAccessUsrList: users
    });
  }

  isEnabledAction = () => {
    const {loggedInUserId, caseDetail} = this.state;
    if (!caseDetail.lockedBy)
      return true;
    return (String(loggedInUserId) === caseDetail.lockedBy ||
        this.isPortalAdminUser() ||
        this.isGrantedUser(caseDetail.lockAccessUserList, loggedInUserId));
  }

  isGrantedUser = (accessUserList, loggedInUserId) => {
      return accessUserList.some(user => user.uniqueId === String(loggedInUserId));
  };

  renderIcon(iconCode) {
    if (iconCode.startsWith('fa') || iconCode.startsWith('glyphicon')) {
        return <i className={iconCode} />; 
    } else {
        return <span className="icon-container"><i data-feather={iconCode} className="icon-class" /></span>;     
    }
  }

  loadIconWithStyle(option, unlockFaStyles) {
    return option.icon.startsWith('fa') || option.icon.startsWith('glyphicon') ? (
      <i className={option.icon} style={unlockFaStyles.icon} />
    ) : (
      <span className="icon-container">
        <i data-feather={option.icon} className="icon-class" style={unlockFaStyles.icon} />
      </span>
    );
  }

  render() {
    const {
      caseDetail,
      showComment,
      showApprove,
      showLock,
      showDel,
      showUnlock,
      showSentback,
      showReject,
      showCaseAssign,
      showCaseLink,
      loggedInUserId,
      maxTextLength,
      textRemaining,
      actionType,
      t,
      showAssignComment,
      userData,
      showLinkCaseComment,
      allCaseData,
      records,
      showAddInfo,
      showSetGradation,
      showAddCommentAction,
      showCompleteAction,
      showRestoreAction,

      title,
      description,
      confirmActionButtonLabel,
      cancelActionButtonLabel,
      isCommentSection,
      isMandatoryComment,
      isCaseHistoryEntry,
      isCaseHistoryLogPdf,
      caseHistoryEntryTitle,
      commentHintText,
      successMessage,
    } = this.state;

    const { loading } = this.props;

    const buttonStyle = `btn btn-app ${loggedInUserId !== caseDetail.lockedBy && caseDetail.lockedBy ? "disabled" : ""
      }`;
    const caseLockUnlockStyle = `fa ${caseDetail.lockedBy ? "fa-unlock" : "fa-lock"
      }`;

    const lockButtonStyle = `btn btn-app ${(loggedInUserId === caseDetail.lockedBy) || this.isPortalAdminUser() ?  "" : "disabled"
      }`;
    let boxBodyClass = "box-body";
    let isDisplayActionBasedOnDate = this.isDisplayActionByDetails(caseDetail);

    caseDetail.actions = this.orderActionList(caseDetail.actions);

    let filteredAllCaseData;
    if (caseDetail.linkedCases) {
      filteredAllCaseData = allCaseData.filter(caseData =>
          caseDetail.linkedCases.every(linkedCase =>
              linkedCase.uniqueId !== caseData.uniqueId));
    } else {
      filteredAllCaseData = allCaseData;
    }

    const formattedUserList = this.getFormattedUserList(userData);

    const isEnabled = this.isEnabledAction();

    const unlockStyles = {
       actionButton : {
         backgroundColor: isEnabled ? "#ff474c" : "#f4f4f4"
       }
    }

    const viewPdfStyles = {
      actionButton : {
        backgroundColor: caseDetail.lockedBy && isEnabled ? "#ff474c" : "#f4f4f4"
      }
    }

    const unlockFaStyles = {
       icon: {
         color: isEnabled ? "#f4f4f4" : "#666"
       }
    }

    return (
      <Fragment>
        <div className="box">
          <div className="box-header">
            <p className="lead" style={{ marginBottom: 0 }}>{t("caseManagement.actionPanel.title")}</p>
          </div>

          <div className="box-body">
            <div className={boxBodyClass}>
            <p>{ caseDetail.caseStateHelpText}</p>
              {caseDetail &&
                caseDetail.actions.sort((a, b) => a.order > b.order ? 1 : -1).map(option => {
                  return (
                    <span key={'ACTION_' + option.id}>
                      {option.name == "VIEW_PDF" && caseDetail.mainPdf && (
                        <button
                          href="#/"
                          className={`btn btn-app ${isEnabled ? 
                              option.enable == false ? "disabled" : "" : "disabled"}`}
                          onClick={e => {
                            e.preventDefault();
                            this.actionClick("VIEW_PDF", {}, option);
                          }}
                          data-toggle={option.tooltip}
                          title={option.title}
                        >
                          { this.loadIconWithStyle(option, unlockFaStyles) }
                          {option.tooltip}
                        </button>
                      )}

                      {option.name == "ASSIGN" && showCaseAssign && (
                        <button
                          href="#/"
                          className={`btn btn-app ${caseDetail.lockedBy && caseDetail.lockedBy ? "disabled" : option.enable == false ? "disabled" : ""}`}
                          onClick={e => {
                            e.preventDefault();
                            this.actionClick("ASSIGN", { stateTransition: option.caseTransitionState != null && option.caseTransitionState != "" ? option.caseTransitionState : "Assign" }, option);
                          }}
                          data-toggle={option.tooltip}
                          title={option.title}
                        >
                          { this.renderIcon(option.icon) }                                                   
                          {option.tooltip}
                        </button>
                      )}

                      {option.name == "LINK" && showCaseLink && (
                        <button
                          href="#/"
                          className={`btn btn-app ${caseDetail.lockedBy && caseDetail.lockedBy ? "disabled" : option.enable == false ? "disabled" : ""}`}
                          onClick={e => {
                            e.preventDefault();
                            this.actionClick("LINK", { stateTransition: option.caseTransitionState != null && option.caseTransitionState != "" ? option.caseTransitionState : "Link" }, option);
                          }}
                          data-toggle={option.tooltip}
                          title={option.title}
                        >
                          { this.renderIcon(option.icon) }

                          {option.tooltip}
                        </button>
                      )}

                      {option.name == "LOCK" && showLock && !caseDetail.lockedBy && (
                        <button
                          href="#/"
                          className={`btn btn-app ${caseDetail.lockedBy && caseDetail.lockedBy ? "disabled" : option.enable == false ? "disabled" : ""}`}
                          onClick={e => {
                            e.preventDefault();
                            this.actionClick("LOCK", {}, option);
                          }}
                          data-toggle={option.tooltip}
                          title={option.title}
                        >
                          { this.renderIcon(option.icon) }
                          {option.tooltip}
                        </button>
                      )}

                      {option.name == "UNLOCK" && showUnlock && caseDetail.lockedBy && (
                        <button
                          href="#/"
                          style={unlockStyles.actionButton}
                          className={`btn btn-app ${isEnabled ? option.enable == false ? "disabled" : "" : "disabled"}`}
                          onClick={e => {
                            e.preventDefault();
                            this.actionClick("UNLOCK", {}, option);
                          }}
                          data-toggle={option.tooltip}
                          title={option.title}
                        >

                          { this.loadIconWithStyle(option, unlockFaStyles) }

                          {option.tooltip}
                        </button>
                      )}

                      {option.name == "ADD_COMMENT" && showAddCommentAction && (
                        <button
                          href="#/"
                          className={`btn btn-app ${caseDetail.lockedBy && caseDetail.lockedBy ? "disabled" : option.enable == false ? "disabled" : ""}`}
                          onClick={e => {
                            e.preventDefault();
                            this.actionClick("ADD_COMMENT", { stateTransition: option.caseTransitionState != null && option.caseTransitionState != "" ? option.caseTransitionState : "add_comment" }, option);
                          }}
                          data-toggle={option.tooltip}
                          title={option.title}
                        >
                          { this.renderIcon(option.icon) }
                          {option.tooltip}
                        </button>
                      )}

                      {option.name == "DELETE" && showDel && (
                          <button
                            href="#/"
                            className={`btn btn-app ${caseDetail.lockedBy && caseDetail.lockedBy ? "disabled" : option.enable == false ? "disabled" : ""}`}
                            onClick={e => {
                              e.preventDefault();
                              this.showDeletePopup(option);
                            }}
                            data-toggle={option.tooltip}
                            title={option.title}
                          >
                            { this.renderIcon(option.icon) }
                            {option.tooltip}
                          </button>
                      )}

                      {option.type !== "1"  && option.pinned !== "true" && (
                          <button
                              href="#/"
                              className={`btn btn-app ${caseDetail.lockedBy && caseDetail.lockedBy ? "disabled" : option.enable == false ? "disabled" : ""}`}
                              onClick={e => {
                                e.preventDefault();
                                this.actionClick(option.name, { stateTransition: option.caseTransitionState }, option);
                              }}
                              data-toggle={option.tooltip}
                              title={option.title}
                          >
                            { this.renderIcon(option.icon) }
                            {option.tooltip}
                          </button>
                      )}


                    </span>
                  )
                })
              }

            </div>
          </div>

          {showComment && (
            <div className="box-footer">
              <div className="form-group">
                <label>
                  {title}
                </label>

                <p align="justify" style={{ fontSize: 13 }}>
                  {description}
                </p>

                {isCommentSection && (
                  <span>
                    <textarea
                      className="form-control"
                      rows="5"
                      maxLength={maxTextLength}
                      type="text"
                      name="comment"
                      placeholder={commentHintText}
                      onChange={this.handleCommentChange}
                    ></textarea>
                    <span className="pull-right">
                      {textRemaining}/{maxTextLength}
                    </span>
                    <br />
                    <br />
                  </span>
                )
                }

                {
                   actionType === 'LOCK' && (
                    <>
                      <label className="cap-ul-label">
                        {t("caseManagement.actionPanel.case_lock.access_user")}
                      </label>

                      <Select
                          classNamePrefix="user-list-select"
                          isMulti
                          placeholder={t("caseManagement.caseActions.fields.type.options.initial")}
                          getOptionLabel={option => option.fullName}
                          getOptionValue={option => option.uniqueId}
                          onChange={this.handleMultiUserChange}
                          options={formattedUserList}
                      />
                    </>
                   )
                }

              </div>

              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-default btn-flat"
                  onClick={this.handleCommentSend}
                >
                  {confirmActionButtonLabel}
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-flat"
                  style={{ marginLeft: 5 }}
                  onClick={this.handleCommentCancel}
                >
                  {cancelActionButtonLabel}
                </button>
              </div>
            </div>
          )}

          {showAssignComment && (
            <div className="box-footer">
              <div className="form-group">
                <label>
                  {title}
                </label>

                <p align="justify" style={{ fontSize: 13 }}>
                  {description}
                </p>

                <select
                  className="form-control select2"
                  id="select2"
                  onChange={this.handleUserChange}
                >
                  <option key={'default'} value="">{t("caseManagement.actionPanel.case_assign.case_worker_default")}</option>
                  {userData &&
                    userData.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(option => {
                      return (
                        <option key={option.id} value={option.firstName + ' ' + option.lastName} email={option.email}>
                          {option.firstName + ' ' + option.lastName}
                        </option>
                      )
                    })
                  }
                </select>

                {this.populateUserSelect()}
                <div style={{ height: 10 }}></div>

                {isCommentSection && (
                  <span>
                    <textarea
                      className="form-control"
                      rows="5"
                      maxLength={maxTextLength}
                      type="text"
                      name="comment"
                      placeholder={commentHintText}
                      onChange={this.handleCommentChange}
                    ></textarea>
                    <span className="pull-right">
                      {textRemaining}/{maxTextLength}
                    </span>
                    <br />
                    <br />
                  </span>
                )}

              </div>

              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-default btn-flat"
                  onClick={this.handleCommentSend}
                >
                  {confirmActionButtonLabel}
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-flat"
                  style={{ marginLeft: 5 }}
                  onClick={this.handleCommentCancel}
                >
                  {cancelActionButtonLabel}
                </button>
              </div>
            </div>

          )}

          {showLinkCaseComment && (
            <div className="box-footer">
              <div className="form-group">
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ border: 0 }}>
                        <label>
                          {title}
                        </label>

                        <p align="justify" style={{ fontSize: 13 }}>
                          {description}
                        </p>

                        {isCommentSection && (
                          <span>
                            <textarea
                              className="form-control"
                              rows="5"
                              maxLength={maxTextLength}
                              type="text"
                              name="comment"
                              placeholder={commentHintText}
                              onChange={this.handleCommentChange}
                            ></textarea>
                            <span className="pull-right">
                              {textRemaining}/{maxTextLength}
                            </span>
                            <br />
                            <br />
                          </span>
                        )}
                      </td>
                      <td style={{ border: 0 }}></td>
                    </tr>
                    <tr>
                      <td style={{ border: 0 }}>
                        <select
                          className="form-control select3"
                          id="select3"
                          name="cases[]"
                          onChange={this.handleUserChange}
                          multiple="multiple"
                        >

                          {filteredAllCaseData &&
                              filteredAllCaseData.map(option => {
                              if (option.uniqueId !== caseDetail.uniqueId) {
                                return (
                                  <option key={option.uniqueId} value={'{"uniqueId":"' + option.uniqueId + '", "workflowName":"' + option.workflowName + '"}'}>
                                    {option.uniqueId + ' - ' + option.workflowName}
                                  </option>
                                )
                              }
                            })
                          }
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {this.populateCaseSelect()}

                <p align="justify" style={{ fontSize: 13 }}>
                </p>
              </div>

              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-default btn-flat"
                  onClick={this.handleCommentSend}
                >
                  {confirmActionButtonLabel}
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-flat"
                  style={{ marginLeft: 5 }}
                  onClick={this.handleCommentCancel}
                >
                  {cancelActionButtonLabel}
                </button>
              </div>
            </div>

          )}

          <div className="modal fade" id="caseDeleteAlert">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  {title}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{description}</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary col-md-2 pull-right"
                    data-dismiss="modal"
                    onClick={e => {
                      e.preventDefault();
                      this.deleteClick();
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    {confirmActionButtonLabel}
                  </button>

                  <button
                    type="button"
                    className="btn btn-default col-md-2 pull-right"
                    data-dismiss="modal"
                  >
                    {cancelActionButtonLabel}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="caseActionAlert">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{t("caseManagement.actionAlert." + actionType)}</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default col-md-2 pull-right"
                    data-dismiss="modal"
                  >
                    {t("common.ok")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {loading && <Loading />}
        </div>
      </Fragment>
    );
  }

}

export default withTranslation("common")(CaseActionPanel);
