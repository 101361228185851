import React, { Component } from 'react'

export class CaseActionModal extends Component {
    render() {
        const { t, isEdit, errors, stateData, caseActions, handleRecords, predefinedActionList, workflowList } = this.props.params
        return (
            <div className="modal fade" id="modal-case-action" ref="modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">{isEdit && (t("common.edit")) || (t("common.add"))} {t("portalSettings.caseActions.actions.add.title")}</h4>
                        </div>
                        <div className="modal-body">

                            <ul className="nav nav-tabs pb-10">
                                <li className="nav-item active"><a className="nav-link" data-toggle="tab" href="#titleTab" role="tab">{t("caseManagement.caseActions.tabMenu.title")}</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#settingsTab" role="tab">{t("caseManagement.caseActions.tabMenu.settings")}</a></li>
                            </ul>


                            <div className="tab-content">
                                <div className="tab-pane active" id="titleTab" role="tabpanel">
                                    <form role="form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={
                                                    errors.title ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="title">
                                                        {t("caseManagement.caseActions.fields.title.name")}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        className="form-control"
                                                        id="title"
                                                        defaultValue={stateData && isEdit && (stateData.title)}
                                                        onChange={this.props.handleChange}
                                                        placeholder={t("caseManagement.caseActions.fields.title.placeholder")}
                                                    />
                                                    {errors.name && (
                                                        <span className="help-block">{errors.title}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={
                                                    errors.tooltip ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="tooltip">
                                                        {t("caseManagement.caseActions.fields.tooltip.name")}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="tooltip"
                                                        className="form-control"
                                                        id="tooltip"
                                                        defaultValue={stateData && isEdit && (stateData.tooltip)}
                                                        onChange={this.props.handleChange}
                                                        placeholder={t("caseManagement.caseActions.fields.tooltip.placeholder")}
                                                    />
                                                    {errors.tooltip && (
                                                        <span className="help-block">{errors.tooltip}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={
                                                    errors.icon ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="icon">
                                                        {t("caseManagement.caseActions.fields.icon.name")}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="icon"
                                                        className="form-control"
                                                        id="icon"
                                                        defaultValue={stateData && isEdit && (stateData.icon)}
                                                        onChange={this.props.handleChange}
                                                        placeholder={t("caseManagement.caseActions.fields.icon.placeholder")}
                                                    />
                                                    {errors.icon && (
                                                        <span className="help-block">{errors.icon}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6" id="successMessageCol">
                                                <div className={
                                                    errors.successMessage ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="successMessage">
                                                        {t("caseManagement.caseActions.fields.successMessage.name")}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="successMessage"
                                                        className="form-control"
                                                        id="successMessage"
                                                        defaultValue={stateData && isEdit && (stateData.successMessage)}
                                                        onChange={this.props.handleChange}
                                                        placeholder={t("caseManagement.caseActions.fields.successMessage.placeholder")}
                                                    />
                                                    {errors.successMessage && (
                                                        <span className="help-block">{errors.successMessage}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={
                                            errors.description ? "form-group has-error" : "form-group"
                                        } id="descriptionRow">
                                            <label htmlFor="description">
                                                {t("caseManagement.caseActions.fields.description.name")}
                                            </label>
                                            <textarea
                                                type="text"
                                                name="description"
                                                className="form-control"
                                                id="description"
                                                defaultValue={stateData && isEdit && (stateData.description)}
                                                onChange={this.props.handleChange}
                                                placeholder={t("caseManagement.caseActions.fields.description.placeholder")}
                                            ></textarea>
                                            {errors.description && (
                                                <span className="help-block">{errors.description}</span>
                                            )}
                                        </div>
                                        <div className="row" id="actionButtonLabelRow">
                                            <div className="col-md-6">
                                                <div className={
                                                    errors.confirmActionButtonLabel ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="confirmActionButtonLabel">
                                                        {t("caseManagement.caseActions.fields.confirmActionButtonLabel.name")}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="confirmActionButtonLabel"
                                                        className="form-control"
                                                        id="confirmActionButtonLabel"
                                                        defaultValue={stateData && isEdit && (stateData.confirmActionButtonLabel)}
                                                        onChange={this.props.handleChange}
                                                        placeholder={t("caseManagement.caseActions.fields.confirmActionButtonLabel.placeholder")}
                                                    />
                                                    {errors.confirmActionButtonLabel && (
                                                        <span className="help-block">{errors.confirmActionButtonLabel}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={
                                                    errors.cancelActionButtonLabel ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="description">
                                                        {t("caseManagement.caseActions.fields.cancelActionButtonLabel.name")}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="cancelActionButtonLabel"
                                                        className="form-control"
                                                        id="cancelActionButtonLabel"
                                                        defaultValue={stateData && isEdit && (stateData.cancelActionButtonLabel)}
                                                        onChange={this.props.handleChange}
                                                        placeholder={t("caseManagement.caseActions.fields.cancelActionButtonLabel.placeholder")}
                                                    />
                                                    {errors.cancelActionButtonLabel && (
                                                        <span className="help-block">{errors.cancelActionButtonLabel}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane" id="settingsTab" role="tabpanel">
                                    <form role="form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={
                                                    errors.type ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="type">
                                                        {t("caseManagement.caseActions.fields.type.name")}
                                                    </label>
                                                    <select
                                                        className="form-control"
                                                        id="type"
                                                        name="type"
                                                        onChange={this.props.handleCaseActionTypeChange}
                                                        value={this.props.typeSelected}
                                                    >
                                                        <option key="0" value="">{t("caseManagement.caseActions.fields.type.options.initial")}</option>
                                                        <option key="1" value="1" >{t("caseManagement.caseActions.fields.type.options.predefinedActions")}</option>
                                                        <option key="2" value="2" >{t("caseManagement.caseActions.fields.type.options.formActions")}</option>
                                                        <option key="3" value="3" >{t("caseManagement.caseActions.fields.type.options.workflowActions")}</option>
                                                        <option key="4" value="4" >{t("caseManagement.caseActions.fields.type.options.LaunchNewWorkflow")}</option>
                                                    </select>
                                                    {errors.type && (
                                                        <span className="help-block">{errors.type}</span>
                                                    )}
                                                </div>
                                            </div>
                                            {this.props.params.isDisplayPredefinedActions==true && (
                                                <div className="col-md-6">
                                                    <div className={
                                                        errors.predefinedActions ? "form-group has-error" : "form-group"
                                                    }>
                                                        <label htmlFor="predefinedActions">
                                                            {t("caseManagement.caseActions.fields.predefinedActions.name")}
                                                        </label>
                                                        <select
                                                            className="form-control"
                                                            id="predefinedActions"
                                                            name="predefinedActions"
                                                            onChange={this.props.handleChange}>
                                                            <option key="0" value="">-- Select --</option>
                                                            {predefinedActionList &&
                                                                predefinedActionList.map(option => {
                                                                    return (
                                                                        <option key={option} value={option}>
                                                                            {this.props.generateTitle("caseManagement.caseActions.fields.predefinedActions.options", option, option)}
                                                                        </option>
                                                                    )

                                                                })
                                                            }
                                                        </select>
                                                        {errors.predefinedActions && (
                                                            <span className="help-block">{errors.predefinedActions}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}




                                            {this.props.params.isDisplayWorkflowLink == true && (
                                                <div className="col-md-6">
                                                    <div className={
                                                        errors.workflowLink ? "form-group has-error" : "form-group"
                                                    }>
                                                        <label htmlFor="workflowLink">
                                                            {t("caseManagement.caseActions.fields.workflowLink.name")}
                                                        </label>


                                                        <input
                                                            type="text"
                                                            name="workflowLink"
                                                            className="form-control"
                                                            id="workflowLink"
                                                            defaultValue={stateData && isEdit && (stateData.workflowLink)}
                                                            onChange={this.props.handleChange}
                                                            placeholder={t("caseManagement.caseActions.fields.workflowLink.placeholder")}
                                                        />


                                                        {errors.workflowLink && (
                                                            <span className="help-block">{errors.workflowLink}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            )}



                                            <div className="col-md-6" id="caseTransitionStateRow">
                                                <div className={
                                                    errors.caseTransitionState ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="caseTransitionState">
                                                        {t("caseManagement.caseActions.fields.workflowActionTitle.name")}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="caseTransitionState"
                                                        className="form-control"
                                                        id="caseTransitionState"
                                                        defaultValue={stateData && isEdit && (stateData.caseTransitionState)}
                                                        onChange={this.props.handleChange}
                                                        placeholder={t("caseManagement.caseActions.fields.caseTransitionState.placeholder")}
                                                        disabled={true}
                                                    />
                                                    {errors.caseTransitionState && (
                                                        <span className="help-block">{errors.caseTransitionState}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row" id="commentSectionRow">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox" name="isCommentSection" id="isCommentSection"
                                                                onChange={this.props.handleChange}
                                                            />
                                                            {t("caseManagement.caseActions.fields.isCommentSection.name")}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6" id="isMandatoryCommentRow">
                                                <div className="form-group">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox" name="isMandatoryComment" id="isMandatoryComment"
                                                                onChange={this.props.handleChange}
                                                                disabled={true}
                                                            />
                                                            {t("caseManagement.caseActions.fields.isMandatoryComment.name")}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={
                                            errors.commentHintText ? "form-group has-error" : "form-group"
                                        } id="commentHintTextRow">
                                            <label htmlFor="commentHintText">
                                                {t("caseManagement.caseActions.fields.commentHintText.name")}
                                            </label>
                                            <input
                                                type="text"
                                                name="commentHintText"
                                                className="form-control"
                                                id="commentHintText"
                                                defaultValue={stateData && isEdit && (stateData.commentHintText)}
                                                onChange={this.props.handleChange}
                                                placeholder={t("caseManagement.caseActions.fields.commentHintText.placeholder")}
                                                disabled={true}
                                            />
                                            {errors.commentHintText && (
                                                <span className="help-block">{errors.commentHintText}</span>
                                            )}
                                        </div>

                                        <div className="row" id="caseHistoryRow">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox" name="isCaseHistoryEntry" id="isCaseHistoryEntry"
                                                                onChange={this.props.handleChange}
                                                            />
                                                            {t("caseManagement.caseActions.fields.isCaseHistoryEntry.name")}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6" id="isCaseHistoryLogPdfRow">
                                                <div className="form-group">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox" name="isCaseHistoryLogPdf" id="isCaseHistoryLogPdf"
                                                                onChange={this.props.handleChange}
                                                                disabled={true}
                                                            />
                                                            {t("caseManagement.caseActions.fields.isCaseHistoryLogPdf.name")}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={
                                            errors.caseHistoryEntryTitle ? "form-group has-error" : "form-group"
                                        } id="caseHistoryEntryTitleRow">
                                            <label htmlFor="caseHistoryEntryTitle">
                                                {t("caseManagement.caseActions.fields.caseHistoryEntryTitle.name")}
                                            </label>
                                            <input
                                                type="text"
                                                name="caseHistoryEntryTitle"
                                                className="form-control"
                                                id="caseHistoryEntryTitle"
                                                defaultValue={stateData && isEdit && (stateData.caseHistoryEntryTitle)}
                                                onChange={this.props.handleChange}
                                                placeholder={t("caseManagement.caseActions.fields.caseHistoryEntryTitle.placeholder")}
                                                disabled={true}
                                            />
                                            {errors.caseHistoryEntryTitle && (
                                                <span className="help-block">{errors.caseHistoryEntryTitle}</span>
                                            )}
                                        </div>

                                        <div className="row" style={{ marginBottom: 15 }}>
                                            <div className="col-md-6">
                                                <div className={
                                                    errors.type ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="">
                                                        {t("caseManagement.caseActions.tabMenu.workflowSelection")}
                                                    </label>
                                                    <select
                                                        className="form-control"
                                                        id="selectedWorkflowId"
                                                        name="selectedWorkflowId"
                                                        onChange={this.props.handleChange}
                                                    >
                                                        <option key="0" value="">-- Select --</option>
                                                        {workflowList &&
                                                            workflowList.map(workflow => {
                                                                if (workflow.name != "portalSettings.workflows.new_workflow.name") {
                                                                    return (
                                                                        <option key={workflow.workflowId} value={workflow.workflowId}>{workflow.name}</option>
                                                                    )
                                                                }
                                                            })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={
                                                    errors.actionConditionButton ? "form-group has-error" : "form-group"
                                                }>
                                                    <label htmlFor="">
                                                        {t("caseManagement.caseActions.tabMenu.casesSelection")}
                                                    </label>
                                                    <button type="button"
                                                        className="btn btn-primary"
                                                        id="actionConditionButton"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.props.openCustomizeDisplayConditionWindow();
                                                        }}
                                                    >{t("caseManagement.caseActions.tabMenu.displayConditionButton")}</button>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <span
                                                            className="input-group-btn"
                                                            style={{ paddingTop: 25, textAlign: "right" }}
                                                        >
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default pull-left"
                                data-dismiss="modal">{t("common.close")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={this.props.handleSave}
                            >{t("common.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CaseActionModal