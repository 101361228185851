import React, { Component } from "react";
import UserDetailsAPI from "../../api/UserDetailsAPI";
import { withTranslation } from "react-i18next";

class UserDetails extends Component {
  // TODO: create CaseDetailsDTO
  state = {
    userDetails: {},
    t: this.props.t
  };

  componentDidMount() {
    const uniqueId = this.props.userData.uniqueId;
    this.getUserDetails(uniqueId);
  }

  componentWillReceiveProps(nextProps) {
    const uniqueId = nextProps.userData.uniqueId;
    this.getUserDetails(uniqueId);
  }

  getUserDetails = uniqueId => {
    UserDetailsAPI.get(uniqueId)
      .then(data => {
        this.setState({ userDetails: data });
      })
      .catch(error => console.error(error));
  };

  generateTitle = (type, key, title) => {
    const {t} = this.state;
    var translation = t(type+"."+key);
    if(translation.includes(type)){
      return title;
    } else {
      return translation;
    }
  }

  render() {
    const { userDetails, t } = this.state;
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <tbody>
            <tr key="uniqueId">
              <th> {t("userManagement.viewDetails.uniqueId")}:</th>
              <td>{userDetails.uniqueId}</td>
            </tr>
            <tr key="firstName">
              <th>{t("userManagement.viewDetails.firstName")}:</th>
              <td>{userDetails.firstName}</td>
            </tr>
            <tr key="lastName">
              <th>{t("userManagement.viewDetails.lastName")}:</th>
              <td>{userDetails.lastName}</td>
            </tr>
            <tr key="email">
              <th>{t("userManagement.viewDetails.email")}:</th>
              <td>{userDetails.email}</td>
            </tr>
            {userDetails.workflowList && (
              <tr key="workflowList" style={{ marginBottom: 10 }}>
                <th>{t("userManagement.viewDetails.workflowDetails")}:</th>
                <td>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ width: 10 }}>#</th>
                        <th>{t("userManagement.viewDetails.workflow")}</th>
                        <th>{t("userManagement.viewDetails.roles")}</th>
                        <th>{t("userManagement.viewDetails.exerciseIds")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userDetails.workflowList.map((item, idx) => (
                        <tr key={item.workflow.name}>
                          <td>{idx + 1}.</td>
                          <td>{item.workflow.name}</td>
                          <td>{this.generateTitle("roles",item.role.name,item.role.name)}</td>
                          <td>
                            {item.exercises.map((item, idx) => {
                              return (
                                <li key={idx} style={{ listStyleType: "none" }}>
                                  {item.code}
                                </li>
                              );
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation("common")(UserDetails);
