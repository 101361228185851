import React from "react";

const Input = ({name, label, value, error, placeHolder, onChange}) => {
    return (
        <>
            <div className={
                error ? "form-group has-error" : "form-group"
            }>
                <label htmlFor={name}>{label}</label>
                <input
                    type="text"
                    name="title"
                    value={value}
                    onChange={onChange}
                    className="form-control"
                    id={name}
                    placeholder={placeHolder}
                />
                {
                    error && <span className={`help-block ${name}`}>{error}</span>
                }
            </div>
        </>
    )
};

export default Input;