import React, { Component, Fragment } from "react";
import CaseState from "../components/cases/CaseState";

export default class CasePage extends Component {
  render() {
    let state = this.props.location.state;
    return (
      <Fragment>
        <div className="content-wrapper case-table-view">
          <section className="content-header">
            <h1>
              {state.workFlowName} <small></small>
            </h1>
          </section>
          <CaseState params={state}></CaseState>
        </div>
      </Fragment>
    );
  }
}
