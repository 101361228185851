import _ from "lodash";


class StateCountUtil {
    static getStateCount = (stats, stateName, workflowId) => {
        let loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser"));
        if (stats) {
            stats = stats.filter(function (el) {
                return loggedInUser.workflowList.some(
                    item => item.workflow.workflowId.toString() === el.workflowId
                );
            });
        }

        if ("All_Applications" === stateName || "My_Cases" === stateName) {
            let sum = 0;
            let filteredStats;
            if (stats) {
                filteredStats = stats.filter(
                    i => i.workflowId === workflowId.toString()
                );
            }
            _.forEach(filteredStats, n => {
                sum += n["count"];
            });
            return sum;
        }

        let statObj = _.find(stats, {
            stateName: stateName,
            workflowId: workflowId.toString()
        });
        if (statObj) {
            return statObj["count"];
        }

        return 0;
    };
}


export default StateCountUtil;