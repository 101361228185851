import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PublicPage } from "./pages/PublicPage";
import ProtectedPage from "./pages/ProtectedPage";
import { PrivateRoute } from "./route/PrivateRoute";
import { PublicRoute } from "./route/PublicRoute";
import { AuthProvider } from "./auth/AuthCtxProvider";
import PageNotFound from "./route/ResourceNotFound";
import PortalUser from "./dto/PortalUser";
import CommonContextProvider from "./context/CommonContext";

export default class App extends Component {
  render() {
    let loggedInUser = sessionStorage.getItem("loggedInUser");
    if (loggedInUser) {
      loggedInUser = JSON.parse(loggedInUser);
    } else {
      loggedInUser = new PortalUser(sessionStorage.getItem("userId"));
    }
    return (
      <Fragment>
        <AuthProvider>
          <Router>
            <Switch>
              <PublicRoute exact path="/" component={PublicPage} />
              <CommonContextProvider>
                <PrivateRoute
                  path="/protected"
                  component={() => <ProtectedPage user={loggedInUser} />}
                />
              </CommonContextProvider>
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </AuthProvider>
      </Fragment>
    );
  }
}
