import React, { Component } from 'react'

export class CaseInfoConfigModal extends Component {
    render() {
        const { t, errors, workflowAttributes, attributesSorted, caseInfoRecords } = this.props.params;
        return (
            <div className="modal fade" id="modal-case-info">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{t("portalSettings.workflows.actions.caseInfoPanel.title")}</h4>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className={
                                    errors.caseInfoTitle ? "form-group has-error" : "form-group"
                                }>
                                    <label htmlFor="caseInfoTitle">
                                        {t("portalSettings.workflows.actions.columns.fields.title")}
                                    </label>
                                    <input
                                        type="text"
                                        name="caseInfoTitle"
                                        className="form-control"
                                        id="caseInfoTitle"
                                        placeholder={t("portalSettings.workflows.actions.columns.placeholder.title")}
                                    />
                                    {errors.caseInfoTitle && (
                                        <span className="help-block">{errors.caseInfoTitle}</span>
                                    )}
                                </div>

                                <div className={
                                    errors.caseInfoField ? "form-group has-error" : "form-group"
                                }>
                                    <label htmlFor="caseInfoField">
                                        {t("portalSettings.workflows.actions.columns.fields.field")}
                                    </label>
                                    <select
                                        type="text"
                                        name="caseInfoField"
                                        className="form-control"
                                        id="caseInfoField"
                                    >
                                        {workflowAttributes && attributesSorted &&
                                            attributesSorted.map(option => {
                                                return (
                                                    <option key={"CIP" + option.id} value={option.attribute}>
                                                        {option.attributeName}
                                                    </option>
                                                )
                                            })
                                        }
                                        <option key={"CIPO01"} value="worker">Assign Worker (default)</option>
                                        <option key={"CIPO02"} value="attachments">Attachments (default)</option>
                                    </select>
                                    {errors.caseInfoField && (
                                        <span className="help-block">{errors.caseInfoField}</span>
                                    )}
                                </div>

                                <div className={"form-group"} style={{ marginTop: 15 }}>
                                    <button
                                        type="button"
                                        onClick={this.props.openCustomizeCaseAccessWindow}
                                        className="btn btn-primary"
                                    >
                                        {t("portalSettings.workflows.actions.caseInfoPanel.caseAccessLevels.windowTitle")}
                                    </button>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <span
                                            className="input-group-btn"
                                            style={{ paddingTop: 25, textAlign: "right" }}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-md btn-default"
                                                id="caseInfoSave"
                                                onClick={this.props.addCaseInfoRecord}
                                            >
                                                {t("common.add")}
                                            </button>

                                            <button
                                                type="reset"
                                                className="btn btn-md btn-default"
                                                style={{ marginLeft: 10 }}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.resetCaseInfoForm();
                                                }}

                                            >
                                                {t("common.clear")}
                                            </button>
                                        </span>
                                    </div>
                                </div>

                                {caseInfoRecords.length > 0 && (
                                    <div className="form-group" style={{ marginTop: 30 }}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>{t("portalSettings.workflows.actions.columns.fields.title")}</th>
                                                    <th>{t("portalSettings.workflows.actions.columns.fields.field")}</th>
                                                    <th style={{ width: 150 }}>
                                                        {t("portalSettings.workflows.actions.columns.fields.action")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {caseInfoRecords.length > 0 && caseInfoRecords.sort((a, b) => a.order > b.order ? 1 : -1).map((record, idx) => (
                                                    <tr key={"ci" + idx}>
                                                        <td>{record.title}</td>
                                                        <td>{this.props.generateAttributeName(record.field)}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                title="Move Up"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.moveUpCaseInfoRecord("caseInfo", record);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-arrow-up"></i></span>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                title="Move Down"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.moveDownCaseInfoRecord("caseInfo", record);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-arrow-down"></i></span>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.editCaseInfoRecord(idx, record);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-edit"></i></span>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.deleteCaseInfoRecord(idx);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-close"></i></span>
                                                            </button>

                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                data-dismiss="modal"
                                style={{ marginLeft: 10 }}
                                onClick={this.props.handleCaseInfoSave}
                            >
                                {t("common.save")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-right"
                                data-dismiss="modal"
                            >
                                {t("common.cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CaseInfoConfigModal