import React, { Component } from 'react'

export class ActionDetails extends Component {
    render() {
        const {t, stateData } = this.props.params
        return (
            <div className="col-xs-8">
                {stateData && (
                    <div className="box">
                        <div className="box-header">
                            <p className="lead">
                                {t("caseManagement.caseActions.infoWindow.title")}
                            </p>
                        </div>
                        <div className="box-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr key="name">
                                            <th> {t("caseManagement.caseActions.fields.title.name")}</th>
                                            <td>{stateData.title}</td>
                                        </tr>
                                        <tr key="title">
                                            <th> {t("caseManagement.caseActions.fields.description.name")} </th>
                                            <td>{stateData.description}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default ActionDetails