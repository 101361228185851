import ApiService from "../auth/ApiService";
import { handleResponse, handleError } from "../services/serviceUtil";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/case-filters";

const CaseFilterAPI = {

    save: async function(filters) {
        let data = await new ApiService()
            .callApi(`${resourceUrl}`, "post", filters);
        return data.data;
    },

    delete: function(ids) {
        return new ApiService()
            .callApi(`${resourceUrl}`, "delete", ids)
            .then(handleResponse)
            .catch(handleError);
    },

    update: function(filters) {
        return new ApiService()
            .callApi(`${resourceUrl}`, "patch", filters)
            .then(handleResponse)
            .catch(handleError);
    },

    getCaseFilters: async function(workflowId, caseStateId) {
        return new ApiService()
            .callApi(`${resourceUrl+"/"+workflowId+"/"+caseStateId}`, "get")
            .then(handleResponse)
            .catch(handleError);
    },

    getCaseFiltersByWorkflowId: async function(workflowId) {
        return new ApiService()
            .callApi(`${resourceUrl+"/"+workflowId}`, "get")
            .then(handleResponse)
            .catch(handleError);
    },

};

export default CaseFilterAPI;