import React, { Component } from 'react'

export class StateDetails extends Component {
    render() {
        const { t, stateData } = this.props.params
        return (
            <div className="col-xs-8">
                {stateData && (
                    <div className="box">
                        <div className="box-header">
                            <p className="lead">
                                {t("portalSettings.caseStates.details.title")}
                            </p>
                        </div>
                        <div className="box-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr key="name">
                                            <th> {t("portalSettings.caseStates.details.table.name")}</th>
                                            <td>{stateData.name}</td>
                                        </tr>
                                        <tr key="workflowStateName">
                                            <th> {t("portalSettings.caseStates.details.table.workflowStateName")}</th>
                                            <td>{stateData.workflowStateName}</td>
                                        </tr>
                                        <tr key="description">
                                            <th> {t("portalSettings.caseStates.details.table.description")}</th>
                                            <td>{stateData.description}</td>
                                        </tr>
                                        <tr key="buttonStyle">
                                            <th> {t("portalSettings.caseStates.details.table.buttonStyle")}</th>
                                            <td>{stateData.buttonStyle}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default StateDetails