import React, {Component} from "react";
import MultiSelect from "../common/MultiSelect";
import CaseStateFilterAPI from "../../api/CaseStateFilterAPI";
import {generateFilterKey} from "../../utils/GenerateKey";
import feather from 'feather-icons';

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: '40px'
    }),
    multiValue: () => ({
        display: 'none'
    }),
    valueContainer: (provided) => ({
        ...provided,
        overflow: 'visible'
    }),
};

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEnableFilterMenu: false,
            filters: [],
            selectedOptions: [],
            hasActiveFilters: false
        }
    }

    componentDidMount() {
       this.fetchingFilters();
       feather.replace();
    }

    componentDidUpdate(prevProps) {
        const { caseStateId, workflowIndex } = this.props.caseData;

        if (prevProps.caseData.caseStateId !== caseStateId ||
            prevProps.caseData.workflowIndex !== workflowIndex) {

            this.setState({isEnableFilterMenu: false});
            this.fetchingFilters();
        }
    }

    fetchingFilters = () => {
        const {workflowIndex, caseStateId} = this.props.caseData;
        const savedFiltersKey = `selectedFilters_${workflowIndex}_${caseStateId}`;
        const savedSelectedOptions = localStorage.getItem(savedFiltersKey);

        CaseStateFilterAPI.getFilterSettings(workflowIndex, caseStateId).then(data => {
            const filtersFromServer = this.transformData(data);
            let filtersWithSelectedValues = filtersFromServer;
            let hasActiveFilters = false;

            if (savedSelectedOptions) {
                const selectedValues = JSON.parse(savedSelectedOptions);

                filtersWithSelectedValues = filtersFromServer.map(filter => {
                    const savedFilter = selectedValues.find(f => f.id === filter.id);
                    if (savedFilter && savedFilter.selectedOptions) {
                        // Filter out options that no longer exist in the server response
                        const validSelectedOptions = savedFilter.selectedOptions.filter(option =>
                            filter.values.some(value => value.value === option.value)
                        );
                        if (validSelectedOptions.length > 0) {
                            hasActiveFilters = true; // Set flag if any filter has valid selected options
                            return { ...filter, selectedOptions: validSelectedOptions};
                        }
                    }
                    return filter;
                });
            }

            this.setState({ filters: filtersWithSelectedValues, hasActiveFilters });
        }).catch(error => {
            console.error(error);
        });
    }

    handleFilterMenu = () => {
        this.setState(prevState => ({
            isEnableFilterMenu: !prevState.isEnableFilterMenu
        }));
    }

    transformData = originalData => {
        return originalData.map(item => ({
            id: item["caseFilter"].id,
            stateFilterId: item["caseStateFilter"].id,
            title: item["caseFilter"].title,
            field: item["caseFilter"].field,
            workflowId: item["caseFilter"].workflowId,
            caseStateId: item["caseStateFilter"].caseStateId,
            values: item["caseFilter"].values.map(value => ({
                id: value.id,
                name: value.value,
                value: value.value
            })),
            isSelected: item["caseFilter"].isSelected
        }));
    }

    handleMultiselectChange = (selectedOption, filter) => {
        const {workflowIndex, caseStateId} = this.props.caseData;

        this.setState(prevState => {
            const filters = [...prevState.filters];
            const filterIndex = filters.findIndex(f => f.id === filter.id);


            if (filterIndex !== -1) {
                const updateFilter = {
                    ...filters[filterIndex],
                    selectedOptions: selectedOption
                };

                filters[filterIndex] = updateFilter;
            }

            // Check if any filter has selected options
            const hasActiveFilters = filters
                .some(filter => filter.selectedOptions && filter.selectedOptions.length > 0);

            const savedFiltersKey = generateFilterKey(workflowIndex, caseStateId);
            const selectedFilters = filters
                .map(f => ({
                id: f.id,
                stateFilterId: f.stateFilterId,
                field: f.field,
                selectedOptions: f.selectedOptions
            }))
                .filter(f => f.selectedOptions && f.selectedOptions.length > 0);

            (selectedFilters.length > 0) ? localStorage.setItem(savedFiltersKey, JSON.stringify(selectedFilters)) :
                localStorage.removeItem(savedFiltersKey);

            this.props.onHandleFilterData(workflowIndex, caseStateId);
            return {filters, hasActiveFilters};
        });
    };

    render() {
        const {isEnableFilterMenu, filters, hasActiveFilters} = this.state;
        const {caseData: {allCaseBtn, myCaseBtn, t}, onHandleCaseBtn} = this.props;

        const buttonClassName = `toolbar-btn filter-btn ${hasActiveFilters ? 'filter-active' : ''}`;

        const placeholder = t("caseManagement.caseTable.filterButton.dropdown.placeholder");
        const sortedFilters = filters.slice().map(filter => {
            return {
              ...filter,
              values: filter.values.slice().sort((a, b) => a.name.localeCompare(b.name))
            };
          }).sort((a, b) => a.title.localeCompare(b.title));

        const noOptionsMessage = t("caseManagement.caseTable.filterButton.dropdown.noOptionsText");

        return (
            <>
                {filters.length !== 0 && <div className="filter-dropdown">
                    <button className={buttonClassName} id="filterMenuBtn"
                            onClick={this.handleFilterMenu}>
                        {t("caseManagement.caseTable.filterButton.label")} 
                        <i data-feather='filter' />
                    </button>
                    {
                        isEnableFilterMenu &&
                        <div className="filter-dropdown-content">
                            <p className="filter-dropdown-title">
                                {t("caseManagement.caseTable.filterButton.menu.header")}
                            </p>
                            <i
                                className="fa fa-times filter-dropdown-close"
                                onClick={this.handleFilterMenu}
                                aria-hidden="true"></i>
                            <div>
                                <hr className="solid"/>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="btn-group"
                                         role="group"
                                         aria-label="Basic example">
                                        <button type="button"
                                                className={allCaseBtn === true ? 'btn btn-primary' : 'btn btn-default'}
                                                onClick={(e) =>
                                                    onHandleCaseBtn('allCaseBtn')}>
                                            {t("caseManagement.caseTable.allCasesButton.value")}
                                        </button>
                                        <button
                                            type="button"
                                            className={myCaseBtn === true ? 'btn btn-primary' : 'btn btn-default'}
                                            onClick={(e) =>
                                                onHandleCaseBtn('myCaseBtn')}>
                                            {t("caseManagement.caseTable.myCasesButton.value")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            { sortedFilters.map(filter => (
                                <div className="row" key={filter.id}>
                                    <div className="col">
                                        <p className="filter-multiselect-title">{filter.title}</p>
                                        <MultiSelect
                                            classNamePrefix="filter-select"
                                            selectedOptions={filter.selectedOptions}
                                            customStyles={customStyles}
                                            options={filter.values}
                                            placeholder={placeholder}
                                            noOptionsMessage={noOptionsMessage}
                                            handleChange={(evt) => this.handleMultiselectChange(evt, filter)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                }
            </>
        );
    }
}

export default Filter;