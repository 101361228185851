import { handleResponse, handleError } from "../services/serviceUtil";
import ApiService from "../auth/ApiService";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/exercise";

const ExerciseAPI = {
  exerciseData: {
    columns: [
      { title: "Id", field: "id", visible:false},
      { title: "Code", field: "code" },
      { title: "Description", field: "description" }
    ],
    data: []
  },

  getExercises: async function() {
    return await new ApiService()
      .callApi(resourceUrl, "get")
      .then(handleResponse)
      .catch(handleError);
  },

  all: async function() {
    let data = await new ApiService().callApi(`${resourceUrl}`, "get");
    this.exerciseData.data = data.data;
    return this.exerciseData;
  },
};

export default ExerciseAPI;
