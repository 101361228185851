import { handleResponse, handleError } from "../services/serviceUtil";
import ApiService from "../auth/ApiService";

const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/user-group";

const UserGroupAPI = {
  userGroupData: {
    data: []
  },

  all: async function(t) {
    let data = await new ApiService().callApi(`${resourceUrl}`, "get");
    this.userGroupData.data = data.data;
    return this.userGroupData;
  },
  save: async function(userGroup) {
    let data = await new ApiService().callApi(`${resourceUrl}`, "post", userGroup);
    return data.data;
  },
  getUserGroup: async function(userGroupId) {
    return new ApiService()
      .callApi(`${resourceUrl}/${userGroupId}`, "get")
      .then(handleResponse)
      .catch(handleError);
  },
  updateUserGroup: function(userGroup) {
    return new ApiService()
      .callApi(`${resourceUrl}`, "put", userGroup)
      .then(handleResponse)
      .catch(handleError);
  },
  deleteUserGroup: function(userGroupId) {
    return new ApiService()
      .callApi(`${resourceUrl}/${userGroupId}`, "delete")
      .then(handleResponse)
      .catch(handleError);
  }
};

export default UserGroupAPI;
