import React, { Component } from "react";

class LoginPageHeaderSection extends Component {
  render() {
    return (
      <header className="main-header login-main-header">
        <a href="/" className="logo login-logo">
          <img
            alt="compose CCM logo"
            src="img/CCM logo.svg"
            className="login-logo-img"
          ></img>
        </a>
        {/* Header Navbar */}
        <nav
          className="navbar navbar-static-top login-navbar-static-top"
          role="navigation"
          style={{ backgroundColor: "transparent" }}
        >
          {/* Navbar Right Menu */}
          <div className="navbar-custom-menu">

          </div>
        </nav>
      </header>
    );
  }
}

export default LoginPageHeaderSection;
