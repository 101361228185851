import ApiService from "../auth/ApiService";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured";

const CaseDetailsAPI = {
  get: async function(uniqueCaseId) {
    let response = await new ApiService().callApi(
      `${resourceUrl}/casedetail/${uniqueCaseId}`,
      "get"
    );
    return response.data;
  }
};

export default CaseDetailsAPI;
