import React, { Component } from 'react'
import _ from "lodash";
const $ = window.$;

export class CaseRulesConfigModal extends Component {

    openConfirmationModal = () => {
        $(function () {
            $('#caseDeletionRuleAlert').modal('show');
        });
    }

    closeConfirmationModal = () => {
        $(function () {
            $('#caseDeletionRuleAlert').modal('hide');
        });
    }

    validationSaveExpression = () => {
        const {  caseDeletionRules,workflowAttributes } = this.props.params;
        let errorMsg="";
        let isValidate=true;
        caseDeletionRules.forEach(item => {
            let value=item.value;
            const regexInteger = /^[+-]?\d+$/;
            const regexDecimal = /^[+-]?\d+(\.\d+)?$/;
            
            let savedAttribute = item.metaData;
            let workflowAttribute = _.find(workflowAttributes, { attribute: savedAttribute });
            if (workflowAttribute !== undefined && workflowAttribute !== null && workflowAttribute !== "") {
                item.type = workflowAttribute.type;
                if(value !=null){                   
                    var valueType="";                    
                    if (regexInteger.test(value)) {//metha 
                        valueType = 'integer'; 
                      } else if (regexDecimal.test(value)) {
                        valueType = 'decimal';  
                      } 
                    if(item.type.toLowerCase()=='integer' ||item.type.toLowerCase()=='decimal'){
                        if(valueType!==item.type.toLowerCase()){
                            isValidate=false; 
                            errorMsg=item.type.toLowerCase();
                        }                         
                    }       
                }   
            }  
        })
        if(isValidate){
            this.openConfirmationModal();
        }else{
            const { t } = this.props.params;           
            $.notification("error", t("common.notification.error"), t("common.validations."+errorMsg));
        }
    }

    render() {
        const { t, workflowAttributes, attributesSorted, isEdit, workflowData, caseDeletionRules, enableDeletionRules, showRulesModel } = this.props.params;
        const conditionOptions=[
            {"key":"eq", "title": "equals"},
            {"key":"contains", "title": "contains"},
            {"key":"greaterthan", "title": "greater than"},
            {"key":"lessthan", "title": "less than"}
        ]; 
        return (
            <>
            {showRulesModel &&
            <div className="modal fade" id="modal-case-rules" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{t("portalSettings.workflows.actions.rules.title")}</h4>
                        </div>
                        <div className="modal-body">
                            <p className="info-text">{t("portalSettings.workflows.actions.rules.message")}</p>
                            <form id="modal-rules-form">
                                <div className="checkbox">
                                    <label htmlFor="enableDeletionRules">
                                        <input
                                            type="checkbox"
                                            name="enableDeletionRules"
                                            id="enableDeletionRules"
                                            defaultChecked={enableDeletionRules === "Y" ? true : false}
                                            onClick={this.props.handleChange}
                                        />
                                        {t("portalSettings.workflows.actions.rules.fields.enable")}
                                    </label>
                                </div>
                                {enableDeletionRules === "Y" &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="actionTitle">
                                                    {t("portalSettings.workflows.actions.rules.fields.actionTitle")}
                                                </label>
                                                <input
                                                    type="text"
                                                    name="actionTitle"
                                                    className="form-control"
                                                    id="actionTitle"
                                                    defaultValue={workflowData && isEdit && (workflowData.actionTitle)}
                                                    onChange={this.props.handleChange}
                                                    placeholder={t(
                                                        "portalSettings.workflows.actions.rules.placeholder.actionTitle"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="input-group">
                                            <div className="col-md-12 section-header">
                                                {t("portalSettings.workflows.actions.rules.button")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1">
                                            &nbsp;
                                        </div>
                                        <div className="col-md-4">
                                            {t("portalSettings.workflows.actions.rules.fields.metadata")}
                                        </div>
                                        <div className="col-md-2">
                                            {t("portalSettings.workflows.actions.rules.fields.method")}
                                        </div>
                                        <div className="col-md-2">
                                            {t("portalSettings.workflows.actions.rules.fields.value")}
                                        </div>
                                        <div className="col-md-1">
                                            {t("portalSettings.workflows.actions.rules.fields.when")}
                                        </div>
                                        <div className="col-md-1">
                                            &nbsp;
                                        </div>
                                        <div className="col-md-1">
                                            &nbsp;
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-1">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.addCaseDeleteRulesRows();
                                                }}
                                            >
                                                <span><i className="fa fa-plus"></i></span>
                                            </button>
                                        </div>
                                        <div className="col-md-11">
                                            {caseDeletionRules.map((data, index) => {
                                                const {metaData, method, value, days}= data;
                                                return(
                                                    <div className="row" key={index}>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <select className="form-control" value={metaData} onChange={(e)=>(this.props.handleCaseDeleteRuleChange(index, e))} name="metaData">
                                                                    <option value="" key='0'>{t("portalSettings.workflows.actions.rules.fields.option")}</option>
                                                                    {attributesSorted &&
                                                                        attributesSorted.map(option => {
                                                                            return (
                                                                                <option key={option.id} value={option.attribute}>
                                                                                    {option.attributeName}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group" style={{marginLeft:-25+'px', width: 170+'%'}}>
                                                                <select className="form-control"  defaultValue={method} onChange={(evnt)=>(this.props.handleCaseDeleteRuleChange(index, evnt))} name="method">
                                                                    {conditionOptions.map(option => {
                                                                        return (
                                                                            <option key={option.key} value={option.key}>
                                                                                {option.title}
                                                                            </option>
                                                                        )
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group" style={{width: 180+'%'}}>
                                                                <input type="text" className="form-control" value={value} onChange={(evnt)=>(this.props.handleCaseDeleteRuleChange(index, evnt))} name="value"></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <div className="form-group" style={{marginLeft:32+'px', width: 275+'%'}}>
                                                                <input type="text" className="form-control" value={days} onChange={(evnt)=>(this.props.handleCaseDeleteRuleChange(index, evnt))} name="days"></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1" style={{marginLeft:40+'px', paddingTop: 15+'px'}}>
                                                            {t("portalSettings.workflows.actions.rules.fields.days")}
                                                        </div>
                                                        <div className="col-md-1" style={{marginLeft:-7+'px'}}>
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.deleteCaseDeleteRulesRows(index);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-close"></i></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                            )})}
                                        </div>
                                    </div>
                                </div>
                                }
                            </form>
                        </div>

                        <div className="modal fade" id="caseDeletionRuleAlert">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button
                                            type="button"
                                            className="close"
                                            aria-label="Close"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.closeConfirmationModal();
                                            }}
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <h4 className="modal-title">{t("portalSettings.workflows.actions.rules.dialog.title")}</h4>
                                    </div>
                                    <div className="modal-body">
                                        <p>{t("portalSettings.workflows.actions.rules.dialog.message")}</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-primary col-md-2 pull-right"
                                            data-dismiss="modal"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.props.handleCaseDeleteRuleSave();
                                            }}
                                            style={{ marginLeft: 10 }}
                                        >
                                            {t("portalSettings.workflows.actions.rules.dialog.button.confirm")}
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-default col-md-2 pull-right"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.closeConfirmationModal();
                                            }}
                                        >
                                            {t("common.cancel")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                style={{ marginLeft: 10 }}
                                onClick={e => {
                                    e.preventDefault();
                                    this.validationSaveExpression();
                                }}
                            >
                                {t("common.save")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-right"
                                data-dismiss="modal"
                            >
                                {t("common.close")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }
            </>
        )
    }
}

export default CaseRulesConfigModal