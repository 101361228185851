import React, { Component, Fragment } from 'react'

export default class NoUsers extends Component {
    render() {
        return (
            <Fragment>
                No Users
            </Fragment>
        )
    }
}