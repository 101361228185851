import React, { Component, Fragment } from "react";
import MainMenu from "../components/shared/MainMenu";

export default class SidebarSection extends Component {

  render() {
    return (
      <Fragment>
        <aside className="main-sidebar">
          <section className="sidebar">
            <MainMenu user={this.props.user}/>
          </section>
        </aside>
      </Fragment>
    );
  }
}
