import AuthService from "../auth/AuthService";
import UserAPI from "../api/UserAPI";

class AuthUtil {
  static initAuth() {
    if (!this.auth) {
      this.auth = new AuthService();
    }
  }

  static getService() {
    this.initAuth();
    return this.auth;
  }

  static setLoggedInUser(user) {
    this.loggedInUser = user;
  }

  static login() {
    this.initAuth();
    this.auth.login();
  }

  static loginWithEmail(loginEmail, setStateFunction, errors, t) {
    try {
      sessionStorage.removeItem("auth_configs");
      UserAPI.getAuthConfig(loginEmail).then(authConfig => {
        if(authConfig){

          const newEnv = {
            REACT_APP_IDP_AUTH_URL: authConfig.AUTH_URL,
            REACT_APP_CLIENT_ID: authConfig.CLIENT_ID,
            REACT_APP_RESOURCE_ID: authConfig.CLIENT_ID,
          };

          Object.assign(window.ENV, newEnv);

          const newConfig = {
            authority: window.ENV.REACT_APP_IDP_AUTH_URL,
            client_id: window.ENV.REACT_APP_CLIENT_ID,
            client_secret: window.ENV.REACT_APP_CLIENT_SECRET,
            extraQueryParams: {
              resource: window.ENV.REACT_APP_RESOURCE_ID,
              login_hint: loginEmail
            }
          };

          sessionStorage.setItem("auth_configs", JSON.stringify(newConfig));

          this.auth = new AuthService();
          this.auth.login();
        } else {
          errors.loginEmail = t("loginPage.validationError.USER_NOT_FOUND");
          setStateFunction({ errors: errors });
        }
      });
    } catch (error) {
      errors.loginEmail = t("loginPage.validationError.USER_NOT_FOUND");
      setStateFunction({ errors: errors });
    }
  }

  static getUserId = () => {
    this.initAuth();
    return this.auth.loadUser().then(user => {
      if (user) {
        return user.profile[window.ENV.REACT_APP_UNIQUE_ID_ALIAS];
      }
      return null;
    });
  };

  static getUsername = user => {
    return `${user.firstName} ${user.lastName}`;
  };

  static hasRole = (user, roles) => {
    return roles.some(role =>
      user.roleList.some(userRole => userRole.name === role)
    );
  };

  static hasRoles = (user, roles, workflowId) => {
    return roles.some(role => {
      var workflow = user.workflowList.filter(workflow => workflow.workflow.workflowId==workflowId);
      return workflow.some(flow => flow.role.name===role);
    });
  };

  static hasSectionAccess = (user, sections) => {
    return sections.some(section =>
        user.roleList.some(userRole =>
            userRole.adminSections.some(ui => ui===section)
        )
    );
  };

  static async _getRemoteUser(user) {
    if (user) {
      if (!this.loggedInUser) {
        try {
          // await UserAPI.isFirstUser(
          //   user.profile[process.env.REACT_APP_UNIQUE_ID_ALIAS]
          // );

          let userDb = await UserAPI.getProfile(
            user.profile[window.ENV.REACT_APP_UNIQUE_ID_ALIAS]
          );

          if (!userDb) {
            this.auth.removeUser();
            return this.loggedInUser;
          }
          this.setLoggedInUser(userDb);
        } catch (error) {
          this.auth.removeUser();
          console.error(error);
        }
      }
      return this.loggedInUser;
    } else {
      this.setLoggedInUser(null);
      return null;
    }
  }

  static getLoggedInUser() {
    this.initAuth();
    let cachedUser = this.auth.getUser();
    if (cachedUser) {
      return this._getRemoteUser(cachedUser);
    }

    return this.auth.loadUser().then(user => this._getRemoteUser(user));
  }
}

export default AuthUtil;
