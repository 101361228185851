class Workflow {
    constructor(
        id= "",
        workflowId = "",
        name = "",
        description = "",
        icon = "",
        securePublishing = "N",
        enableDeletionRules = "N",
        actionTitle = "",
        states = [],
        exercises = [],
        columns = [],
        caseInfoConfig = [],
        caseDeletionRules = [],
        segmentationWorkflow = "N",
        caseHighlightRules = []
    ) {
        this.id = id;
        this.workflowId = workflowId;
        this.name = name;
        this.description = description;
        this.icon = icon;
        this.securePublishing = securePublishing;
        this.enableDeletionRules = enableDeletionRules;
        this.actionTitle = actionTitle;
        this.states = states;
        this.exercises = exercises;
        this.columns = columns;
        this.caseInfoConfig = caseInfoConfig;
        this.caseDeletionRules = caseDeletionRules;
        this.segmentationWorkflow = segmentationWorkflow;
        this.caseHighlightRules = caseHighlightRules;
    }
}

export default Workflow;