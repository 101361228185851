import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import Moment from 'moment';
import _ from "lodash";
import ReactHtmlParser from 'react-html-parser';

const $ = window.$;

class CaseDetails extends Component {
  state = {
    caseDetail: this.props.caseData,
    userData: this.props.userData,
    config: this.props.config,
    t: this.props.t,
    infoAlertShowHide: true,
    workerMessageShowHide: true
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ caseDetail: nextProps.caseData,  config: nextProps.config, infoAlertShowHide: true, workerMessageShowHide: true});
  }

  sortAdditionalDetails(caseDetail){
    var existingList = Object.create(caseDetail.caseAdditionalInfo);
    var sorted = {};

    if(existingList.areas){
      sorted.areas = existingList.areas;
    }
    if(existingList.organizationNo){
      sorted.organizationNo = existingList.organizationNo;
    }
    if(existingList.applicantMobile){
      sorted.applicantMobile = existingList.applicantMobile;
    }
    if(existingList.applicantEmail){
      sorted.applicantEmail = existingList.applicantEmail;
    }

    _.forEach(caseDetail.caseAdditionalInfo,function(value,key) {
      if(!existingList.hasOwnProperty(key)){
        sorted[key] = value;
      }
    });
    caseDetail.caseAdditionalInfo = sorted;
    return caseDetail;
  }

  getValue = (caseDetails, field) => {
    if(field==undefined || field==null) return;

    if(field.includes("metadata.")){
      field = field.replace("metadata.","");
      field = field.replace(".value","");
      let caseAdditionalInfo=caseDetails.caseAdditionalInfo[field];
      if(caseAdditionalInfo === "null"){
        caseAdditionalInfo="";
      }
      return caseAdditionalInfo;
    } else if(field.includes("auditInfo.")){
      field = field.replace("auditInfo.","");
      return Moment(caseDetails.auditInfo[field]).format('DD-MM-YYYY HH:mm:ss');
    } else {
      return caseDetails[field];
    }
  }

  handleAttachmentClick = (value) => {
    if (this.isAccessibleAttachment()) {
      this.props.attachmentClick(value.filePath, value.fileName);
    }
  }

  isAccessibleAttachment = () => {
    const {userData, caseDetail} = this.state;
    const lockedUser = caseDetail.lockedBy;
    const accessUserList = caseDetail.lockAccessUserList;

    if (lockedUser) {
      return (userData.isPortalAdmin || (String(userData.user.id) === lockedUser) ||
          accessUserList.some(user => user.uniqueId === String(userData.user.id)));
    }
     return true;
  };

  getRow = (caseDetail, record, t) => {
    if(record.field=="attachments" && caseDetail.attachments) {
      return (
          <tr key={"attachments"+record.id}>
            <th>{t("caseManagement.viewDetails.attachments")}</th>
            <td>
              <ul className="products-list product-list-in-box">
                {caseDetail.attachments.map((value, index) => {
                  return (
                      <li key={index} className="item" style={{background: "none"}}>
                        <a  className={`${ this.isAccessibleAttachment() ?  "" : "info-attachment-disable"}`}
                            rel="noreferrer noopener"
                              onClick={() => this.handleAttachmentClick(value)}>
                                  <span>
                                    <i className="fa fa-paperclip" />{" "}
                                    {value.fileName}
                                  </span>
                        </a>
                      </li>
                  );
                })}
              </ul>
            </td>
          </tr>
      )

    } else {
      return(
          <tr key={record.field+record.id}>
            <th>{record.title}</th>
            <td>{ReactHtmlParser(this.getValue(caseDetail, record.field))}</td>
          </tr>
      )
    }

  }

  hideInfoAlert = () => {
    this.setState({
      infoAlertShowHide: false
    });
  }

  hideWorkerMessageAlert = () => {
    this.setState({
      workerMessageShowHide: false
    });
  }

  render() {
    const { caseDetail, t, config, infoAlertShowHide , workerMessageShowHide} = this.state;
    return (
      <div className="box">
        <div className="box-header">
          <p className="lead" style={{marginBottom:0}}>{t("caseManagement.viewDetails.title")}</p>
        </div>
        {
          caseDetail.lockedUserComment && infoAlertShowHide && (
              <div className="row info-text">
                <div className="col-md-1">
                  <span>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="col-md-10">
                  <span> {caseDetail.lockedUserComment} </span>
                </div>
                <div className="col-md-1">
                  <span>
                    <i className="fa fa-times-circle-o"
                       aria-hidden="true" onClick={this.hideInfoAlert}></i>
                  </span>
                </div>
              </div>
            )
        }

        {
          caseDetail.workerMessage && workerMessageShowHide && (
              <div className="row info-text">
                <div className="col-md-1">
                  <span>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="col-md-10">
                  <span> {caseDetail.workerMessage} </span>
                </div>
                <div className="col-md-1">
                  <span>
                    <i className="fa fa-times-circle-o"
                       aria-hidden="true" onClick={this.hideWorkerMessageAlert}></i>
                  </span>
                </div>
              </div>
            )
        }

        <div className="box-body">
          <div className="col-xs-12 table-responsive">
            <table className="table table-striped">
              <tbody>
	              {config && config.sort((a, b) => a.order > b.order ? 1 : -1).map((record, idx) => (
	                    this.getRow(caseDetail, record, t)
	              ))}
             
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(CaseDetails);
