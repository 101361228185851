import React from 'react';
import Select from "react-select";
import PropTypes from "prop-types";

const MultiSelect = ({
                         classNamePrefix,
                         selectedOptions = [],
                         options,
                         customStyles,
                         placeholder,
                         handleChange,
                         noOptionsMessage}) => {

    const customNoOptionsMessage = () => {
        return noOptionsMessage ? noOptionsMessage : 'No options';
    };

    const renderSelectedItems = () => {
        return (
            <div className="selected-items-container">
                {selectedOptions && selectedOptions.map((item) => (
                    <div className="selected-item" key={item.id}>
                        {item.name}
                        <i
                            className="fa fa-times filter-dropdown-close remove-item"
                            onClick={() => handleChange(
                                selectedOptions.filter(option => option.id !== item.id)
                            )}
                            aria-hidden="true"></i>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            <Select
                classNamePrefix={classNamePrefix}
                isMulti
                value={selectedOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.value}
                onChange={handleChange}
                options={options}
                placeholder={placeholder}
                noOptionsMessage={customNoOptionsMessage}
                menuPlacement="bottom"
                menuPortalTarget={document.body}  // Render the menu outside of the .filter-dropdown-content
                menuPosition="fixed"  // Keep the menu in a fixed position
                styles={{
                    ...customStyles,
                    menuPortal: base => ({ ...base, zIndex: 9999 })  // Ensure the menu appears above other content
                }}
            />
            {renderSelectedItems()}
        </>
    )
};

MultiSelect.propTypes = {
    classNamePrefix: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    customStyles: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
}

export default MultiSelect;