import React, {Component} from "react";
import AuthUtil from "../utils/AuthUtil";

const AuthContext = React.createContext({
    login : () => ({}),
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({}),
    loadLoggedInUserToStore: () => ({})
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
    authService;
    constructor(props) {
        super(props);
        this.authService = AuthUtil.getService();
    }

    componentWillUnmount() {
        this.authService.unregisterEvents();
    }

    render() {
        return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
    }
}