import React, { Component } from 'react'

export class CaseStateConfigModal extends Component {
    render() {
        const { t, caseStates, initialColumns } = this.props.params;
        return (
            <div className="modal fade" id="modal-state">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{t("portalSettings.workflows.actions.states.title")}</h4>
                        </div>
                        <div className="modal-body">
                            <p className="info-text"> {t("portalSettings.caseStates.infoText.message")}</p>
                            <form id="modal-state-form">
                                <div className="form-group">
                                    <label htmlFor="description">
                                        {t("portalSettings.workflows.actions.states.fields.state")}
                                    </label>
                                    <select
                                        className="form-control"
                                        id="caseStateColumn"
                                        name="caseStateColumn"
                                        onChange={this.props.handleCaseStateColumnChange}>
                                        <option key="0" value="none">{t("portalSettings.workflows.actions.states.fields.option")}</option>
                                        {caseStates &&
                                            caseStates.map(option => {
                                                return (
                                                    <option key={option.id} value={option.id}>
                                                        {this.props.generateTitle("caseManagement.viewDetails.stateType", option.name, option.name)}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div className="checkbox">
                                                <label>
                                                    <input type="checkbox" name="select-all" onChange={this.props.handleSelectAll} />
                                                    Select All
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div className="checkbox">
                                                <label>
                                                    <input type="checkbox" name="deselect-all" onChange={this.props.handleDeselectAll} />
                                                    Deselect All
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {initialColumns.length > 0 && initialColumns.map((column, idx) => (
                                        <div className="col-md-4" key={idx}>
                                            <div className="form-group">
                                                <div >
                                                    <label>
                                                        <input className="checkbox-test" type="checkbox" name="column" value={column.id} onClick={this.props.checkboxListner} onDoubleClick={() => this.props.addExtraColumn(column.id)} />
                                                        {column.title}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                style={{ marginLeft: 10 }}
                                onClick={this.props.handleStateColumn}
                            >
                                {t("common.save")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-right"
                                data-dismiss="modal"
                            >
                                {t("common.close")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CaseStateConfigModal