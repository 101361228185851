import React, {Component} from 'react'
import Input from "../common/Input";
import {generateUniqueKey} from "../../utils/GenerateKey";

const $ = window.$;

export class CaseTableConfigModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            title: '',
            field: '',
            filterValue: '',
            values: [],
            filters: this.props.params.caseFilters,
            editingIndex: null,
            columnsChanged: false,
            filtersChanged: false,
            tablesChanged: false,
            activeTab: 'columns',
            removeFilters: [],
            selectedColumnState: 0,
            stateFilters: [],
            errors: {}
        };
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        // Add event listener to the modal for 'hide' event
        $(this.modalRef.current).on('hide.bs.modal', this.handleModalHide);
    }

    componentWillUnmount() {
        // Remove event listener when component unmounts
        $(this.modalRef.current).off('hide.bs.modal', this.handleModalHide);
    }

    componentDidUpdate(prevProps) {
        const { attributesSorted, caseFilters, stateFilters } = this.props.params;
        if (attributesSorted !== prevProps.params.attributesSorted && attributesSorted.length > 0) {
            this.setState({ field: attributesSorted[0].attribute });
        }

        if (prevProps.params.caseFilters !== caseFilters) {
            this.setState({
                filters: caseFilters
            });
        }

        if (prevProps.params.stateFilters !== stateFilters) {
            this.setState({
                stateFilters: stateFilters
            });
        }
    }

    handleModalHide = () => {
        this.setState({
            selectedColumnState: 0,
            stateFilters: [],
        });
    }

    handleTabChange = tab => {
        this.setState({ activeTab: tab });
    }

    validate = () => {
        const {t} = this.props.params;

        const errors = {};
        const {title, values} = this.state;

        if (!title.trim()) errors.title = t("portalSettings.workflows.actions" +
            ".caseTables.filterTab.validationMessage.titleValidationMessage");
        if (values.length === 0) errors.values = t("portalSettings.workflows.actions" +
            ".caseTables.filterTab.validationMessage.filterValueMandatory");

        return  Object.keys(errors).length === 0 ? null : errors;
    }

    validateProperty = ({name, value}) => {
        const {t} = this.props.params;

        if (name === 'title' && !value.trim()) return t("portalSettings.workflows.actions" +
            ".caseTables.filterTab.validationMessage.titleValidationMessage");
        if (name === 'values' && value.length === 0) return t("portalSettings.workflows.actions" +
            ".caseTables.filterTab.validationMessage.filterValueMandatory");
    }

    handleChange = ({currentTarget: input}) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        this.setState({
            [input.name]: input.value,
            errors
        });
    }

    handleAddFilterValue = () => {
        const {filterValue, values} = this.state;

        if (filterValue && !values.some(item => item.value === filterValue)) {
            const newFilterValue = {
                id: generateUniqueKey(),
                value: filterValue
            };
            this.setState(prevState => ({
                filterValue: '',
                values: [...prevState.values, newFilterValue],
                errors: {...prevState.errors, values: ''}
            }));
        }
    }

    // Helper function to set common state
    resetState = () => {
        const { attributesSorted } = this.props.params;
        this.setState({
            title: '',
            field: attributesSorted[0]?.attribute,
            filterValue: '',
            values: [],
            editingIndex: null,
            errors: {}
        });
    }

    handleClear = () => {
        this.resetState();
        this.setState({
            removeFilters: []
        });
    }

    handleValueDelete = id => {
        let {t} = this.props.params;
        this.setState(prevState => {
            const newValues =  prevState.values.filter(value => value.id !== id);
            const errors = {...prevState.errors};
            if (newValues.length === 0) {
                errors.values = t("portalSettings.workflows.actions.caseTables" +
                    ".filterTab.validationMessage.filterValueMandatory");
            } else {
                delete errors.values;
            }
            return {
                values: newValues,
                errors
            };
        });
    }

    handleFilterDelete = filter => {
        const {id: filterId} = this.state;
        this.setState(prevState => {
            const deletingFilter = prevState.filters.find(f => f.id === filter.id);

            if (deletingFilter.id === filterId) {
               this.resetState();
            }

            return {
                filters: prevState.filters.filter(value => value.id !== filter.id),
                filtersChanged: true,
                removeFilters: [...prevState.removeFilters, filter.id]
            }
        });

        // clear state filters if it is loaded
        this.props.onFilterDelete(filter);
    }

    handleAddFilter = () => {
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors) return;

        const {id, title, field, values, filters, editingIndex} = this.state;

        // Check the same title and field already exists in the filter table
        const isRecordsExists = filters.some(filter => (filter.title === title || filter.field === field)
            && filter.id !== id);
        if (isRecordsExists) return;

        if (editingIndex !== null) {
            const updatedFilters = [...filters];
            updatedFilters[editingIndex] = {...updatedFilters[editingIndex], title, field, values};
            this.setState({
                filters: updatedFilters,
                title: '',
                field: '',
                editingIndex: null,
                filtersChanged: true,
            })
        } else {

            const newFilters = {
                id: generateUniqueKey(),
                title: title,
                field: field,
                values: [...values],
            };
            this.setState(prevState => ({
                filters: [...prevState.filters, newFilters],
                filtersChanged: true,
            }));
        }
        this.resetState();
    }

    handleFilterEdit = id => {
        const {filters} = this.state;
        const selectedItem = filters.find(f => f.id === id);
        const index = filters.indexOf(selectedItem);

        this.setState({
            id: selectedItem.id,
            title: selectedItem.title,
            field: selectedItem.field,
            values: selectedItem.values,
            editingIndex: index
        });
    }

    handleStateColumnChange = evt => {
        const {value} = evt.target;
        if (value !== 'none') {
            const selectedState = parseInt(value, 10);
            this.setState({
                selectedColumnState: selectedState,
                stateFilters: []
            });
        }

        this.props.handleCaseStateColumnChange(evt);
    }

    handleSave = () => {
        const {filters, filtersChanged, removeFilters} = this.state;
        const filterState = {
            filters: filters,
            filtersChanged: filtersChanged,
            removeFilters: removeFilters
        }

        this.props.onCaseTableSave(filterState);
    }

    render() {
        const {
            t,
            errors,
            workflowAttributes,
            attributesSorted,
            columnRecords,
            caseStates,
            initialColumns,
            stateFilters,
            selectedColumnState,
        } = this.props.params;

        const {title, filterValue, values, activeTab, filters, errors: caseTableErrors} = this.state;
        const sortedValues = values.slice().sort((a, b) => a.value.localeCompare(b.value));

        const updatedFilters = filters.map(filter => {
            const matchingStateFilter = stateFilters.find(stateFilter => stateFilter.title === filter.title);
            if (matchingStateFilter) {
                return {
                    ...filter,
                    id: matchingStateFilter.id,
                    caseStateId: matchingStateFilter.caseStateId,
                    isChecked: matchingStateFilter.isChecked
                };
            }
            return {...filter, isInitial: true};
        });

        const sortedFilters = updatedFilters.slice().sort((a, b) => a.title.localeCompare(b.title));
        const sortedTableFilters = filters.slice().sort((a, b) => a.title.localeCompare(b.title));

        return (
            <div className="modal fade" id="modal-case-table" ref={this.modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">{t("portalSettings.workflows.actions.caseTables.title")}</h4>
                        </div>
                        <div className="modal-body">

                            <ul className="nav nav-tabs pb-10 case-tbl-modal">
                                <li className="nav-item active">
                                    <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#titleTab"
                                        onClick={() => this.handleTabChange('columns')}
                                        role="tab">
                                        {t("portalSettings.workflows.actions.caseTables.tabs.columns")}
                                    </a>

                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#statusesTab"
                                        onClick={() => this.handleTabChange('statuses')}
                                        role="tab">
                                        {t("portalSettings.workflows.actions.caseTables.tabs.tables")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#filtersTab"
                                        onClick={() => this.handleTabChange('filters')}
                                        role="tab">
                                        {t("portalSettings.workflows.actions.caseTables.tabs.filters")}
                                    </a>
                                </li>

                            </ul>

                            <div className="tab-content">
                                <div className="tab-pane active" id="titleTab" role="tabpanel">
                                    <div id="modal-columns">
                                        <form>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className={
                                                        errors.title ? "form-group has-error" : "form-group"
                                                    }>
                                                        <label htmlFor="title">
                                                            {t("portalSettings.workflows.actions.columns.fields.title")}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            className="form-control"
                                                            id="title"
                                                            placeholder={t("portalSettings.workflows" +
                                                                ".actions.columns.placeholder.title")}
                                                        />
                                                        {errors.title && (
                                                            <span className="help-block">{errors.title}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className={
                                                        errors.field ? "form-group has-error" : "form-group"
                                                    }>
                                                        <label htmlFor="field">
                                                            {t("portalSettings.workflows.actions.columns.fields.field")}
                                                        </label>
                                                        <select
                                                            type="text"
                                                            name="field"
                                                            className="form-control"
                                                            id="field"
                                                        >
                                                            {workflowAttributes && attributesSorted &&
                                                                attributesSorted.map(option => {
                                                                    return (
                                                                        <option key={"C" + option.id}
                                                                                value={option.attribute}>
                                                                            {option.attributeName}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {errors.field && (
                                                            <span className="help-block">{errors.field}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className={
                                                        errors.align ? "form-group has-error" : "form-group"
                                                    }>
                                                        <label htmlFor="align">
                                                            {t("portalSettings.workflows.actions.columns.fields.align")}
                                                        </label>
                                                        <select
                                                            type="text"
                                                            name="align"
                                                            className="form-control"
                                                            id="align"
                                                        >
                                                            <option key="a1"
                                                                    value="left">{t("portalSettings.workflows" +
                                                                ".actions.columns.fields.alignOptions.left")}</option>
                                                            <option key="a2"
                                                                    value="right">{t("portalSettings.workflows" +
                                                                ".actions.columns.fields.alignOptions.right")}</option>
                                                            <option key="a3"
                                                                    value="center">{t("portalSettings.workflows" +
                                                                ".actions.columns.fields.alignOptions.center")}</option>
                                                        </select>
                                                        {errors.align && (
                                                            <span className="help-block">{errors.align}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className={
                                                        errors.width ? "form-group has-error" : "form-group"
                                                    }>
                                                        <label htmlFor="width">
                                                            {t("portalSettings.workflows.actions.columns.fields.width")}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="width"
                                                            className="form-control"
                                                            id="width"
                                                            placeholder={t("portalSettings.workflows.actions.columns.placeholder.width")}
                                                        />
                                                        {errors.width && (
                                                            <span className="help-block">{errors.width}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                <span
                                                    className="input-group-btn"
                                                    style={{paddingTop: 31, textAlign: "right"}}
                                                >
                                            <button
                                                type="button"
                                                className="btn btn-md btn-default"
                                                id="columnSave"
                                                onClick={this.props.addColumnRecord}
                                            >
                                                {t("common.add")}
                                            </button>

                                            <button
                                                type="reset"
                                                className="btn btn-md btn-default"
                                                style={{marginLeft: 10}}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.resetColumnForm();
                                                }}
                                            >
                                                {t("common.clear")}
                                            </button>
                                        </span>
                                                </div>
                                            </div>
                                            {columnRecords.length > 0 && (
                                                <div className="form-group" style={{marginTop: 30}}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th>{t("portalSettings.workflows.actions.columns.fields.title")}</th>
                                                            <th>{t("portalSettings.workflows.actions.columns.fields.field")}</th>
                                                            <th style={{width: 150}}>
                                                                {t("portalSettings.workflows.actions.columns.fields.action")}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {columnRecords.length > 0 &&
                                                            columnRecords.sort((a, b) => a.order > b.order ? 1 : -1).map((record, idx) => (
                                                                <tr key={idx}>
                                                                    <td>{record.title}</td>
                                                                    <td>{this.props.generateAttributeName(record.field)}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            style={{marginRight: 10}}
                                                                            title="Move Up"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.moveUpCaseInfoRecord("columns", record);
                                                                            }}
                                                                        >
                                                                            <span><i
                                                                                className="fa fa-arrow-up"></i></span>
                                                                        </button>

                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            style={{marginRight: 10}}
                                                                            title="Move Down"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.moveDownCaseInfoRecord("columns", record);
                                                                            }}
                                                                        >
                                                                        <span><i
                                                                            className="fa fa-arrow-down"></i></span>
                                                                        </button>

                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            style={{marginRight: 10}}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.editColumnRecord(idx, record);
                                                                            }}
                                                                        >
                                                                            <span><i className="fa fa-edit"></i></span>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            style={{marginRight: 10}}
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.deleteColumnRecord(idx);
                                                                            }}
                                                                        >
                                                                            <span><i className="fa fa-close"></i></span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                        </form>
                                    </div>
                                </div>
                                <div className="tab-pane" id="filtersTab" role="tabpanel">
                                    <p className="info-text">
                                        {t("portalSettings.workflows.actions.caseTables.infoText.message")}
                                    </p>
                                    <form className="filterTabForm">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col">
                                                        <Input name="filterTitle"
                                                               error={caseTableErrors.title}
                                                               onChange={this.handleChange}
                                                               value={title}
                                                               label={t("portalSettings.workflows.actions." +
                                                                   "caseTables.filterTab.label.title")}
                                                               placeHolder={t("portalSettings.workflows.actions." +
                                                                   "caseTables.filterTab.placeholder.title")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className={
                                                            errors.field ? "form-group has-error" : "form-group"
                                                        }>
                                                            <label htmlFor="field">
                                                                {t("portalSettings.workflows.actions" +
                                                                    ".caseTables.filterTab.label.field")}
                                                            </label>
                                                            <select
                                                                type="text"
                                                                name="field"
                                                                value={this.state.field}
                                                                onChange={this.handleChange}
                                                                className="form-control"
                                                                id="filterField"
                                                            >
                                                                {workflowAttributes && attributesSorted &&
                                                                    attributesSorted.map(option => {
                                                                        return (
                                                                            <option key={"C" + option.id}
                                                                                    value={option.attribute}>
                                                                                {option.attributeName}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                            {errors.field && (
                                                                <span className="help-block">{errors.field}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className={
                                                            caseTableErrors.values ? "input-group has-error" : "input-group"
                                                        }>
                                                            <div className="col-md-10" style={{paddingLeft: 0}}>
                                                                <label>
                                                                    {t("portalSettings.workflows.actions.caseTables." +
                                                                        "filterTab.label.filterValue")}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="filterValue"
                                                                    value={filterValue}
                                                                    className="form-control filter-value"
                                                                    onChange={this.handleChange}
                                                                    id="filterValue"
                                                                    placeholder={t(
                                                                        "portalSettings.workflows.actions.caseTables." +
                                                                        "filterTab.placeholder.filterValue"
                                                                    )}
                                                                />
                                                                {
                                                                    caseTableErrors.values && <span
                                                                        className="help-block">{caseTableErrors.values}</span>
                                                                }
                                                            </div>
                                                            <div className="col-md-2">
                                                                <span
                                                                    className="input-group-btn filter-value-btn"
                                                                    style={{ paddingTop: 25 }}>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-md btn-default"
                                                                        onClick={this.handleAddFilterValue}>
                                                                        <span className="glyphicon glyphicon-plus"></span>
                                                                    </button>
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*filter value table*/}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    {sortedValues.length > 0 && (
                                                        <table className="table table-bordered filter-value-table">
                                                            <thead>
                                                            <tr>
                                                            <th>{t("portalSettings.workflows.actions" +
                                                                    ".caseTables.filterTab.label.values")}</th>
                                                                <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {sortedValues.map(value => (
                                                                <tr key={value.id}>
                                                                    <td>{value.value}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            title="Delete"
                                                                            onClick={() => this.handleValueDelete(value.id)}
                                                                        >
                                                                            <i className="fa fa-close"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <span
                                                            className="input-group-btn"
                                                            style={{paddingTop: 25, textAlign: "right"}}
                                                        >
                                                            <button
                                                                type="button"
                                                                className={
                                                                    `btn btn-md btn-save btn-default ${this.validate() ?
                                                                        'disabled-button' : ''}`
                                                                }
                                                                onClick={this.handleAddFilter}
                                                                id="filterSave"
                                                            >
                                                                {t("portalSettings.workflows.actions" +
                                                                    ".caseTables.filterTab.button.addFilter")}
                                                            </button>

                                                            <button
                                                                id="filterClear"
                                                                type="reset"
                                                                className="btn btn-md btn-clear btn-default"
                                                                onClick={this.handleClear}
                                                                style={{marginLeft: 10}}
                                                            >
                                                                {t("portalSettings.workflows.actions" +
                                                                    ".caseTables.filterTab.button.clear")}
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                {sortedTableFilters.length > 0 && (
                                                    <div className="form-group" style={{marginTop: 30}}>
                                                        <table className="table table-bordered table-admin"
                                                               id="filterTable">
                                                            <thead>
                                                            <tr>
                                                                <th>{t("portalSettings.workflows.actions.caseTables." +
                                                                    "filterTab.filterTable.header.title")}</th>
                                                                <th>{t("portalSettings.workflows.actions.caseTables." +
                                                                    "filterTab.filterTable.header.field")}</th>
                                                                <th style={{width: 150}}/>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {sortedTableFilters.map((record, index) => (
                                                                <tr key={record.id}>
                                                                    <td>{record.title}</td>
                                                                    <td>{this.props.generateAttributeName(record.field)}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            style={{marginRight: 10}}
                                                                            title="Move Up"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.moveUpCaseInfoRecord("columns", record);
                                                                            }}
                                                                        >
                                                                            <span><i className="fa fa-arrow-up"></i></span>
                                                                        </button>

                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            style={{ marginRight: 10 }}
                                                                            title="Move Down"
                                                                            onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.moveDownCaseInfoRecord("columns", record);
                                                                            }}
                                                                        >
                                                                            <span><i className="fa fa-arrow-down"></i></span>
                                                                        </button>

                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            style={{ marginRight: 10 }}
                                                                            onClick={() => this.handleFilterEdit(record.id)}
                                                                        >
                                                                            <span><i className="fa fa-edit"></i></span>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-xs"
                                                                            style={{ marginRight: 10 }}
                                                                            onClick={() => this.handleFilterDelete(record)}
                                                                        >
                                                                            <span><i className="fa fa-close"></i></span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane" id="statusesTab" role="tabpanel">
                                    <p className="info-text"> {t("portalSettings.caseStates.infoText.message")}</p>

                                    <form id="modal-state-form">
                                        <div className="form-group">
                                            <label htmlFor="description">
                                                {t("portalSettings.workflows.actions.states.fields.state")}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="caseStateColumn"
                                                name="caseStateColumn"
                                                value={selectedColumnState}
                                                onChange={this.handleStateColumnChange}>
                                                <option key="0" value="none">{
                                                    t("portalSettings.workflows.actions.states.fields.option")}
                                                </option>
                                                {caseStates &&
                                                    caseStates.map(option => {
                                                        return (
                                                            <option key={option.id} value={option.id}>
                                                                {
                                                                    this.props
                                                                        .generateTitle("caseManagement.viewDetails.stateType",
                                                                            option.name, option.name)}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <br/>
                                        {columnRecords.length > 0 && (
                                            <h4 className="section-header">
                                                {t("portalSettings.workflows.actions.states.headers.columns")}
                                            </h4>
                                        )}
                                        <div className="row">
                                        <div className="col-md-4">
                                                <div className="form-group table-status">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="select-all"
                                                                onChange={this.props.handleSelectAll}
                                                            />
                                                            Select All
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group table-status">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="deselect-all"
                                                                onChange={this.props.handleDeselectAll}
                                                            />
                                                            Deselect All
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {columnRecords.length > 0 && columnRecords.map((column, idx) => (
                                                <div className="col-md-4" key={idx}>
                                                    <div className="form-group state-check-box">
                                                        <div>
                                                            <label>
                                                                <input
                                                                    className="checkbox-test"
                                                                    type="checkbox" name="column"
                                                                    value={column.id}
                                                                    onClick={this.props.checkboxListner}
                                                                    onDoubleClick=
                                                                        {
                                                                            () => this.props.addExtraColumn(column.id)
                                                                        }
                                                                />
                                                                {column.title}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {filters.length > 0 && (
                                            <h4 className="section-header">
                                                {t("portalSettings.workflows.actions.states.headers.filters")}
                                            </h4>
                                        )}
                                        <div className="row">
                                            {sortedFilters.length > 0 && sortedFilters.map((filter, idx) => (
                                                <div className="col-md-4" key={idx}>
                                                    <div className="form-group state-check-box">
                                                        <div>
                                                            <label>
                                                                <input
                                                                    className="checkbox-test"
                                                                    type="checkbox"
                                                                    name="filter-state"
                                                                    checked={filter.isChecked}
                                                                    onChange={
                                                                    (e) =>
                                                                        this.props.handleStateFilterCheckboxChange(e, filter)
                                                                    }

                                                                />
                                                                {filter.title}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                style={{marginLeft: 10}}
                                data-dismiss={activeTab === 'columns' ? "modal": ""}
                                onClick={this.handleSave}
                            >
                                {t("portalSettings.workflows.actions.caseTables.modalButton.save")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-right"
                                onClick={this.handleClear}
                                data-dismiss="modal"
                            >
                                {t("portalSettings.workflows.actions.caseTables.modalButton.cancel")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CaseTableConfigModal