import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import CommonTable from "../components/shared/CommonTable";
import StateAPI from "../api/StateAPI";
import StateActionAPI from "../api/StateActionAPI";
import CaseState from "../dto/CaseState";
import CaseAction from "../dto/CaseAction";
import _ from "lodash";
import WorkflowAPI from "../api/WorkflowAPI";
import CaseExpression from "../components/shared/CaseExpression";
import DeleteModal from "../components/shared/DeleteModal";
import CommonActionButton from "../components/shared/CommonActionButton";
import CaseActionModal from "../components/caseAction/CaseActionModal";
import ActionDetails from "../components/caseAction/ActionDetails";
const $ = window.$;

class SettingsCaseStateActionPage extends Component {
    ref = React.createRef();

    state = {
        caseState: new CaseState(),
        t: this.props.t,
        stateData: null,
        columns: [],
        data: [],
        isEdit: false,
        selectAction: null,
        selectedWorkflowId: null,
        caseActions: [],
        records: [],
        errors: {},
        handleRecords: [],
        caseInfoRecords: [],
        selectedCaseInfo: null,
        isCaseInfoEdit: false,
        workflowList: [],
        workflowAttributes: [],
        caseAccessExpressions: [],
        enableCaseAccessExpressions: [],
        predefinedActionList: ["LOCK", "VIEW_PDF", "UNLOCK", "ASSIGN", "LINK", "DELETE", "ADD_COMMENT"],
        formActionList: [],
        workflowActionList: [],
        isDisplayPredefinedActions: false,
        isDisplayWorkflowLink: false,
        autoSuggestedAttributeValues: [
            {
                workflowName: ["Tipsl?sning (CCM)", "Whistle - Demo", "Demo_wf", "CCM connection"],
                stateName: ["Submitted", "Rejected", "Approved", "Under_work"],
                applicantName: ["Anonymous"]
            }
        ],
        oldCondition:"",
        enableOldCondition:"",
        selectedValue:"",
        selectedType:"",
        enableCondition:true,
        information:"",
        typeSelected:""
    };

    onRowClick = stateData => {
        let { caseInfoRecords } = this.state;
        let newData = [];
        let allActionExpressions = [];
        allActionExpressions.push(stateData.caseExpressions);
        if (stateData.enableCaseExpressions !== undefined && stateData.enableCaseExpressions !== null && stateData.enableCaseExpressions.length != 0) {
            allActionExpressions.push(stateData.enableCaseExpressions);
        }
        let actionExpression = [];
        let enableActionExpression = [];
        let information = "";
        if (allActionExpressions !== undefined && allActionExpressions !== null && allActionExpressions.length != 0) {
            _.forEach(allActionExpressions, function (allActionExpression) {
                _.forEach(allActionExpression, function (obj) {
                    if (obj.type.toUpperCase() === 'DATE') {
                        obj.value = new Date(obj.value);
                    }
                    if (obj.expressionType.toUpperCase() === 'ENABLE') {
                        enableActionExpression.push(obj);
                        information = obj.information;
                    } else {
                        actionExpression.push(obj);
                    }
                }
                );
            });
            stateData.caseExpressions = actionExpression;
            stateData.enableCaseExpressions = enableActionExpression;
        }
        let record = {
            id: stateData.id,
            name: stateData.name,
            description: stateData.description,
            title: stateData.title,
            tooltip: stateData.tooltip,
            icon: stateData.icon,
            type: stateData.type,
            predefinedActions: stateData.predefinedActions,
            isCommentSection: stateData.isCommentSection,
            isMandatoryComment: stateData.isMandatoryComment,
            isCaseHistoryEntry: stateData.isCaseHistoryEntry,
            isCaseHistoryLogPdf: stateData.isCaseHistoryLogPdf,
            caseHistoryEntryTitle: stateData.caseHistoryEntryTitle,
            confirmActionButtonLabel: stateData.confirmActionButtonLabel,
            cancelActionButtonLabel: stateData.cancelActionButtonLabel,
            commentHintText: stateData.commentHintText,
            isTransitionState: stateData.isTransitionState,
            caseTransitionState: stateData.caseTransitionState,
            caseAccessExpressions: actionExpression,
            enableCaseAccessExpressions: enableActionExpression,
            selectedWorkflowId: stateData.selectedWorkflowId,
            successMessage: stateData.successMessage,
            workflowLink: stateData.workflowLink,
            pinned: stateData.pinned
        };
        newData.push(record);

        let recordExpressions = {
            id: null,
            title: $('#title').val(),
            order: caseInfoRecords.length + 1,
            workflowId: stateData.selectedWorkflowId,
            caseExpressions: actionExpression,
            enableCaseExpressions: enableActionExpression
        }

        var newExpressionData = [];
        newExpressionData = [...caseInfoRecords, recordExpressions];

        let mandatorySuccessMessage = true;
        if (stateData.name == 'VIEW_PDF') {
            mandatorySuccessMessage = false;
        }

        let isDisplayPredefinedActions = false;

        let isDisplayWorkflowLink = false;

        if (stateData.type==1) {
            isDisplayPredefinedActions = true;
        }

        if (stateData.type == 4) {
            isDisplayWorkflowLink = true;
        }

        this.setState({
            stateData: stateData,
            caseState: stateData,
            caseAccessExpressions: actionExpression,
            enableCaseAccessExpressions: enableActionExpression,
            isEdit: true,
            records: newData,
            handleRecords: [],
            caseInfoRecords: newExpressionData,
            information:information,
            mandatorySuccessMessage: mandatorySuccessMessage,
            isDisplayPredefinedActions:isDisplayPredefinedActions,
            isDisplayWorkflowLink:isDisplayWorkflowLink
        });
        this.updateWorkflowAttributes(stateData.selectedWorkflowId,actionExpression,enableActionExpression);
        this.changeCheckBoxValues(true, stateData);
    };

    reverseOnRowClick = () => {
        this.setState({
            stateData: null,
            caseState: new CaseState(),
            isEdit: false,
            records: [],
            handleRecords: []
        });
        this.componentDidMount();
    }

    componentDidMount() {
        this.updateStateTable();
        this.updateCaseStateActions();
        this.hideFields();
        this.getWorkflows();
    }

    getWorkflows() {
        WorkflowAPI.getWorkflows().then(data => this.setState({ workflowList: data }));
    }

    hideFields() {
        $('#isCaseHistoryLogPdfRow').hide();
        $('#isMandatoryCommentRow').hide();
        $('#commentHintTextRow').hide();
        $('#caseHistoryEntryTitleRow').hide();
        $('#caseTransitionStateRow').hide();
        $('#edit_close').hide();
    }

    updateStateTable() {
        let { columns, t } = this.state;

        function getColumns(column, t) {
            var translation = t("caseManagement.caseActions.fields." + column.field + ".name");
            if (!translation.includes("caseManagement.caseActions.fields")) {
                column.title = translation;
            }
        }

        function getDataValues(data, t) {
            var translation = t("caseManagement.caseActions.fields.predefinedActions.options." + data.name);
            if (!translation.includes("caseManagement.caseActions.fields.predefinedActions.options")) {
                data.predefinedActionsTranslated = translation;
            }
        }

        this.hideFields();

        StateActionAPI.all().then(data => {
            _.forEach(data.columns, function (column) {
                getColumns(column, t)
            });

            _.forEach(data.data, function (data) {
                getDataValues(data, t)
            });

            columns = [...data.columns];
            data.data = [...data.data];

            this.setState({
                columns: columns,
                data: data.data,
                caseState: new CaseState(),
                stateData: null,
                isEdit: false,
                selectAction: null,
                records: [],
                errors: {},
                handleRecords: [],
                caseInfoRecords: []
            });
        });
    }

    updateCaseStateActions() {
        StateActionAPI.all().then(data => {
            this.setState({ caseActions: data.data })
        })
    }

    handleChange = event => {
        let { name, value } = event.target;
        let text='';
        if (event.target.options) {
            value = [...event.target.selectedOptions].map(option => option.value);
            text = [...event.target.selectedOptions].map(option => option.text);            
        }
        if (name === "status") {
            value = event.target.checked ? "ACTIVE" : "INACTIVE";
        }

        if (name === "isCommentSection" || name === "isMandatoryComment" || name === "isCaseHistoryEntry") {
            value = event.target.checked;
        }

        $('#isCaseHistoryLogPdfRow').hide();

        let mandatoryCommentHintText = false;
        let mandatoryCaseHistoryEntryTitle = false;
        let mandatoryCommentBox = true;
        let mandatoryDescription = true;
        let mandatorySuccessMessage = true;

        if ((name === "isCommentSection" && event.target.checked === true) || $("input[name=isCommentSection]").prop('checked')) {
            $('#isMandatoryCommentRow').show();
            $('#commentHintTextRow').show();
            $('#isMandatoryComment').attr('disabled', false);
            $('#commentHintText').attr('disabled', false);
            mandatoryCommentHintText = true;
        } else if ((name === "isCommentSection" && event.target.checked === false) || !$("input[name=isCommentSection]").prop('checked')) {
            $('#isMandatoryComment').attr('disabled', true);
            $('#commentHintText').attr('disabled', true);
            $("input[name=isMandatoryComment]").prop('checked', false);
            $("input[name=commentHintText]").val('');
            $('#isMandatoryCommentRow').hide();
            $('#commentHintTextRow').hide();
            mandatoryCommentHintText = false;
        }

        if ((name === "isCaseHistoryEntry" && event.target.checked === true) || $("input[name=isCaseHistoryEntry]").prop('checked')) {
            $('#caseHistoryEntryTitle').attr('disabled', false);
            $('#caseHistoryEntryTitleRow').show();
            mandatoryCaseHistoryEntryTitle = true;
        } else if ((name === "isCaseHistoryEntry" && event.target.checked === false) || !$("input[name=isCaseHistoryEntry]").prop('checked')) {
            $('#caseHistoryEntryTitle').attr('disabled', true);
            $("input[name=caseHistoryEntryTitle]").val('');
            $('#caseHistoryEntryTitleRow').hide();
            mandatoryCaseHistoryEntryTitle = false;
        }

        if ((name === "predefinedActions" && ['VIEW_PDF'].includes(value[0]))
            || ['VIEW_PDF'].includes($("#predefinedActions").val())) {
            $('#confirmActionButtonLabel').attr('disabled', true);
            $('#cancelActionButtonLabel').attr('disabled', true);
            $('#actionButtonLabelRow').hide();

            $('#descriptionRow').hide();
            $('#commentSectionRow').hide();
            $('#commentHintTextRow').hide();
            $('#caseHistoryRow').hide();
            $('#caseHistoryEntryTitleRow').hide();
            $('#successMessageCol').hide();

            mandatoryCommentBox = false;
            mandatoryDescription = false;
            mandatorySuccessMessage = false;
        } else if ((name === "predefinedActions" && ['DELETE'].includes(value[0]))
            || ['DELETE'].includes($("#predefinedActions").val())) {
            $('#confirmActionButtonLabel').attr('disabled', false);
            $('#cancelActionButtonLabel').attr('disabled', false);
            $('#actionButtonLabelRow').show();
            $('#descriptionRow').show();

            $('#commentSectionRow').hide();
            $('#commentHintTextRow').hide();
            $('#caseHistoryRow').hide();
            $('#caseHistoryEntryTitleRow').hide();
            $('#successMessageCol').show();
            mandatoryCommentBox = false;
            mandatorySuccessMessage = true;

            $('#caseTransitionState').attr('disabled', false);
            $('#caseTransitionStateRow').show();
        } else if (name === "predefinedActions") {
            $('#confirmActionButtonLabel').attr('disabled', false);
            $('#cancelActionButtonLabel').attr('disabled', false);
            $('#actionButtonLabelRow').show();

            $('#descriptionRow').show();
            $('#commentSectionRow').show();
            $('#caseHistoryRow').show();
            $('#successMessageCol').show();
            mandatorySuccessMessage = true;
            if((['ASSIGN'].includes(value[0]))
                || ['ASSIGN'].includes($("#predefinedActions").val())) {
                $('#caseTransitionState').attr('disabled', false);
                $('#caseTransitionStateRow').show();
            }
        }

        this.setState({
            caseState: {
                ...this.state.caseState,
                [event.target.name]: value
            },
            mandatoryCommentHintText: mandatoryCommentHintText,
            mandatoryCaseHistoryEntryTitle: mandatoryCaseHistoryEntryTitle,
            mandatoryCommentBox: mandatoryCommentBox,
            mandatoryDescription: mandatoryDescription,
            selectedWorkflowId: (name === "selectedWorkflowId"?value[0]:null),
            mandatorySuccessMessage: mandatorySuccessMessage
        });

        if (name === "selectedWorkflowId" && value != null && value != "") {
            if (value != null && value != "") {
                $('#actionConditionButton').attr('disabled', false);
                let saveWorkflowName = this.state.caseState.selectedWorkflowName;
                let saveInformation = "";
                if (saveWorkflowName === text[0]) {
                    let savedCaseExpressions = [];
                    let savedEnableCaseExpressions = [];
                    if (this.state.caseState.caseExpressions !== undefined && this.state.caseState.caseExpressions !== null) {
                        savedCaseExpressions = this.state.caseState.caseExpressions;
                    }
                    if (this.state.caseState.enableCaseExpressions !== undefined && this.state.caseState.enableCaseExpressions !== null) {
                        savedEnableCaseExpressions = this.state.caseState.enableCaseExpressions
                        _.forEach(savedEnableCaseExpressions, function (obj) {
                            if (obj.information !== "") {
                                saveInformation = obj.information
                            }
                        });
                    }
                    this.updateWorkflowAttributes(value, savedCaseExpressions, savedEnableCaseExpressions);
                } else {
                    saveInformation = "";
                    this.updateWorkflowAttributes(value, [], []);
                }
                this.setState({
                    information: saveInformation
                });
            } else {
                $('#actionConditionButton').attr('disabled', true);
            }
        }
    };

    removeUnwantedValues = (mandatoryCommentHintText, mandatoryCaseHistoryEntryTitle) => {
        let { caseState } = this.state;
        let clearCommentHint = $("input[name=commentHintText]").val();
        let clearHistoryTitle = $("input[name=caseHistoryEntryTitle]").val();


        if (!$("input[name=isCommentSection]").prop('checked')) {
            caseState.commentHintText = ""
        }

        if (!$("input[name=isCaseHistoryEntry]").prop('checked')) {
            caseState.caseHistoryEntryTitle = ""
        }

        this.setState({
            caseState: caseState
        });
    }

    deleteCaseState = (id, name) => {
        const { t } = this.state;

        StateAPI.deleteAction(id).then(() => {
            $.notification("success", t("common.notification.success"), t("common.validations.RECORD_DELETED_SUCCESS"));
            this.updateStateTable();
        }).catch(error => {
            this.errorHandling(error);
        });
    };

    handleSave = event => {
        const { t } = this.state;

        let {
            title,
            tooltip,
            icon,
            description,
            type,
            predefinedActions,
            isCommentSection,
            isMandatoryComment,
            isCaseHistoryEntry,
            isCaseHistoryLogPdf,
            caseHistoryEntryTitle,
            confirmActionButtonLabel,
            cancelActionButtonLabel,
            commentHintText,
            isTransitionState,
            caseTransitionState,
            workflowLink,
            selectedWorkflowId,
            successMessage,
            pinned
        } = this.state.caseState;

        event.preventDefault();

        if (Array.isArray(predefinedActions)) {
            predefinedActions = predefinedActions[0];
        }

        if (Array.isArray(selectedWorkflowId)) {
            selectedWorkflowId = selectedWorkflowId[0];
        }

        let caseActionObj = new CaseAction(
            "",
            title,
            tooltip,
            icon,
            description,
            type,
            predefinedActions,
            isCommentSection,
            isMandatoryComment,
            isCaseHistoryEntry,
            isCaseHistoryLogPdf,
            caseHistoryEntryTitle,
            confirmActionButtonLabel,
            cancelActionButtonLabel,
            commentHintText,
            isTransitionState,
            caseTransitionState,
            workflowLink,
            selectedWorkflowId,
            successMessage,
            pinned
        );


        this.validateWorkflowLink(workflowLink);

        if (!this.isFormValid(caseActionObj)) return;
        caseActionObj.caseExpressions = [];
        if(this.state.caseInfoRecords[0]!=undefined && this.state.caseInfoRecords[0]!=null){
            if(this.state.caseInfoRecords[0].caseExpressions!=undefined && this.state.caseInfoRecords[0].caseExpressions!=null) {
                $.each(this.state.caseInfoRecords[0].caseExpressions, function (key, val) {
                    caseActionObj.caseExpressions.push(val);
                });
            }
            if(this.state.caseInfoRecords[0].enableCaseExpressions!=undefined && this.state.caseInfoRecords[0].enableCaseExpressions!=null) {
                $.each(this.state.caseInfoRecords[0].enableCaseExpressions, function (key, val) {
                    caseActionObj.caseExpressions.push(val);
                });
            }
        }

        if(caseActionObj.type=="2" || caseActionObj.type=="3") {
            caseActionObj.isTransitionState = true;
            caseActionObj.name = caseTransitionState;
        } else {
            caseActionObj.isTransitionState = false;
        }

        if (this.state.isEdit) {
            if (caseActionObj.id == null || caseActionObj.id == "") {
                caseActionObj.id = this.state.stateData.id;
            }
            caseActionObj.isCommentSection = $("input[name=isCommentSection]").prop('checked');
            caseActionObj.isMandatoryComment = $("input[name=isMandatoryComment]").prop('checked');
            caseActionObj.isCaseHistoryEntry = $("input[name=isCaseHistoryEntry]").prop('checked');
            caseActionObj.isCaseHistoryLogPdf = $("input[name=isCaseHistoryLogPdf]").prop('checked');
            caseActionObj.commentHintText = $("#commentHintText").val();
            caseActionObj.caseHistoryEntryTitle = $("#caseHistoryEntryTitle").val();
            caseActionObj.caseTransitionState = $("#caseTransitionState").val();
            caseActionObj.workflowLink = $("#workflowLink").val();

            StateAPI.updateAction(caseActionObj).then(() => {
                $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
                this.updateStateTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        } else {
            StateAPI.saveAction(caseActionObj).then(() => {
                $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
                this.updateStateTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        }

        this.closeModal();
    }

    errorHandling = error => {
        const { t } = this.state;

        if (error.response) {
            $.notification("error", t("common.notification.error"), t("portalSettings.caseStates.validationError." + error.response.data));
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
    }

    closeModal = () => {
        $(function () {
            $('#modal-case-action').modal('hide');
        });
    }

    openModal = () => {
        if (this.state.errors != null && this.state.errors.length != 0) {
            this.setState({
                errors: []
            });
        }
        if (this.state.isEdit) {
            if (this.state.records.length != 0) {
                let savedCaseExpression = [];
                let savedEnableCaseExpression = [];
                let savedInformation = "";
                _.forEach(this.state.records, function (record) {
                    if (record.caseAccessExpressions.length != 0) {
                        _.forEach(record.caseAccessExpressions, function (obj) {
                            if (obj.type.toUpperCase() === 'DATE') {
                                obj.value = new Date(obj.value);
                            }
                        });
                        savedCaseExpression = record.caseAccessExpressions;
                    }
                    if (record.enableCaseAccessExpressions.length != 0) {
                        _.forEach(record.enableCaseAccessExpressions, function (obj) {
                            if (obj.type.toUpperCase() === 'DATE') {
                                obj.value = new Date(obj.value);
                            }
                            if (obj.information !== "") {
                                savedInformation = obj.information;
                            }
                        });
                        savedEnableCaseExpression = record.enableCaseAccessExpressions;
                    }
                });
                this.setState({
                    information: savedInformation
                });
                this.updateWorkflowAttributes(this.state.stateData.selectedWorkflowId, savedCaseExpression, savedEnableCaseExpression);
            }
            this.setState({
                handleRecords: this.state.records,
                selectedValue: ""
            });
        } else {
            this.setState({
                handleRecords: []
            });
        }

        $(function () {
            $('#modal-case-action').find('form').trigger('reset');
            $('#modal-case-action').modal('show');
            $('#modal-case-action').css('overflow-y', 'auto');
        });
    }

    openModalAfterExpression = () => {
        $(function () {
            $('#modal-case-action').modal('show');
            $('#modal-case-action').css('overflow-y', 'auto');
        });
    }

    isFormValid = caseObject => {
        const { t,
            mandatoryCommentHintText,
            mandatoryCaseHistoryEntryTitle,
            mandatoryCommentBox,
            mandatoryTransitionState,
            mandatoryDescription,
            mandatorySuccessMessage,
            mandatoryPredefinedAction,
            isValidWorkflowLink
        } = this.state;

        const errors = {};
        if (!caseObject.title)
            errors.title = t("caseManagement.caseActions.fields.title.validation.required")

        if (!caseObject.tooltip)
            errors.tooltip = t("caseManagement.caseActions.fields.tooltip.validation.required")

        if (!caseObject.icon)
            errors.icon = t("caseManagement.caseActions.fields.icon.validation.required")

        if (mandatoryDescription && !caseObject.description)
            errors.description = t("caseManagement.caseActions.fields.description.validation.required")

        if (!caseObject.type)
            errors.type = t("caseManagement.caseActions.fields.type.validation.required")

        if (mandatoryPredefinedAction && !caseObject.predefinedActions)
            errors.predefinedActions = t("caseManagement.caseActions.fields.predefinedActions.validation.required")

        if (mandatoryCommentBox && !caseObject.confirmActionButtonLabel)
            errors.confirmActionButtonLabel = t("caseManagement.caseActions.fields.confirmActionButtonLabel.validation.required")

        if (mandatoryCommentBox && !caseObject.cancelActionButtonLabel)
            errors.cancelActionButtonLabel = t("caseManagement.caseActions.fields.cancelActionButtonLabel.validation.required")

        if (mandatoryCommentHintText && !caseObject.commentHintText)
            errors.commentHintText = t("caseManagement.caseActions.fields.commentHintText.validation.required")

        if (mandatoryCaseHistoryEntryTitle && !caseObject.caseHistoryEntryTitle)
            errors.caseHistoryEntryTitle = t("caseManagement.caseActions.fields.caseHistoryEntryTitle.validation.required")

        if (mandatoryTransitionState && !caseObject.caseTransitionState)
            errors.caseTransitionState = t("caseManagement.caseActions.fields.caseTransitionState.validation.required")

        if (!caseObject.workflowLink && caseObject.type == "4")
            errors.workflowLink = t("caseManagement.caseActions.fields.workflowLink.validation.required")

        if (!isValidWorkflowLink && caseObject.workflowLink) 
            errors.workflowLink = t("caseManagement.caseActions.fields.workflowLink.validation.validLink")

        if (mandatorySuccessMessage && !caseObject.successMessage)
            errors.successMessage = t("caseManagement.caseActions.fields.successMessage.validation.required")

        this.setState({
            errors: errors
        });
        return Object.keys(errors).length === 0;
    }

    validateWorkflowLink = workflowLink => {
        if (/^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(workflowLink)) {
            this.state.isValidWorkflowLink = true;
        } else {
            this.state.isValidWorkflowLink = false;
        }
    }

    handleCaseActionTypeChange = event => {
        let { name, value } = event.target;
        let { predefinedActionList } = this.state;
        let isDisplayPredefinedActions = true;
        let isDisplayWorkflowLink = true;
        switch (value) {
            case "":
                isDisplayPredefinedActions = false;
                isDisplayWorkflowLink = false;
                this.setState({
                    typeSelected: ""
                });
                break;
            case "1":
                isDisplayPredefinedActions = true;
                isDisplayWorkflowLink = false;
                this.setState({
                    typeSelected: "1"
                });
                break;
            case "2":
                isDisplayPredefinedActions = false;
                isDisplayWorkflowLink = false;
                this.setState({
                    typeSelected: "2"
                });
                break;
            case "3":
                isDisplayPredefinedActions = false;
                isDisplayWorkflowLink = false;
                this.setState({
                    typeSelected: "3"
                });
                break;
            case "4":
                isDisplayPredefinedActions = false;
                isDisplayWorkflowLink = true;
                this.setState({
                    typeSelected: "4"
                });
                break;
        }

        let mandatoryTransitionState = true;
        let mandatoryPredefinedAction = true;
        if (name === "type" && (value === '2' || value=='3')) {
            $('#caseTransitionState').attr('disabled', false);
            $('#caseTransitionStateRow').show();
            mandatoryTransitionState = true;
            mandatoryPredefinedAction = false;
        } else if (name === "type" && (value === '' || value=='1')) {
            $('#caseTransitionState').attr('disabled', true);
            $("input[name=caseTransitionState]").val('');
            $('#caseTransitionStateRow').hide();
            mandatoryTransitionState = false;
            mandatoryPredefinedAction = true;
        } else if (name === "type" && (value=='4')) {
            $('#caseTransitionState').attr('disabled', true);
            $("input[name=caseTransitionState]").val('');
            $('#caseTransitionStateRow').hide();
            mandatoryPredefinedAction = false;
            mandatoryTransitionState = false;
        }

        this.setState({
            predefinedActionList: predefinedActionList,
            isDisplayPredefinedActions: isDisplayPredefinedActions,
            mandatoryTransitionState: mandatoryTransitionState,
            mandatoryPredefinedAction: mandatoryPredefinedAction,
            isDisplayWorkflowLink: isDisplayWorkflowLink,
            caseState: {
                ...this.state.caseState,
                [event.target.name]: value
            }
        });
    }


    handleWorkflowChange = event => {
        this.setState({
            selectedWorkflowId: event.target.value,
            caseState: {
                ...this.state.caseState,
                [event.target.name]: event.target.value
            },
            stateData: {
                ...this.state.stateData,
                [event.target.name]: event.target.value
            },
        })
        this.updateWorkflowAttributes(event.target.value)
    }

    updateWorkflowAttributes(workflowId, caseActionExpression,enableCaseAccessExpression) {
        WorkflowAPI.getWorkflowAttributes(workflowId).then(data => {
            this.setState({
                workflowAttributes: data,
                caseAccessExpressions:caseActionExpression,
                enableCaseAccessExpressions:enableCaseAccessExpression
            })
        })
    }

    addRecord = () => {
        const {
            caseActions,
            selectAction,
            records,
            handleRecords
        } = this.state;

        var selectedAction;
        var newData;

        if (selectAction != null) {
            selectedAction = _.find(caseActions, { id: selectAction });

            let record = {
                id: selectedAction.id,
                name: selectedAction.name,
                description: selectedAction.description
            };

            if (!_.find(handleRecords, { name: selectedAction.name })) {
                newData = [...handleRecords, record];
                this.setState({
                    handleRecords: newData
                });
            }
        }
    }

    deleteRecord = idx => {
        var handleRecords = this.state.handleRecords;
        var newData = [];
        handleRecords.forEach(item => {
            let record = {
                id: item.id,
                name: item.name,
                description: item.description
            };
            newData.push(record);
        })

        newData.splice(idx, 1);
        this.setState({
            handleRecords: newData
        });
    };

    generateTitle = (type, key, title) => {
        const { t } = this.state;
        var translation = t(type + "." + key);
        if (translation.includes(type)) {
            return title;
        } else {
            return translation;
        }
    }

    changeType(isEdit, type) {
        if (isEdit == true) {
            $('#type').val(type).change();
            $("#type option[value=" + type + "]").attr("selected", "selected");
            $('#type').trigger("change");
        }
    }

    typeSelected (newValue) {
        this.setState({ typeSelected: newValue });
    }

    changeAction(isEdit, action) {
        if (isEdit == true) {
            $("#predefinedActions option:selected").each(function () {
                $(this).removeAttr('selected');
            });

            $('#predefinedActions').val(action).change();
            $("#predefinedActions option[value='" + action + "']").attr("selected", "selected");
            $('#predefinedActions').trigger("change");
        }
    }

    changeWorkflows(isEdit, workflow) {
        if (isEdit == true) {
            $("#selectedWorkflowId option:selected").each(function () {
                $(this).removeAttr('selected');
            });

            $('#selectedWorkflowId').val(workflow).change();
            $("#selectedWorkflowId option[value='" + workflow + "']").attr("selected", "selected");
            $('#selectedWorkflowId').trigger("change");
        }
    }

    changeCheckBoxValues(isEdit, stateData) {
        if (isEdit) {
            $('#edit_close').show();
            //$('#predefinedActions').val(stateData.predefinedActions)
            this.changeWorkflows(isEdit, stateData.selectedWorkflowId);

            $('#isCommentSection').val(false);
            $('#isCommentSection').prop('checked', false);
            $('#isCommentSection').attr('checked', false);

            $('#isMandatoryComment').val(false);
            $('#isMandatoryComment').prop('checked', false);
            $('#isMandatoryComment').attr('checked', false);

            $('#isCaseHistoryEntry').val(false);
            $('#isCaseHistoryEntry').prop('checked', false);
            $('#isCaseHistoryEntry').attr('checked', false);

            $('#isCaseHistoryLogPdf').val(false);
            $('#isCaseHistoryLogPdf').prop('checked', false);
            $('#isCaseHistoryLogPdf').attr('checked', false);

            this.typeSelected(stateData.type);

            this.changeAction(isEdit, stateData.predefinedActions);

            $('#isCaseHistoryLogPdfRow').hide();
            $('#isMandatoryCommentRow').hide();
            $('#commentHintTextRow').hide();
            $('#caseHistoryEntryTitleRow').hide();
            $('#caseTransitionStateRow').hide();

            if (stateData.type == '2' || stateData.type=='3') {
                $('#caseTransitionState').attr('disabled', false);
                $('#caseTransitionStateRow').show();
            } else if (stateData.type == '' || stateData.type=='1') {
                $('#caseTransitionState').attr('disabled', true);
                $("input[name=caseTransitionState]").val('');
                $('#caseTransitionStateRow').hide();
            } 

            if (['VIEW_PDF'].includes(stateData.predefinedActions)) {
                $('#confirmActionButtonLabel').attr('disabled', true);
                $('#cancelActionButtonLabel').attr('disabled', true);
                $('#actionButtonLabelRow').hide();
                $('#descriptionRow').hide();
                $('#commentSectionRow').hide();
                $('#commentHintTextRow').hide();
                $('#caseHistoryRow').hide();
                $('#caseHistoryEntryTitleRow').hide();
                $('#successMessageCol').hide();
            } else if (['DELETE'].includes(stateData.predefinedActions)) {
                $('#actionButtonLabelRow').show();
                $('#confirmActionButtonLabel').attr('disabled', false);
                $('#cancelActionButtonLabel').attr('disabled', false);
                $('#descriptionRow').show();
                $('#commentSectionRow').hide();
                $('#commentHintTextRow').hide();
                $('#caseHistoryRow').hide();
                $('#caseHistoryEntryTitleRow').hide();
                $('#successMessageCol').show();
                $('#caseTransitionState').attr('disabled', false);
                $('#caseTransitionStateRow').show();
            } else {
                $('#actionButtonLabelRow').show();
                $('#confirmActionButtonLabel').attr('disabled', false);
                $('#cancelActionButtonLabel').attr('disabled', false);
                $('#descriptionRow').show();
                $('#commentSectionRow').show();
                $('#caseHistoryRow').show();
                $('#successMessageCol').show();
                if(['ASSIGN'].includes(stateData.predefinedActions)) {
                    $('#caseTransitionState').attr('disabled', false);
                    $('#caseTransitionStateRow').show();
                }
            }

            if (stateData.isCommentSection === "true") {
                $('#isMandatoryCommentRow').show();
                $('#commentHintTextRow').show();

                $('#isCommentSection').val(true);
                $('#isCommentSection').prop('checked', true);
                $('#isCommentSection').attr('checked', true);

                $('#isMandatoryComment').attr('disabled', false);
                $('#commentHintText').attr('disabled', false);
            }

            if (stateData.isMandatoryComment === "true") {
                $('#isMandatoryComment').val(true);
                $('#isMandatoryComment').prop('checked', true);
                $('#isMandatoryComment').attr('checked', true);
            }

            if (stateData.isCaseHistoryEntry === "true") {
                $('#caseHistoryEntryTitleRow').show();
                $('#isCaseHistoryEntry').val(true);
                $('#isCaseHistoryEntry').prop('checked', true);
                $('#isCaseHistoryEntry').attr('checked', true);
                $('#caseHistoryEntryTitle').attr('disabled', false);
            }

            if (stateData.isCaseHistoryLogPdf === "true") {
                $('#isCaseHistoryLogPdf').val(true);
                $('#isCaseHistoryLogPdf').prop('checked', true);
                $('#isCaseHistoryLogPdf').attr('checked', true);
            }
        }
    }

    handleDateChange = (selectedExpression, val) => {
        const { caseAccessExpressions } = this.state;
        let expression = _.find(caseAccessExpressions, {
            expressionId: selectedExpression.expressionId
        });
        expression.value = val;
        this.setState({
            caseAccessExpressions: caseAccessExpressions
        });
    } 
    enableHandleDateChange = (selectedExpression, val) => {
        const { enableCaseAccessExpressions } = this.state;
        let expression = _.find(enableCaseAccessExpressions, {
            expressionId: selectedExpression.expressionId
        });
        expression.value = val;
        this.setState({
            enableCaseAccessExpressions: enableCaseAccessExpressions
        });
    }
    changeAttribute = (event,optionList) => {
        const { autoSuggestedAttributeValues,caseAccessExpressions} = this.state;
        _.forEach(caseAccessExpressions, function (obj) {
            let attribute = $('#attribute' + obj.expressionId).val();
            let selected = _.find(optionList, { attribute: attribute });
            if (selected !== undefined && selected !== null && selected !== "") {
                obj.type = selected.type;
                if (selected.type.toUpperCase() === 'DATE') {
                    if (typeof obj.value === "string") {
                        obj.value = new Date();
                    }
                } else {
                    obj.value = "";
                }
            }
        }
        );
        const selectedValue = event.target.value;
        let selected = _.find(optionList, { attribute: selectedValue });
        if (selected !== undefined && selected !== null && selected !== "") {
            this.setState({
                selectedType: selected.type
            });
        }
        var id = event.target.id;
        id = id.replace('attribute', '');

        $(function () {

            function autocompleteExpression(inp, arr) {
                /*the autocomplete function takes two arguments,
                the text field element and an array of possible autocompleted values:*/
                var currentFocus;
                /*execute a function when someone writes in the text field:*/
                inp.addEventListener("input", function (e) {
                    var a, b, i, val = this.value;
                    /*close any already open lists of autocompleted values*/
                    closeAllLists();
                    if (!val) { return false; }
                    currentFocus = -1;
                    /*create a DIV element that will contain the items (values):*/
                    a = document.createElement("DIV");
                    a.setAttribute("id", this.id + "autocomplete-list");
                    a.setAttribute("class", "autocomplete-items");
                    /*append the DIV element as a child of the autocomplete container:*/
                    this.parentNode.appendChild(a);
                    /*for each item in the array...*/
                    for (i = 0; i < arr.length; i++) {
                        /*check if the item starts with the same letters as the text field value:*/
                        if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                            /*create a DIV element for each matching element:*/
                            b = document.createElement("DIV");
                            /*make the matching letters bold:*/
                            b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                            b.innerHTML += arr[i].substr(val.length);
                            /*insert a input field that will hold the current array item's value:*/
                            b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                            /*execute a function when someone clicks on the item value (DIV element):*/
                            b.addEventListener("click", function (e) {
                                /*insert the value for the autocomplete text field:*/
                                inp.value = this.getElementsByTagName("input")[0].value;
                                /*close the list of autocompleted values,
                                (or any other open lists of autocompleted values:*/
                                closeAllLists();
                            });
                            a.appendChild(b);
                        }
                    }
                });
                /*execute a function presses a key on the keyboard:*/
                inp.addEventListener("keydown", function (e) {
                    var x = document.getElementById(this.id + "autocomplete-list");
                    if (x) x = x.getElementsByTagName("div");
                    if (e.keyCode == 40) {
                        /*If the arrow DOWN key is pressed,
                        increase the currentFocus variable:*/
                        currentFocus++;
                        /*and and make the current item more visible:*/
                        addActive(x);
                    } else if (e.keyCode == 38) { //up
                        /*If the arrow UP key is pressed,
                        decrease the currentFocus variable:*/
                        currentFocus--;
                        /*and and make the current item more visible:*/
                        addActive(x);
                    } else if (e.keyCode == 13) {
                        /*If the ENTER key is pressed, prevent the form from being submitted,*/
                        e.preventDefault();
                        if (currentFocus > -1) {
                            /*and simulate a click on the "autocompleteExpressionactive" item:*/
                            if (x) x[currentFocus].click();
                        }
                    }
                });
                function addActive(x) {
                    /*a function to classify an item as "active":*/
                    if (!x) return false;
                    /*start by removing the "active" class on all items:*/
                    removeActive(x);
                    if (currentFocus >= x.length) currentFocus = 0;
                    if (currentFocus < 0) currentFocus = (x.length - 1);
                    /*add class "autocomplete-active":*/
                    x[currentFocus].classList.add("autocomplete-active");
                }
                function removeActive(x) {
                    /*a function to remove the "active" class from all autocomplete items:*/
                    for (var i = 0; i < x.length; i++) {
                        x[i].classList.remove("autocomplete-active");
                    }
                }
                function closeAllLists(elmnt) {
                    /*close all autocomplete lists in the document,
                    except the one passed as an argument:*/
                    var x = document.getElementsByClassName("autocomplete-items");
                    for (var i = 0; i < x.length; i++) {
                        if (elmnt != x[i] && elmnt != inp) {
                            x[i].parentNode.removeChild(x[i]);
                        }
                    }
                }
                /*execute a function when someone clicks in the document:*/
                document.addEventListener("click", function (e) {
                    closeAllLists(e.target);
                });
            }

            if (autoSuggestedAttributeValues[0][selectedValue] != undefined) {
                autocompleteExpression(document.getElementById("expression" + id), autoSuggestedAttributeValues[0][selectedValue]);
            }

        });
    }

    enableChangeAttribute = (event,optionList) => {
        const { autoSuggestedAttributeValues,enableCaseAccessExpressions} = this.state;
        _.forEach(enableCaseAccessExpressions, function (obj) {
            let attribute = $('#enableAttribute' + obj.expressionId).val();
            let selected = _.find(optionList, { attribute: attribute });
            if (selected !== undefined && selected !== null && selected !== "") {
                obj.type = selected.type;
                if (selected.type.toUpperCase() === 'DATE') {
                    if (typeof obj.value === "string") {
                        obj.value = new Date();
                    }
                } else {
                    obj.value = "";
                }
            }
        }
        );
        const selectedValue = event.target.value;
        let selected = _.find(optionList, { attribute: selectedValue });
        if (selected !== undefined && selected !== null && selected !== "") {
            this.setState({
                selectedType: selected.type
            });
        }
        var id = event.target.id;
        id = id.replace('enableAttribute', '');

        $(function () {

            function autocompleteExpression(inp, arr) {
                /*the autocomplete function takes two arguments,
                the text field element and an array of possible autocompleted values:*/
                var currentFocus;
                /*execute a function when someone writes in the text field:*/
                inp.addEventListener("input", function (e) {
                    var a, b, i, val = this.value;
                    /*close any already open lists of autocompleted values*/
                    closeAllLists();
                    if (!val) { return false; }
                    currentFocus = -1;
                    /*create a DIV element that will contain the items (values):*/
                    a = document.createElement("DIV");
                    a.setAttribute("id", this.id + "autocomplete-list");
                    a.setAttribute("class", "autocomplete-items");
                    /*append the DIV element as a child of the autocomplete container:*/
                    this.parentNode.appendChild(a);
                    /*for each item in the array...*/
                    for (i = 0; i < arr.length; i++) {
                        /*check if the item starts with the same letters as the text field value:*/
                        if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                            /*create a DIV element for each matching element:*/
                            b = document.createElement("DIV");
                            /*make the matching letters bold:*/
                            b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                            b.innerHTML += arr[i].substr(val.length);
                            /*insert a input field that will hold the current array item's value:*/
                            b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                            /*execute a function when someone clicks on the item value (DIV element):*/
                            b.addEventListener("click", function (e) {
                                /*insert the value for the autocomplete text field:*/
                                inp.value = this.getElementsByTagName("input")[0].value;
                                /*close the list of autocompleted values,
                                (or any other open lists of autocompleted values:*/
                                closeAllLists();
                            });
                            a.appendChild(b);
                        }
                    }
                });
                /*execute a function presses a key on the keyboard:*/
                inp.addEventListener("keydown", function (e) {
                    var x = document.getElementById(this.id + "autocomplete-list");
                    if (x) x = x.getElementsByTagName("div");
                    if (e.keyCode == 40) {
                        /*If the arrow DOWN key is pressed,
                        increase the currentFocus variable:*/
                        currentFocus++;
                        /*and and make the current item more visible:*/
                        addActive(x);
                    } else if (e.keyCode == 38) { //up
                        /*If the arrow UP key is pressed,
                        decrease the currentFocus variable:*/
                        currentFocus--;
                        /*and and make the current item more visible:*/
                        addActive(x);
                    } else if (e.keyCode == 13) {
                        /*If the ENTER key is pressed, prevent the form from being submitted,*/
                        e.preventDefault();
                        if (currentFocus > -1) {
                            /*and simulate a click on the "autocompleteExpressionactive" item:*/
                            if (x) x[currentFocus].click();
                        }
                    }
                });
                function addActive(x) {
                    /*a function to classify an item as "active":*/
                    if (!x) return false;
                    /*start by removing the "active" class on all items:*/
                    removeActive(x);
                    if (currentFocus >= x.length) currentFocus = 0;
                    if (currentFocus < 0) currentFocus = (x.length - 1);
                    /*add class "autocomplete-active":*/
                    x[currentFocus].classList.add("autocomplete-active");
                }
                function removeActive(x) {
                    /*a function to remove the "active" class from all autocomplete items:*/
                    for (var i = 0; i < x.length; i++) {
                        x[i].classList.remove("autocomplete-active");
                    }
                }
                function closeAllLists(elmnt) {
                    /*close all autocomplete lists in the document,
                    except the one passed as an argument:*/
                    var x = document.getElementsByClassName("autocomplete-items");
                    for (var i = 0; i < x.length; i++) {
                        if (elmnt != x[i] && elmnt != inp) {
                            x[i].parentNode.removeChild(x[i]);
                        }
                    }
                }
                /*execute a function when someone clicks in the document:*/
                document.addEventListener("click", function (e) {
                    closeAllLists(e.target);
                });
            }

            if (autoSuggestedAttributeValues[0][selectedValue] != undefined) {
                autocompleteExpression(document.getElementById("expression" + id), autoSuggestedAttributeValues[0][selectedValue]);
            }

        });
    }

    addCaseAccessExpression = (optionList) => {
        const { caseAccessExpressions, selectedWorkflowId } = this.state;
        let isCondition="";
        if(caseAccessExpressions.length>1){
            isCondition=$("#condition"+caseAccessExpressions[0].expressionId).val();
        }
        _.forEach(caseAccessExpressions, function (obj) {
            if (isCondition !== "") {
                if (obj.condition !== "" && obj.condition !== isCondition) {
                    obj.condition = isCondition;
                }
            }
            let optionValue = $('#attribute' + obj.expressionId).val();
            let option = _.find(optionList, { attribute: optionValue });
            if (option !== undefined && option !== null && option !== "") {
                obj.type = option.type
            }
        }
        );
        let newExpression = {
            id: null,
            expressionId: 1,
            attribute: "",
            operator: "eq",
            value: "",
            workflowId: selectedWorkflowId,
            condition: "",
            type: ""
        };

        var latestExpression = caseAccessExpressions[caseAccessExpressions.length - 1];

        if (latestExpression != undefined) {
            newExpression.expressionId = latestExpression.expressionId + 1
        }

        caseAccessExpressions.push(newExpression);
        this.setState({
            caseAccessExpressions: caseAccessExpressions,
            oldCondition: isCondition,
            selectedValue: ""
        });
    };

    addEnableCaseAccessExpression  = (optionList) => {
        const { enableCaseAccessExpressions, selectedWorkflowId } = this.state;
        let isCondition="";
        if(enableCaseAccessExpressions.length>1){
            isCondition=$("#enableCondition"+enableCaseAccessExpressions[0].expressionId).val();
        }
        _.forEach(enableCaseAccessExpressions, function (obj) {
            if (isCondition !== "") {
                if (obj.condition !== "" && obj.condition !== isCondition) {
                    obj.condition = isCondition;
                }
            }
            let optionValue = $('#enableAttribute' + obj.expressionId).val();
            let option = _.find(optionList, { attribute: optionValue });
            if (option !== undefined && option !== null && option !== "") {
                obj.type = option.type
            }
        }
        );
        let newExpression = {
            id: null,
            expressionId: 1,
            attribute: "",
            operator: "eq",
            value: "",
            workflowId: selectedWorkflowId,
            condition: "",
            type: ""
        };

        var latestExpression = enableCaseAccessExpressions[enableCaseAccessExpressions.length - 1];

        if (latestExpression != undefined) {
            newExpression.expressionId = latestExpression.expressionId + 1
        }

        enableCaseAccessExpressions.push(newExpression);
        this.setState({
            enableCaseAccessExpressions: enableCaseAccessExpressions,
            enableOldCondition: isCondition,
            selectedValue: ""
        });
    };  

    openCustomizeDisplayConditionWindow = () => {
        $(function () {
            $('#modal-case-action').modal('hide');
            $('#modal-customize-case-access').modal('show');
            $('#modal-customize-case-access').css('overflow-y', 'auto');
        });
    }

    closeCustomizeDisplayConditionWindow = () => {
        $(function () {
            $('#modal-customize-case-access').modal('hide');
            $('#modal-case-action').modal('show');
            $('#modal-case-action').css('overflow-y', 'auto');
        });
    }

    removeCaseAccessExpression = idx => {
        const { caseAccessExpressions } = this.state;
        caseAccessExpressions.splice(idx, 1);
        let isCondition="";
        if(caseAccessExpressions.length>1){
            isCondition=$("#condition"+caseAccessExpressions[0].expressionId).val();
        }
        if (isCondition !== "") {
            _.forEach(caseAccessExpressions, function (obj) {
                if (obj.condition !== "" && obj.condition !== isCondition) {
                    obj.condition = isCondition;
                }
            }
            );
        }
        this.setState({
            caseAccessExpressions: caseAccessExpressions,
            oldCondition: isCondition
        });
    };

    removeEnableCaseAccessExpression = idex => {
        const { enableCaseAccessExpressions } = this.state;
        enableCaseAccessExpressions.splice(idex, 1);
        let isCondition="";
        if(enableCaseAccessExpressions.length>1){
            isCondition=$("#enableCondition"+enableCaseAccessExpressions[0].expressionId).val();
        }
        if (isCondition !== "") {
            _.forEach(enableCaseAccessExpressions, function (obj) {
                if (obj.condition !== "" && obj.condition !== isCondition) {
                    obj.condition = isCondition;
                }
            }
            );
        }
        this.setState({
            enableCaseAccessExpressions: enableCaseAccessExpressions,
            enableOldCondition: isCondition
        });
    };


    changeCondition = event => {
        var id = event.target.id;
        var currentValue = $('#' + id).attr('value');
        if (currentValue == "AND") {
            $('#' + id).attr('value', 'OR');
            $('#' + id).html('OR');
        } else {
            $('#' + id).attr('value', 'AND');
            $('#' + id).html('AND');
        }
    }

    generateAttributeName = (attribute) => {
        if (attribute.includes("metadata") || attribute.includes("auditInfo")) {
            var valueArray = attribute.split(".");
            if (valueArray[1] != undefined && valueArray[1] != null) {
                return valueArray[1];
            } else {
                return attribute;
            }
        } else {
            return attribute;
        }
    }

    generateSortedAttributeList =(workflowAttributesTemp)=> {
        let workflowAttributesSorted = workflowAttributesTemp.map(option=> {
            let attribName = this.generateAttributeName(option.attribute);
            if (
                attribName.includes("sessionRef") ||
                attribName.includes("workflowRef") ||
                attribName.includes("worker") ||
                attribName.includes("Attachments") ||
                attribName.includes("Assign Worker") ||
                attribName.includes("sessionRef") ||
                attribName.includes("worker") ||
                attribName.includes("workflowRef") ||
                attribName.includes("workflowId") ||
                attribName.includes("workflowName") ||
                attribName.includes("workflowVersion") ||
                attribName.includes("baseURL") ||
                attribName.includes("continuationURL") ||
                attribName.includes("stateName") ||
                attribName.includes("stateType") ||
                attribName.includes("caseState")) {
                return {
                    id: option.id,
                    attribute: option.attribute,
                    attributeName: `${attribName} (default)`,
                    type: option.type
                }
            } else {
                return {
                    id: option.id,
                    attribute: option.attribute,
                    attributeName: attribName,
                    type: option.type
                }
            }
            })
        workflowAttributesSorted.sort((a,b) => a.attributeName.localeCompare(b.attributeName))
        return workflowAttributesSorted;
    }

    handleSaveExpression = () => {
        const { caseInfoRecords, isCaseInfoEdit, selectedCaseInfo, caseAccessExpressions,enableCaseAccessExpressions, selectWorkflow, selectedWorkflowId,workflowAttributes } = this.state;
        let newExData = [];
        caseAccessExpressions.forEach(item => {
            let value="";
            if(item.type.toUpperCase()==="DATE"){
                value=item.value;
            }else{
                value=$('#expression'+item.expressionId).val();
            }
            let savedAttribute = $('#attribute' + item.expressionId).val();
            let workflowAttribute = _.find(workflowAttributes, { attribute: savedAttribute });
            if (workflowAttribute !== undefined && workflowAttribute !== null && workflowAttribute !== "") {
                item.type = workflowAttribute.type;
            }
            
            let record = {
                id: item.id,
                expressionId: item.expressionId,
                attribute: $('#attribute' + item.expressionId).val(),
                operator: $('#operator' + item.expressionId).val(),
                value: value,
                workflowId: selectedWorkflowId,
                condition: $('#condition' + item.expressionId).val(),
                expressionType:'display',
                type:item.type
            };
            newExData.push(record);
        })

        enableCaseAccessExpressions.forEach(item => {
            let value="";
            if(item.type.toUpperCase()==="DATE"){
                value=item.value;
            }else{
                value=$('#enableExpression'+item.expressionId).val();
            }
            let savedAttribute = $('#enableAttribute' + item.expressionId).val();
            let workflowAttribute = _.find(workflowAttributes, { attribute: savedAttribute });
            if (workflowAttribute !== undefined && workflowAttribute !== null && workflowAttribute !== "") {
                item.type = workflowAttribute.type;
            }
            
            let record = {
                id: item.id,
                expressionId: item.expressionId,
                attribute: $('#enableAttribute' + item.expressionId).val(),
                operator: $('#enableOperator' + item.expressionId).val(),
                value: value,
                workflowId: selectedWorkflowId,
                condition: $('#enableCondition' + item.expressionId).val(),
                expressionType:'enable',
                type:item.type,
                information:$('#information').val()
            };
            newExData.push(record);
        })

        let record = {
            id: null,
            title: $('#title').val(),
            order: caseInfoRecords.length + 1,
            workflowId: selectedWorkflowId,
            caseExpressions: newExData
        }
        var newData = [];
        if (isCaseInfoEdit) {
            caseInfoRecords.forEach(item => {
                let add = null;
                if (item.field != selectedCaseInfo) {
                    add = {
                        id: item.id,
                        title: item.title,
                        field: item.field,
                        order: item.order,
                        workflowId: item.workflowId,
                        caseExpressions: item.caseExpressions
                    };
                } else {
                    record.id = item.id;
                    record.order = item.order;
                    add = record;
                }

                newData.push(add);
            })
            this.setState({
                caseInfoRecords: newData,
                isCaseInfoEdit: false
            });
        } else {
            if ($('#title').val()) {
                if (!_.find(caseInfoRecords, { title: $('#title').val() })) {
                    newData = [record];
                    this.setState({
                        caseInfoRecords: newData
                    });
                }
            }
        }

        $('#modal-customize-case-access').modal('hide');
        $('#modal-case-action').modal('show');
        $('#modal-case-action').css('overflow-y', 'auto');
    }

    resetCaseInfoForm = () => {
        const { isCaseInfoEdit, t } = this.state;
        if (isCaseInfoEdit) {
            $("#caseActionInfoSave").html(t("common.add"));
            this.setState({
                isCaseInfoEdit: false,
                selectedCaseInfo: null,
                caseAccessExpressions: []
            });
        }
        $('#modal-customize-case-access').find('form').trigger('reset');
    }

    changeInformation = event => {
        let { name, value } = event.target;
        this.setState({
            information: value
        });
    }

validationSaveExpression = () => {    
    const {  caseAccessExpressions,enableCaseAccessExpressions,workflowAttributes } = this.state;
    let errorMsg="";
    let isValidate=true;
    caseAccessExpressions.forEach(item => {
        let value;
        const regexInteger = /^[+-]?\d+$/;
        const regexDecimal = /^[+-]?\d+(\.\d+)?$/;
        if(item.type.toUpperCase()==="DATE"){
            value=item.value;
        }else{
            value=$('#expression'+item.expressionId).val();
        }
        let savedAttribute = $('#attribute' + item.expressionId).val();
        let workflowAttribute = _.find(workflowAttributes, { attribute: savedAttribute });
        if (workflowAttribute !== undefined && workflowAttribute !== null && workflowAttribute !== "") {
            item.type = workflowAttribute.type;
            if(value !=null){                   
                var valueType="";                    
                if (regexInteger.test(value)) {
                    valueType = 'integer'; 
                  } else if (regexDecimal.test(value)) {
                    valueType = 'decimal';  
                  } 
                if(item.type.toLowerCase()=='integer' ||item.type.toLowerCase()=='decimal'){
                    if(valueType!==item.type.toLowerCase()){
                        isValidate=false; 
                        errorMsg=item.type.toLowerCase();
                    }                         
                }       
            }   
        }  
    })
    enableCaseAccessExpressions.forEach(item => {
        let value;
        const regexInteger = /^[+-]?\d+$/;
        const regexDecimal = /^[+-]?\d+(\.\d+)?$/;
        if(item.type.toUpperCase()==="DATE"){
            value=item.value;
        }else{
            value=$('#enableExpression'+item.expressionId).val();
        }
        let savedAttribute = $('#enableAttribute' + item.expressionId).val();
        let workflowAttribute = _.find(workflowAttributes, { attribute: savedAttribute });
        if (workflowAttribute !== undefined && workflowAttribute !== null && workflowAttribute !== "") {
            item.type = workflowAttribute.type;
            if(value !=null){                   
                var valueType="";                    
                if (regexInteger.test(value)) {
                    valueType = 'integer'; 
                  } else if (regexDecimal.test(value)) {
                    valueType = 'decimal';  
                  } 
                if(item.type.toLowerCase()=='integer' ||item.type.toLowerCase()=='decimal'){
                    if(valueType!==item.type.toLowerCase()){
                        isValidate=false; 
                        errorMsg=item.type.toLowerCase();
                    }                         
                }       
            }   
        }  
    })
    if(isValidate){
        this.handleSaveExpression();
    }else{
        const { t } = this.state;           
        $.notification("error", t("common.notification.error"), t("common.validations."+errorMsg));
    }
}

    render() {
        const { t } = this.props;
        const { stateData, columns, data, isEdit, records, caseActions, errors, handleRecords, predefinedActionList, workflowList,
            caseAccessExpressions,enableCaseAccessExpressions, workflowAttributes,oldCondition,enableOldCondition,selectedValue,
            enableCondition,information, isDisplayPredefinedActions, isDisplayWorkflowLink} = this.state;
        const deleteModalParams = { t }
        const actionDetailsParams = {t, stateData }
        const caseStateActionModalParams = { t, isEdit, errors, stateData, caseActions, handleRecords, predefinedActionList, workflowList, isDisplayPredefinedActions, isDisplayWorkflowLink }

        var attributesSorted = this.generateSortedAttributeList(workflowAttributes)
        const caseExpressionParams = { t, caseAccessExpressions,enableCaseAccessExpressions, workflowAttributes, attributesSorted,oldCondition,enableOldCondition,selectedValue,enableCondition,information};
      
        return (
            <Fragment>
                <div className="content-wrapper portal-admin admin-case-actions">
                    <section className="content-header">
                        <h1>
                            {t("caseManagement.caseActions.title")} <small />
                        </h1>
                    </section>

                    <section className="content">
                        <CommonTable rowClick={this.onRowClick} columns={columns} data={data} t={t} />

                        <CaseActionModal
                            params={caseStateActionModalParams}
                            generateTitle={this.generateTitle}
                            changeType={this.changeType}
                            typeSelected={this.state.typeSelected}
                            handleChange={this.handleChange}
                            handleCaseActionTypeChange={this.handleCaseActionTypeChange}
                            openCustomizeDisplayConditionWindow={this.openCustomizeDisplayConditionWindow}
                            handleSave={this.handleSave}
                        />

                        <div className="row">

                            <ActionDetails
                                params={actionDetailsParams}
                            />

                            <div className="col-xs-4 right">
                                <div className="box">
                                    <div className="box-header">
                                        <div className="box-title">
                                            <p className="lead" style={{ marginBottom: 0 }}>{t("caseManagement.caseActions.actions.title")}</p>
                                        </div>
                                    </div>
                                    <div className="box-body">
                                        {!isEdit && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.openModal();
                                                }}
                                            >
                                                <span
                                                    className="fa fa-plus"
                                                    data-toggle="tooltip"
                                                    title={t("common.add")}
                                                />
                                                {t("common.add")}
                                            </a>
                                        )}
                                        {stateData &&
                                            <CommonActionButton
                                                params={this.props.t}
                                                reverseOnRowClick={this.reverseOnRowClick}
                                                openModal={this.openModal}
                                            />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DeleteModal
                            type="action"
                            id="caseStateDeleteAlert"
                            params={deleteModalParams}
                            stateDataId={stateData?.id}
                            stateDataName={stateData?.name}
                            deleteFunction={this.deleteCaseState}
                            message="caseManagement.caseActions.deleteWindow.body"
                        />

                    </section>
                </div>

                <CaseExpression params={caseExpressionParams}
                    addCaseAccessExpression={this.addCaseAccessExpression}
                    removeCaseAccessExpression={this.removeCaseAccessExpression}
                    handleSaveExpression={this.handleSaveExpression}      
                    validationSaveExpression={this.validationSaveExpression}                       
                    changeAttribute={this.changeAttribute}
                    openSpecifyWindow={this.openModalAfterExpression}
                    handleDateChange={this.handleDateChange}
                    title={t("caseManagement.caseActions.tabMenu.windowTitle")}
                    addEnableCaseAccessExpression={this.addEnableCaseAccessExpression}
                    removeEnableCaseAccessExpression={this.removeEnableCaseAccessExpression}
                    enableHandleDateChange={this.enableHandleDateChange}   
                    enableChangeAttribute={this.enableChangeAttribute}
                    changeInformation={this.changeInformation}
                />
            </Fragment>
        );
    }
}

export default withTranslation("common")(SettingsCaseStateActionPage);