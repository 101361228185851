import React, { Component, Fragment } from "react";
import AllUsers from "../components/users/AllUsers";
import AddUser from "../components/users/AddUser";
import NoUsers from "../components/users/NoUsers";
import { withTranslation } from "react-i18next";

class UsersPage extends Component {
  showUsers(state) {
    if (state == null) {
      return <NoUsers />;
    }

    // TODO: handle other restrictions here
    let showAll = true;
    let showAdd = true;
    let showEdit = true;

    switch (state.state) {
      case "view":
        return showAll ? <AllUsers params={state} /> : <NoUsers />;
      case "add":
        return showAdd ? <AddUser params={state} /> : <NoUsers />;
      case "edit":
        return showEdit ? <AddUser params={state} /> : <NoUsers />;
      default:
        return null;
    }
  }

  render() {
    let state = this.props.location.state;
    const { t } = this.props;
    const className = state && state.state === "view" ? "content-wrapper user-admin view-user-list" : "content-wrapper user-admin add-edit-user";

    return (
      <Fragment>
        <div className={className}>
          <section className="content-header">
            <h1>
              {t("userManagement.title")} <small />
            </h1>
          </section>
          {this.showUsers(state)}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(UsersPage);
