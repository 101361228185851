import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import { ReactTabulator } from "react-tabulator";
/*
*  Future task development
*/
class SettingsUserRolePage extends Component {
    ref = React.createRef();

    state = {
        stateData : null,
        t : this.props.t,
        columns: [
            { title: "Navn", field: "name" },
            { title: "Beskrivelse", field: "description" }
        ],
        data: [
            {"name":"PORTAL_ADMIN","description":this.props.t("roles.PORTAL_ADMIN")},
            {"name":"CASE_MANAGER","description":this.props.t("roles.CASE_MANAGER")},
            {"name":"CASE_SUPERVISOR","description":this.props.t("roles.CASE_SUPERVISOR")},
            {"name":"READER","description":this.props.t("roles.READER")},
            {"name":"INSPECTOR","description":this.props.t("roles.INSPECTOR")}],
        options: {
            pagination: "local",
            paginationSize: 10,
            langs: {
                all: {
                    pagination: {
                        first: this.props.t("common.table.pagination.first"),
                        first_title: this.props.t("common.table.pagination.first_title"),
                        last: this.props.t("common.table.pagination.last"),
                        last_title: this.props.t("common.table.pagination.last_title"),
                        prev: this.props.t("common.table.pagination.prev"),
                        prev_title: this.props.t("common.table.pagination.prev_title"),
                        next: this.props.t("common.table.pagination.next"),
                        next_title: this.props.t("common.table.pagination.next_title")
                    }
                }
            }
        },
        isEdit: false
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ columns: nextProps.columns, data: nextProps.data, t: nextProps.t });
    }

    rowClick = (e, row) => {
        this.ref.table.deselectRow();
        this.ref.table.selectRow(row);
        this.onRowClick(row.getData());
    };

    onRowClick = stateData => {
        this.setState({
            stateData: stateData,
            isEdit: true
        });
    };

    setRef(ref) {
        if (ref) {
            this.ref = ref;
            this.ref.table.setLocale("all");
        }
    }

    render() {
        const { t } = this.props;
        const { stateData, columns, data, options, isEdit } = this.state;

        return (
            <Fragment>
                <div className="content-wrapper">
                    <section className="content-header">
                        <h1>
                            {t("portalSettings.userRoles.title")} <small />
                        </h1>
                    </section>

                    <section className="content">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="box">
                                    <div className="box-body">
                                        {data.length > 0 && (
                                            <ReactTabulator
                                                ref={ref => (this.setRef(ref))}
                                                columns={columns}
                                                data={data}
                                                rowClick={this.rowClick}
                                                options={options}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div className="modal fade" id="modal-default">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">{t("portalSettings.userRoles.add.title")}</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="name">
                                                {t("portalSettings.userRoles.add.name")}
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="description">
                                                {t("portalSettings.userRoles.add.description")}
                                            </label>
                                            <input
                                                type="text"
                                                name="description"
                                                className="form-control"
                                                id="description"
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default pull-left"
                                                data-dismiss="modal">{t("common.close")}
                                        </button>
                                        <button type="button" className="btn btn-default">{t("common.save")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">

                            <div className="col-xs-8">
                                {stateData && (
                                    <div className="box">
                                        <div className="box-header">
                                            <p className="lead">
                                                {t("portalSettings.userRoles.details.title")}
                                            </p>
                                        </div>
                                        <div className="box-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <tbody>
                                                    <tr key="name">
                                                        <th> {t("portalSettings.userRoles.details.name")}:</th>
                                                        <td>{stateData.name}</td>
                                                    </tr>
                                                    <tr key="description">
                                                        <th> {t("portalSettings.userRoles.details.description")}:</th>
                                                        <td>{stateData.description}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>


                            <div className="col-xs-4 right">
                                <div className="box">
                                    <div className="box-header">
                                        <div className="box-title">
                                            <p className="lead" style={{marginBottom:0}}>
                                                {t("portalSettings.userRoles.actions.title")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="box-body">
                                        {!isEdit && (
                                        <a
                                            href="#/"
                                            className="btn btn-app"
                                            data-toggle="modal"
                                            data-target="#modal-default"
                                        >
                                              <span
                                                  className="fa fa-plus"
                                                  data-toggle="tooltip"
                                                  title="Add"
                                              />
                                            {t("portalSettings.userRoles.actions.add")}
                                        </a>
                                        )}
                                        {stateData && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                data-toggle="modal"
                                                data-target="#modal-default"
                                            >
                                                <i
                                                    className="fa fa-edit"
                                                    data-toggle="tooltip"
                                                    title="Edit"
                                                />
                                                {t("portalSettings.userRoles.actions.edit")}
                                            </a>
                                        )}

                                        {stateData && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                data-toggle="modal"
                                                data-target="#userDeleteAlert"
                                            >
                                              <span
                                                  className="fa fa-remove"
                                                  data-toggle="tooltip"
                                                  title={t("common.delete")}
                                              />
                                                {t("common.delete")}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}
export default withTranslation("common")(SettingsUserRolePage);