import ApiService from "../auth/ApiService";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured";

const CaseHistoryAPI = {
    get: async function(uniqueCaseId) {
        let response = await new ApiService().callApi(
            `${resourceUrl}/case_history/${uniqueCaseId}`,
            "get"
        );
        return response.data;
    },

    save: async function(caseHistory) {
        let data = await new ApiService().callApi(`${resourceUrl}/case_history`, "post", caseHistory);
        return data.data;
    },
};

export default CaseHistoryAPI;