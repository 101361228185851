import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const cmpLocaleBranch = window.ENV.REACT_APP_UI_LOCALES_BRANCH;
const lang = window.ENV.REACT_APP_LANG;

i18n.use(initReactI18next)
    .init({
      lng: lang,
      resources: {
        en: {
          common : require(`./locales/${cmpLocaleBranch}.en.json`)
        },
        no: {
          common: require(`./locales/${cmpLocaleBranch}.no.json`)
        }
      }
    });

i18n.languages = ['en', 'no'];
export default i18n;
