import React, { Component, Fragment } from 'react'
import { withTranslation } from "react-i18next";

export class UserGroupActionPanel extends Component {
    render() {
        const { t } = this.props;
        const {isEdit,stateData} = this.props.params;
        return (
            <Fragment>
                <div className="modal fade" id="userGroupDeleteAlert">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title">{isEdit && ( t("common.delete") )} {t("portalSettings.userGroups.add.title")}</h4>
                            </div>
                            <div className="modal-body">
                                <p>{t("portalSettings.userGroups.delete.message")}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary col-md-2 pull-right"
                                    data-dismiss="modal"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.deleteUserGroup(stateData.id);
                                    }}
                                    style={{ marginLeft: 10 }}
                                >
                                    {t("common.ok")}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-default col-md-2 pull-right"
                                    data-dismiss="modal"
                                >
                                    {t("common.cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="box">
                    <div className="box-header">
                        <div className="box-title">
                            <p className="lead" style={{marginBottom:0}}>
                                {t("portalSettings.userGroups.actions.title")}
                            </p>
                        </div>
                    </div>
                    <div className="box-body">
                        <a
                            href="#/"
                            className="btn btn-app"
                            data-toggle="modal"
                            data-target="#modal-user-group"
                            onClick={e => {
                                e.preventDefault();
                                isEdit && this.props.reverseOnRowClick();
                            }}
                        >
                                <span
                                    className="fa fa-plus"
                                    data-toggle="tooltip"
                                    title="Add"
                                />
                            {t("portalSettings.userGroups.actions.add")}
                        </a>
                        {stateData && (
                            <a
                                href="#/"
                                className="btn btn-app"
                                data-toggle="modal"
                                data-target="#modal-user-group"
                            >
                                <i
                                    className="fa fa-edit"
                                    data-toggle="tooltip"
                                    title="Edit"
                                />
                                {t("portalSettings.userGroups.actions.edit")}
                            </a>
                        )}

                        {stateData && (
                            <a
                                href="#/"
                                className="btn btn-app"
                                data-toggle="modal"
                                data-target="#userGroupDeleteAlert"
                            >
                                <span
                                    className="fa fa-remove"
                                    data-toggle="tooltip"
                                    title={t("common.delete")}
                                />
                                {t("common.delete")}
                            </a>
                        )}
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default withTranslation("common")(UserGroupActionPanel);