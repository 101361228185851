import ApiService from "../auth/ApiService";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/user";

const UserDetailsAPI = {
  get: async function(uniqueId) {
    let data = await new ApiService().callApi(
      `${resourceUrl}/${uniqueId}`,
      "get"
    );
    return data.data;
  }
};

export default UserDetailsAPI;
