import React, { Component } from 'react'
import { CommonContext } from '../../context/CommonContext';
const $ = window.$;
export class WorkflowModal extends Component {
    static contextType = CommonContext;

    setAttributeValue(value) {
        $("#exercises option[value='" + value + "']").attr("selected", "selected");
        $('#exercises').trigger("change");
    }

    render() {
        const { t, errors, isEdit, workflowData, records, caseStates, exercises, exerciseRecords, workflowAttributes, selectedAttributeName } = this.props.params;
        var attributesSorted = this.props.generateSortedAttributeList(workflowAttributes);

        return (
            <div className="modal fade" id="modal-workflow">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">{isEdit && (t("common.edit")) || (t("common.add"))} {t("portalSettings.workflows.actions.add.title")}</h4>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className={
                                    errors.workflowId ? "form-group has-error" : "form-group"
                                }>
                                    <label htmlFor="workflowId">
                                        {t("portalSettings.workflows.fields.workflowId")}
                                    </label>
                                    <input
                                        type="text"
                                        name="workflowId"
                                        className="form-control"
                                        id="workflowId"
                                        defaultValue={workflowData && isEdit && (workflowData.workflowId)}
                                        onChange={this.props.handleChange}
                                        placeholder={t(
                                            "portalSettings.workflows.placeholder.workflowId"
                                        )}
                                    />
                                    {errors.workflowId && (
                                        <span className="help-block">{errors.workflowId}</span>
                                    )}
                                </div>

                                <div className={
                                    errors.name ? "form-group has-error" : "form-group"
                                }>
                                    <label htmlFor="name">
                                        {t("portalSettings.workflows.fields.name")}
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        defaultValue={workflowData && isEdit && (workflowData.name)}
                                        onChange={this.props.handleChange}
                                        placeholder={t(
                                            "portalSettings.workflows.placeholder.name"
                                        )}
                                    />
                                    {errors.name && (
                                        <span className="help-block">{errors.name}</span>
                                    )}
                                </div>

                                <div className={
                                    errors.description ? "form-group has-error" : "form-group"
                                }>
                                    <label htmlFor="description">
                                        {t("portalSettings.workflows.fields.description")}
                                    </label>
                                    <input
                                        type="text"
                                        name="description"
                                        className="form-control"
                                        id="description"
                                        defaultValue={workflowData && isEdit && (workflowData.description)}
                                        onChange={this.props.handleChange}
                                        placeholder={t(
                                            "portalSettings.workflows.placeholder.description"
                                        )}
                                    />
                                    {errors.description && (
                                        <span className="help-block">{errors.description}</span>
                                    )}
                                </div>

                                <div className={
                                    errors.icon ? "form-group has-error" : "form-group"
                                }>
                                    <label htmlFor="icon">
                                        {t("portalSettings.workflows.fields.icon")}
                                    </label>
                                    <input
                                        type="text"
                                        name="icon"
                                        className="form-control"
                                        id="icon"
                                        defaultValue={workflowData && isEdit && (workflowData.icon)}
                                        onChange={this.props.handleChange}
                                        placeholder={t(
                                            "portalSettings.workflows.placeholder.icon"
                                        )}
                                    />
                                    {errors.icon && (
                                        <span className="help-block">{errors.icon}</span>
                                    )}
                                </div>

                                <div className="input-group" style={{ marginBottom: 15 }}>
                                    <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                        <label>
                                            {t("portalSettings.workflows.fields.states.select")}
                                        </label>
                                        <select
                                            className="form-control"
                                            id="caseState"
                                            name="caseState"
                                            onChange={this.props.handleWorkflowChange}>
                                            <option key="0" value="none">{t("portalSettings.workflows.fields.states.option")}</option>
                                            {caseStates &&
                                                caseStates.map(option => {
                                                    return (
                                                        <option key={option.id} value={option.id}>
                                                            {this.props.generateTitle("caseManagement.viewDetails.stateType", option.name, option.name)}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <span
                                        className="input-group-btn"
                                        style={{ paddingTop: 25 }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-md btn-default"
                                            onClick={this.props.addRecord}
                                        >
                                            <span className="glyphicon glyphicon-plus"></span>
                                        </button>
                                    </span>
                                </div>

                                {records.length > 0 && (
                                    <div className="form-group" style={{ marginTop: 30 }}>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>{t("portalSettings.workflows.fields.states.table.name")}</th>
                                                    <th>{t("portalSettings.workflows.fields.states.table.description")}</th>
                                                    <th style={{ width: 110 }}>
                                                        {t("portalSettings.workflows.fields.states.table.action")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {records.length > 0 && records.sort((a, b) => a.order > b.order ? 1 : -1).map((record, idx) => (
                                                    <tr key={record.id}>
                                                        <td>{this.props.generateTitle("caseManagement.viewDetails.stateType", record.name, record.name)}</td>
                                                        <td>{this.props.generateTitle("caseManagement.viewDetails.stateType", record.description, record.description)}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                title="Move Up"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.moveUpCaseInfoRecord("case_states", record);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-arrow-up"></i></span>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                style={{ marginRight: 10 }}
                                                                title="Move Down"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.moveDownCaseInfoRecord("case_states", record);
                                                                }}
                                                            >
                                                                <span><i className="fa fa-arrow-down"></i></span>
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-default btn-xs"
                                                                title="Delete"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.deleteRecord(idx);
                                                                }}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                <div className="checkbox">
                                    <label htmlFor="securePublishing">
                                        <input
                                            type="checkbox"
                                            name="securePublishing"
                                            id="securePublishing"
                                            defaultChecked={workflowData && (workflowData.securePublishing === "Y" ? true : false)}
                                            onClick={this.props.handleChange}
                                        />
                                        {t("portalSettings.workflows.fields.securePublishing")}
                                    </label>
                                </div>

                                {workflowData && (
                                    <div className="checkbox">
                                        <label htmlFor="segmentationWorkflow">
                                            <input
                                                type="checkbox"
                                                name="segmentationWorkflow"
                                                id="segmentationWorkflow"
                                                defaultChecked={workflowData && isEdit && (workflowData.segmentationWorkflow === "Y" ? true : false)}
                                                onClick={this.props.handleSegmentationChange}
                                            />
                                            {t("portalSettings.workflows.fields.segmentation.initialCheck")}
                                        </label>
                                    </div>
                                )}

                                {workflowData && workflowData.segmentationWorkflow === "Y" && (
                                    <div className="input-group">
                                        <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                            <label>
                                                {t("portalSettings.workflows.fields.segmentation.attributeTitle")}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="exercises"
                                                name="exercises"
                                                onChange={this.props.handleSegmentedAttributeChange}
                                                defaultValue={workflowData && isEdit && (selectedAttributeName)}>
                                                <option key="0" value="none">{t("portalSettings.workflows.fields.segmentation.attributeInitialOption")}</option>

                                                {attributesSorted &&
                                                    attributesSorted.map(option => {
                                                        return (
                                                            <option key={"SEG"+option.id} value={option.attribute}>
                                                                {option.attributeName}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                )}
                                {this.setAttributeValue(selectedAttributeName)}
                                {workflowData && workflowData.segmentationWorkflow === "Y" && (
                                    <div className="input-group">
                                        <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                            <label>
                                                {t("portalSettings.workflows.fields.segmentation.inputTitle")}
                                            </label>
                                            <input
                                                type="text"
                                                name="subflow"
                                                className="form-control"
                                                id="subflow"
                                                onChange={this.props.handleChange}
                                                placeholder={t(
                                                    "portalSettings.workflows.fields.segmentation.inputTitle"
                                                )}
                                            />
                                        </div>
                                        <span
                                            className="input-group-btn"
                                            style={{ paddingTop: 25 }}
                                        >
                                                        <button
                                                            type="button"
                                                            className="btn btn-md btn-default"
                                                            onClick={this.props.addSegmentationRecord}
                                                        >
                                                          <span className="glyphicon glyphicon-plus"></span>
                                                        </button>
                                                    </span>

                                    </div>
                                )}

                                {exerciseRecords.length > 0 && (workflowData.segmentationWorkflow === "Y" ? true : false) && (
                                    <div className="form-group" style={{ marginTop: 30 }}>
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>{t("portalSettings.workflows.fields.segmentation.tableFirstColumn")}</th>
                                                <th style={{ width: 10 }}>
                                                    {t("portalSettings.workflows.fields.segmentation.tableSecondColumn")}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {exerciseRecords.length > 0 &&
                                                exerciseRecords.map((record, idx) => (
                                                    <tr key={record.code}>
                                                        <td>{record.code}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-block btn-danger"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.deleteExerciseRecord(idx);
                                                                }}
                                                            >
                                                                {t("common.remove")}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default pull-left"
                                data-dismiss="modal">{t("common.close")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={(event) => {
                                    this.props.handleSave(event);
                                    this.context.handleSideMenuUpdate(true);
                                }}
                            >{t("common.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkflowModal