import React, { Component } from "react";
import AuthUtil from "../utils/AuthUtil";
import ConfigAPI from "../api/ConfigAPI";
import LoginPageHeaderSection from "../sections/LoginPageHeaderSection";
import LoginPageFooterSection from "../sections/LoginPageFooterSection";
import properties from '../version.properties'
import './LoginPage.css'
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import ToggleSwitch from './ToggleSwitch';

class LoginPage extends Component {
  state = {
    locale: null,
    loginConfig:null,
    t: this.props.t,
    isToggled: (window.ENV.REACT_APP_UI_LOCALES === "en") ? false : true,
    isMultipleAuthClientsEnabled: window.ENV.REACT_APP_MULTIPLE_AUTH_CLIENTS_ENABLED,
    loginEmail: '',
    errors: {}
  };

  constructor(props) {
    super(props);
    this.onLoginButtonClick = this.onLoginButtonClick.bind(this);
  }

  componentWillMount() {
    ConfigAPI.getLocale(window.ENV.REACT_APP_UI_LOCALES).then(data => {
      this.setState({
        locale: data.loginPage,
      });
    });

    ConfigAPI.getConfig().then(data => {
      let { t } = this.state;
      this.setState({
        loginConfig: data.loginPage,
      });      
    });
    this.selectLanguage(this.state.isToggled);
  }

  isFormValid = () => {
    let {loginEmail, t} = this.state;
    const errors = {};

    if (!loginEmail)
      errors.loginEmail = t("userManagement.addUser.validationError.EMAIL_REQUIRED");
    if (!/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/.test(loginEmail)) {
      errors.loginEmail = t("userManagement.addUser.validationError.EMAIL_INVALID");
    }      

    this.setState({
      errors: errors
    });
    return Object.keys(errors).length === 0;
  };

  onLoginButtonClick(event) {
    event.preventDefault();
    let {isMultipleAuthClientsEnabled, loginEmail, errors, t} = this.state;

    if(isMultipleAuthClientsEnabled){
      if(this.isFormValid()){
        AuthUtil.loginWithEmail(loginEmail,this.setState.bind(this), errors, t);
      }
    } else {
      AuthUtil.login();
    }
    
  };

  getYear() {
    return new Date().getFullYear();
  }

  languageChangeHandler = (event, lang) => {
    i18n.changeLanguage(lang);
  }

  handleToggle = () => {
    this.setState(prevState => ({
      isToggled: !prevState.isToggled
    }));

    let langState = false;
    if (this.state.isToggled == true) {
      langState = false;
    } else {
      langState = true;
    }

    this.selectLanguage(langState);
  };

  selectLanguage(toggleState) {
    if (toggleState == true) {
      this.languageChangeHandler("", 'no');
    } else {
      this.languageChangeHandler("", 'en');
    }
  }

  handleChange = event => {
    event.preventDefault()
    let { name, value } = event.target;
    this.setState({loginEmail:value});
  }

  render() {
    let { locale, loginConfig, t, errors, isMultipleAuthClientsEnabled } = this.state;
    document.title =loginConfig && t('loginPage.companyName');
    return (
      <div className="login-container">
        <div className="login-image">
          <LoginPageHeaderSection />
          <div className="login-page-ccm-title-div">
            <div className="login-page-login-ccm-title">
              {t('loginPage.loginCCMTitle')}
              <div>
              <a
                href="https://www.usecompose.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="hidden-xs login-hidden-xs">
                  www.usecompose.com
                </span>
              </a>
            </div>

            </div>

          </div>
        </div>
      <div className="login-panel">
        {/* <LoginPageHeaderSection /> */}

        <div className='row'>
            <div className='lang-div col-xs-12'>
              <ToggleSwitch 
                  isToggled={this.state.isToggled}
                  onToggle={this.handleToggle} 
              />
            </div>
          </div>

        <div className="lockscreen-wrapper">
          <div className="lockscreen-logo login-lockscreen-logo">
            <img
              src={loginConfig && "img/" + loginConfig.logoUrl}
              className="login-company-logo"
              alt="company logo"
            />
            <br />

            <div className="login-title-div">
              {t('loginPage.title')}
            </div>
            <br />
            <div className="login-page-title login-page-title-text">
              {t('loginPage.loginTitleText')}
            </div>
          </div>
          <div className="lockscreen-item">
            {isMultipleAuthClientsEnabled &&
            <div className="row">
              <div className="col-xs-1"></div>
              <div className="col-xs-12">
                <div
                  className={
                    errors.firstName ? "form-group has-error" : "form-group"
                  }
                >
                  <label htmlFor="loginEmail">
                    {t("userManagement.addUser.email")}
                  </label>
                  <input
                    type="text"
                    name="loginEmail"
                    className="form-control"
                    id="loginEmail"
                    onChange={this.handleChange}
                    placeholder={t(
                      "userManagement.addUser.placeholder.email"
                    )}
                  />
                  {errors.loginEmail && (
                    <span className="help-block">{errors.loginEmail}</span>
                  )}
                </div>
              </div>
              <div className="col-xs-1"></div>
            </div>
            }
            <div className="login-row row">
              <div className="col-xs-1"></div>
              <div className="col-xs-12">
                <button
                  type="submit"
                  onClick={this.onLoginButtonClick}
                  className="btn btn-block btn-login"
                >
                  {t('loginPage.button')}
                </button>
                {}
              </div>
              <div className="col-xs-1"></div>
            </div>

              <div className="separator-container">
                <div className="separator"></div>
                <div className="separator-text">{t('loginPage.orText')}</div>
                <div className="separator"></div>
              </div>

              <div className="try-compose-text">
              {t('loginPage.tryComposeText')}  
              <a
                  href="https://usecompose.com/contact/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="">
                  {t('loginPage.tryComposeLink')} 
                  </span>
                </a> 
              </div>
          </div>

          <div className='row'>
            <div className="app-version-div">
              <h5 className="login-page-app-version">Version : {properties.appVersion}</h5>
            </div>
          </div>
        </div>

        <div className="login-footer-div"><LoginPageFooterSection locale={locale}/></div>
        
      </div>
      </div>
    );
  }
}

export default withTranslation("common")(LoginPage);
