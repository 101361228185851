import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AuthUtil from "../../utils/AuthUtil";
import { withTranslation } from "react-i18next";
import DeleteModal from "./DeleteModal";

const $ = window.$;

class UserActionPanel extends Component {
  state = {
    uniqueId: this.props.uniqueId,
    loggedInUserId: null,
    isCaseManager : false,
    t: this.props.t
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      uniqueId: nextProps.uniqueId,
      isCaseManager: this.isCaseManager(nextProps.roleList)
    });
  }

  componentWillMount() {
    this.getLogedInUser();
  }

  componentDidMount() {
    this.setState({
      isCaseManager : this.isCaseManager(this.props.roleList)
    });
  }
  
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  deleteUser = uniqueId => {
    this.props.deleteUser(uniqueId);
  };

  getLogedInUser = () => {
    return AuthUtil.getUserId().then(id => {
      this.setState({ loggedInUserId: id });
    });
  };

  isCaseManager = roleList => {
    if (!roleList || roleList.length === 0) {
      return false;
    }
    return roleList[0].id === 2;
  }

  render() {
    let { uniqueId, loggedInUserId, isCaseManager, t } = this.state;
    const deleteModalParams = { t }
    const buttonStyle = `btn btn-app ${
      loggedInUserId === uniqueId ? "disabled" : ""
    }`;

    return (
      <Fragment>
        <div className="box">
          <div className="box-header">
            <div className="box-title">
              {t("userManagement.actionPanel.title")}
            </div>
          </div>
          <div className="box-body">
            <Link
              to={{
                pathname: "/protected/users/",
                search: "?action=edit",
                state: { state: "edit", userId: this.state.uniqueId }
              }}
              className={buttonStyle}
            >
              <i
                className="fa fa-edit"
                data-toggle="tooltip"
                title={t("common.edit")}
              />
              {t("common.edit")}
            </Link>
            <a
              href="#/"
              className={buttonStyle}
              data-toggle="modal"
              data-target="#userDeleteAlert"
            >
              <span
                className="fa fa-remove"
                data-toggle="tooltip"
                title={t("common.delete")}
              />
              {t("common.delete")}
            </a>
          </div>

          <DeleteModal
            type="user"
            id="userDeleteAlert"
            params={deleteModalParams}
            uniqueId={this.props?.uniqueId}
            deleteFunction={this.deleteUser}
            message="userManagement.deleteUser.message"
          />

        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(UserActionPanel);