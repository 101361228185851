import axios from "axios";
import AuthUtil from "../utils/AuthUtil";


const API_URL = window.ENV.REACT_APP_CMP_API_URL;

export default class ApiService {
  constructor() {
    this.auth = AuthUtil.getService();
  }

  static get API_URL() {
    return API_URL;
  }

  callApi(url, method, data, header) {
    return this.auth.loadUser().then(user => {
      if (user && user.access_token) {
        return this.invokeRestCall(url, user.access_token, method, data, header).catch(
          error => {
            if (error.response.status > 401 && error.response.message === "Expired JWT") {
              return this.auth.silentRenew().then(renewedUser => {
                return this.invokeRestCall(
                  url,
                  renewedUser.access_token,
                  method,
                  data,
                  header
                );
              });
            }
            throw error;
          }
        );
      } else if (user) {
        return this.auth.silentRenew().then(renewedUser => {
          return this.invokeRestCall(
            url,
            renewedUser.access_token,
            method,
            data,
            header
          );
        });
      } else {
        throw new Error("user is not logged in");
      }
    });
  }

  invokeRestCall(url, token, method, data, header) {
    let headers;
    let authHeader = {Authorization: "Bearer " + token};
    if (!header) {
       headers = {
         ...authHeader,
        "Content-Type": "application/json",
        Accept: "application/json"
      };
    } else {
      headers = {...header, ...authHeader};
    }

    switch (method) {
      case "get":
        return data === undefined
          ? axios.get(url, { headers })
          : axios.get(url, { params: data, headers });
      case "post":
        return axios.post(url, data, { headers });
      case "put":
        return axios.put(url, data, { headers });
      case "delete":
        if (data)
          return axios.delete(url, { headers, data });
        else
          return axios.delete(url, { headers });
      default:
        return;
    }
  }
}
