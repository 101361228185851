import ApiService from "../auth/ApiService";
import { handleResponse, handleError } from "../services/serviceUtil";
const apiEndpoint = ApiService.API_URL;
const resourceUrl = apiEndpoint + "/secured/metadata-filter";

const MetadataFilterAPI = {
    save: async function(filteredMetadata) {
        let data = await new ApiService().callApi(`${resourceUrl}`, "post", filteredMetadata);
        return data.data;
    },

    getFilteredMetadata: function(id) {
        return new ApiService()
            .callApi(`${resourceUrl}/${id}`, "get")
            .then(handleResponse)
            .catch(handleError);
    }
};

export default MetadataFilterAPI;